import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { useLocation } from "react-router-dom";
import CryptoFundLineChart from "../cryptoFund/CryptoFundLineChart";
import { getPriceInDecimal } from "../../config";

const TokenTablesCard = ({ token }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  let price = token?.usdPrice;
  let tokenprice = getPriceInDecimal(price);

  return (
    <div
      class="d-flex align-items-center  market-preview"
      style={{ cursor: "pointer" }}
      onClick={() =>
        navigate(`/token/${token?.tokenId}/${token?.symbol}`)
      }
    >
      <div class="w-100">
        <div class="row">
          <div className="d-flex align-items-center ">
            <div class="d-flex align-items-center col-4 col-md-3">
              <span>
                {token?.icon ? (
                  <img
                    src={token?.icon}
                    alt=""
                    style={{ width: "36px", height: "36px" }}
                    className="rounded-circle m-0 p-0"
                  />
                ) : (
                  <img
                    src="/images/basketimage.svg"
                    alt=""
                    style={{ width: "36px", height: "36px" }}
                    className="rounded-circle m-0 p-0"
                  />
                )}
              </span>
              <div class="ms-3">
                <h5 class="fs-14 font-w600 mb-0">{token?.symbol}</h5>
                <h6 class="fs-12 font-w400 mb-0">{token?.name}</h6>
              </div>
            </div>

            <div className="col-4 col-md-2">
              {parseInt(token?.usdVolume24h)}
            </div>
            <div className="col-4 col-md-2">
              {parseInt(token?.usdMarketcap)}
            </div>
            <div className="d-flex align-items-center justify-content-start  col-4 col-md-3">
              <CryptoFundLineChart
                prices={token?.prices ? token?.prices : []}
              />
            </div>
            <div className="d-flex flex-column align-items-start justify-content-start col-4 col-md-2">
              <div className="w-100 text-end">${tokenprice}</div>
              <h5
                class={`fs-12 font-w400 mb-0 w-100 text-end  ${
                  token?.usdPriceChange24h > 0 ? "text-success" : "text-danger"
                }`}
              >
                {token?.usdPriceChange24h?.toFixed(4)}% in 24H
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div
    //   class="market-preview w-100 row"
    //   style={{ cursor: "pointer" }}
    //   onClick={() =>
    //     navigate(`/token/${token?.tokenId}/${token?.symbol}`)
    //   }
    // >
    //   <div className="col-2">
    //     <img
    //       src={token?.icon}
    //       alt=""
    //       style={{ width: "25px", height: "25px" }}
    //       className="rounded-circle ms-1"
    //     />
    //   </div>
    //   <div className="col-2">{token?.name}</div>
    //   <div className="col-2">{tokenprice}</div>
    //   <div className="col-2">{parseInt(token?.usdMarketcap)}</div>
    //   <div className="col-2">{parseInt(token?.usdVolume24h)}</div>
    //   <div className="col-2 d-flex justify-content-end">
    //     <div className="" style={{ maxWidth: "250px" }}>
    //       <CryptoFundLineChart prices={token?.prices ? token?.prices : []} />
    //     </div>
    //   </div>
    // </div>
  );
};

export default TokenTablesCard;
