import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import UserNameSetupModal from "../../component/userNameSetupModal/UserNameSetupModal";
import moment from "moment";

const Refer = () => {
  const [referredAccounts, setReferredAccounts] = useState([]);
  const [referredAccountsLoading, setReferredAccountsLoading] = useState(false);
  const [points, setPoints] = useState(0);
  const [pointsLoading, setPointsLoading] = useState(false);
  const [isReferralCodeCopied, setIsReferralCodeCopied] = useState(false);
  const [isReferraLinkCopied, setIsReferralLinkCopied] = useState(false);
  const [openUserNameSetupModal, setOpenUserNameSetupModal] = useState(false);

  const appCtx = useSelector((state) => state.app);

  const makeRequest = useAuthorizedHttp();
  const navigate = useNavigate();

  const themedColor = `${appCtx?.isDarkMode ? "white" : "black"}`;

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const backgroundStyle = {
    backgroundColor: "#E2E5FC",
    padding: "5px 10px",
    borderRadius: "50px",
  };

  const buttonStyle = {
    borderRadius: "50px",
    backgroundColor: "white",
    color: "#6666FF",
    padding: "5px 10px",
    marginLeft: "10px",
  };

  useEffect(() => {
    setReferredAccountsLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/tale/points/ledger`,
      },
      (data) => {
        setReferredAccounts(data?.content);
        setReferredAccountsLoading(false);
      },
      (error) => {
        setReferredAccountsLoading(false);
      },
      () => {
        setReferredAccountsLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    setPointsLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/tale/points`,
      },
      (data) => {
        setPoints(data?.points);
        setPointsLoading(false);
      },
      (error) => {
        setPointsLoading(false);
      },
      () => {
        setPointsLoading(false);
      }
    );
  }, []);

  const getTruncatedAddress = (address) => {
    const truncatedAddress = `${address?.substring(
      0,
      6
    )}.....${address?.substring(address?.length - 6)}`;

    return truncatedAddress;
  };

  const handleCopyReferralCode = (value) => {
    setIsReferralCodeCopied(!isReferralCodeCopied);
    navigator.clipboard.writeText(value);
  };
  const handleCopyReferralLink = (value) => {
    setIsReferralLinkCopied(!isReferraLinkCopied);
    navigator.clipboard.writeText(value);
  };

  useEffect(() => {
    if (!appCtx?.userDetails?.userName) {
      setOpenUserNameSetupModal(true);
    }
  }, []);

  return (
    <>
      <MainWrapper>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            <div className="col-12">
              <img
                src="/images/TCReferlong.png"
                alt=""
                style={{ width: "100%" }}
                className="my-2"
              />
            </div>
            {!appCtx?.userDetails?.userName ? (
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0"></div>
                  <div className="card-body pt-0">
                    <div className="w-100">
                      <div class="mb-3 font-w600 d-flex flex-column justify-content-center align-items-center gap-2">
                        <h5>Add your username to generate a referral code</h5>
                        <button
                          className="btn btn-primary"
                          onClick={() => setOpenUserNameSetupModal(true)}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-header border-0"></div>
                    <div className="card-body pt-0">
                      <div className="w-100">
                        {appCtx?.userDetails?.userName && (
                          <>
                            <div class="mb-1 font-w600 d-flex justify-content-center align-items-center gap-2">
                              <h4>Your Referral Code:</h4>

                              <h4 className="font-w400">
                                {appCtx?.userDetails?.userName}
                              </h4>
                              <h4
                                onClick={() =>
                                  handleCopyReferralCode(
                                    appCtx?.userDetails?.userName
                                  )
                                }
                                className="text-secondary "
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                }}
                              >
                                {isReferralCodeCopied ? (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className=""
                                    style={{
                                      color: themedColor,
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    className=""
                                    style={{
                                      color: themedColor,
                                    }}
                                  />
                                )}
                              </h4>
                            </div>

                            <div class="mb-3 font-w600 d-flex justify-content-center align-items-center gap-2">
                              <h4>Your Referral Link:</h4>

                              <h4 className="font-w400">
                                https://app.taleinvest.io/signup?referral=
                                {appCtx?.userDetails?.userName}
                              </h4>
                              <h4
                                onClick={() =>
                                  handleCopyReferralLink(
                                    ` https://app.taleinvest.io/signup?referral=${appCtx?.userDetails?.userName}`
                                  )
                                }
                                className="text-secondary "
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                }}
                              >
                                {isReferraLinkCopied ? (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className=""
                                    style={{
                                      color: themedColor,
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    className=""
                                    style={{
                                      color: themedColor,
                                    }}
                                  />
                                )}
                              </h4>
                            </div>
                          </>
                        )}

                        <p>
                          Refer Tale Wallet and secure 100 TC point in reward
                          when the new user loads their tale wallet with
                          cryptos.
                        </p>
                        <p>
                          New user can load their Tale Wallet using either via
                          fiat to crypto conversion through the OnRamp provider
                          in Tale Wallet or by transferring their crypto from
                          some other wallet to Tale Wallet.
                        </p>
                        <p>
                          New user also receives 100 TC point for loading their
                          wallet
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-12">
                  {referredAccountsLoading ? (
                    <div
                      class="d-flex justify-content-center align-items-center card crypto-chart"
                      style={{ minHeight: "400px" }}
                    >
                      <CircularProgress
                        style={{
                          color: "#BBFF00",
                          fontSize: "10px",
                          width: "25px",
                          height: "25px",
                        }}
                        className="py-10"
                        fontSize="small"
                      />
                    </div>
                  ) : (
                    <>
                      <div class="card w-100">
                        <div class="card-header pb-0 border-0 flex-wrap">
                          <div class="card-header pb-0 mb-0 border-0 flex-wrap">
                            <div class="mb-0">
                              <h4 class="card-title mb-2">Referred Accounts</h4>
                              {!pointsLoading && (
                                <div style={containerStyle}>
                                  <div
                                    style={backgroundStyle}
                                    className="d-flex align-items-center px-3 mb-3"
                                  >
                                    <p
                                      className="mb-0"
                                      style={{
                                        color: "#6666FF",
                                      }}
                                    >
                                      My Tc Points
                                    </p>
                                    <div style={buttonStyle}>{points || 0}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {referredAccounts?.length === 0 ? (
                          <div className="card-body">
                            <div className="mt-2">
                              <p className="text-center  ">
                                You haven't referred anyone yet
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div class="card-body pt-2 overflow-auto">
                            <div class="d-flex align-items-center  market-preview">
                              <div class="w-100">
                                <div class="row">
                                  <div className="d-flex align-items-center font-w600 ">
                                    <div class="d-flex align-items-center col-4 col-md-2 ">
                                      S no
                                    </div>

                                    <div className="d-flex flex-column align-items-start justify-content-start col-4 col-md-2">
                                      <div className="">User Id</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-start justify-content-start col-4 col-md-4">
                                      <div className="">Date</div>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-start  col-4 col-md-2">
                                      Action
                                    </div>
                                    <div className="d-flex align-items-center justify-content-start  col-4 col-md-2">
                                      Reward
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {referredAccounts?.map((account, index) => (
                              // <TokenTablesCard key={index} token={token} />
                              <div
                                class="d-flex align-items-center  market-preview"
                                style={{ cursor: "pointer" }}
                                // onClick={() => navigate("/refer")}
                                key={index}
                              >
                                <div class="w-100">
                                  <div class="row">
                                    <div className="d-flex align-items-center ">
                                      <div class="d-flex align-items-center col-4 col-md-2 font-w-600">
                                        {index + 1}
                                      </div>

                                      <div className="col-4 col-md-2  font-w-600">
                                        {account?.userId}
                                      </div>
                                      <div className="col-4 col-md-4">
                                        {moment(account?.createdDate).format(
                                          "D MMM YYYY"
                                        )}
                                      </div>
                                      <div className="col-4 col-md-2">
                                        {account?.action}
                                      </div>
                                      <div className="col-4 col-md-2">
                                        {account?.points}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <UserNameSetupModal
          openModal={openUserNameSetupModal}
          setOpenModal={setOpenUserNameSetupModal}
        />
      </MainWrapper>
    </>
  );
};

export default Refer;
