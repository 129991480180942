import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";

const HomeCarouselTokenCard = ({ coin, allCoinPrice, usersToken }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let tokenprice;
  let price = allCoinPrice[coin?.symbol]?.price;
  if (price >= 1000) {
    tokenprice = `${price?.toFixed(0)}`;
  } else if (price >= 1) {
    tokenprice = `${price?.toFixed(2)}`;
  } else if (price >= 0.1) {
    tokenprice = `${price?.toFixed(3)}`;
  } else if (price >= 0.01) {
    tokenprice = `${price?.toFixed(4)}`;
  } else if (price >= 0.001) {
    tokenprice = `${price?.toFixed(5)}`;
  } else if (price >= 0.0001) {
    tokenprice = `${price?.toFixed(6)}`;
  } else if (price >= 0.00001) {
    tokenprice = `${price?.toFixed(7)}`;
  } else {
    tokenprice = price?.toFixed(8);
  }

  const bgColors = ["bg-warning", "bg-pink", "bg-secondary", "bg-dark"];
  const randomColor = bgColors[Math.floor(Math.random() * bgColors.length)];

  return (
    <div
      class="swiper-slide me-3 "
      style={{ height: "150px", cursor: "pointer" }}
      onClick={() => {
        navigate(`/spot/${coin?.tokenId}/${coin?.symbol}`);
        dispatch(
          appActions.setSingleTokenData({
            tokenName: coin.tokenName,
            tokenSymbol: coin?.symbol,
            priceOfToken: tokenprice,
          })
        );
      }}
    >
      <div class={`card card-box ${randomColor}`}>
        <div class="card-header border-0 pb-0">
          <div class="chart-num">
            <h2 class="font-w600 mb-0">{coin.tokenName}</h2>
            <p className="">
              {allCoinPrice[coin.symbol]?.priceChange > 0 ? (
                <i class="fa-solid fa-sort-up me-2"></i>
              ) : (
                <i class="fa-solid fa-sort-up me-2"></i>
              )}
              {allCoinPrice[coin.symbol]?.priceChange?.toFixed(3)}
              (1 days)
            </p>
            <h3 class="font-w600 mb-0">${tokenprice}</h3>
          </div>
          <div class="dlab-swiper-circle">
            <img
              src={coin.tokenIcon}
              alt=""
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                marginTop: "2px",
                marginLeft: "5px",
              }}
            />
          </div>
        </div>
        <div class="card-body p-0">
          <div id="widgetChart2" class="chart-primary"></div>
        </div>
      </div>
    </div>
  );
};

export default HomeCarouselTokenCard;
