import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { CircularProgress } from "@mui/material";
import useAuthorizedHttp from "../../hooks/use-authorized-http";

function AddBankModal({ openModal, setOpenModal }) {
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [loader, setLoader] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  let isOpenedfromMobile = queryParams.get("address");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      accountNumber,
      bankName,
      branchName,
      ifscCode,
      userId: appCtx.userDetails?.userId,
    };
    setLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/bank/account
`,
        data,
        method: "post",
      },
      (data) => {
        // setLoader(false);
        toast.success("Successfully Added the Bank Account");
        setLoader(false);
        closeModal();
      },
      (error) => {
        toast.error("failed to add bank account!");
        setLoader(false);
      },
      () => {}
    );
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
      zIndex: 9999,
      width: "400px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="w-100 d-flex justify-content-end m-2 position-relative">
          <div
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </div>
        </div>
        <div className="overflow-x-hidden overflow-y-visible rounded-xl">
          <h5 className="text-2xl font-medium text-center my-3">
            Add a Bank Account
          </h5>
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto text-sm">
            <div className="mb-4">
              <label htmlFor="accountNumber" className="block font-medium">
                Account Number
              </label>
              <input
                type="text"
                placeholder="Enter Account Number"
                id="accountNumber"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                className="form-control rounded-md px-4 py-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="bankName" className="block font-medium">
                Bank Name
              </label>
              <input
                type="text"
                placeholder="Enter Bank Name"
                id="bankName"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                className="form-control rounded-md px-4 py-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="branchName" className="block font-medium">
                Branch Name
              </label>
              <input
                type="text"
                id="branchName"
                placeholder="Enter Branch Name"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                className="form-control rounded-md px-4 py-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="ifscCode" className="block font-medium">
                IFSC Code
              </label>
              <input
                type="text"
                id="ifscCode"
                placeholder="Enter IFSC Code"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                className="form-control rounded-md px-4 py-2 w-full"
              />
            </div>

            <div className="px-5">
              <div className="d-flex justify-content-center mt-4 w-100">
                <button
                  type="submit"
                  className="btn btn-primary px-5 d-flex align-items-center gap-2  "
                >
                  <p className="mb-0">Add Bank Account</p>
                  <div>
                    {loader && (
                      <CircularProgress size={20} className="ml-[5px]" />
                    )}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddBankModal;
