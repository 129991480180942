import React, { useEffect, useState, useRef } from "react";
import FutureHeader from "../../component/futureComponent/FutureHeader";
import MainWrapper from "../../component/wrapper/MainWrapper";
import TradingViewWidget from "../../component/tradingViewChart/TradingViewWidget";
import { useWalletView } from "../../hooks/useWalletView";
import TokenCard from "../../component/TokenLIstCard/SpotTokenCard";
import { useParams } from "react-router-dom";
import Ticker from "../../component/orderbook/Ticker";
import TokenBottomTable from "../../component/tokenBottomTable/TokenBottomTable";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import OrderBook from "../../component/orderbook/OrderBook";
import ExpertsBuySellSuccessModal from "../../component/orderbook/ExpertsBuySellSuccessModal";

const SpotDetailsPage = () => {
  const [openTransferSuccessModal, setOpenTransferSuccessModal] =
    useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [fundWalletAddresstLoading, setFundWalletAddresstLoading] =
    useState(false);
  const [fundWalletAddress, setFundWalletAddress] = useState([]);
  const [selectedFundWalletAddress, setSelectedFundWalletAddress] =
    useState(null);
  const [selectedFundName, setSelectedFundName] = useState(null);
  const [selectedFundId, setSelectedFundId] = useState(null);
  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    appCtx,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    icon,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    totalpage,
  } = useWalletView();

  const { tokenid, tokenname } = useParams();
  const makeRequest = useAuthorizedHttp();
  const parentRef = useRef(null);
  useEffect(() => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      let fourthOfParent = parentWidth / 4;
      setParentWidth(fourthOfParent);
    }
  }, [appCtx?.isSideBarOpen, parentRef?.current?.offsetWidth]);

  useEffect(() => {
    getAllCoin(0, 100);
    getAllCoinPrice();
    getUsersToken();
  }, [appCtx.blockchain]);
  let screenHeight = window.innerHeight;

  useEffect(() => {
    if (fundWalletAddress.length > 0) {
      setSelectedFundWalletAddress(fundWalletAddress[0]?.fundWalletAddress);
      setSelectedFundName(fundWalletAddress[0]?.fundName);
      setSelectedFundId(fundWalletAddress[0]?.fundId);
    }
  }, [fundWalletAddress]);

  useEffect(() => {
    setFundWalletAddresstLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/funds/wallet/list`,
        method: "get",
      },
      (data) => {
        setFundWalletAddress(data);
        setFundWalletAddresstLoading(false);
      },
      () => {
        setFundWalletAddresstLoading(false);
      },
      (error) => {
        setFundWalletAddresstLoading(false);
      }
    );
  }, []);

     
  return (
    <MainWrapper>
      <div
        class={`container-fluid mt-2 ${appCtx?.isDarkMode ? "text-white" : ""}`}
      >
        <div class="row g-3 d-flex align-items-start" ref={parentRef}>
          <div class="col-xl-9 g-1">
            <div class="card">
              <div class="card-body " style={{ height: "70vh" }}>
                <TradingViewWidget tokenname={tokenname} />
              </div>
            </div>
            <TokenBottomTable
              allCoin={allCoin}
              allCoinPrice={allCoinPrice}
              usersToken={usersToken}
              isCoinLoading={isCoinLoading}
              fundWalletAddresstLoading={fundWalletAddresstLoading}
              fundWalletAddress={fundWalletAddress}
              selectedFundWalletAddress={selectedFundWalletAddress}
              setSelectedFundWalletAddress={setSelectedFundWalletAddress}
              selectedFundName={selectedFundName}
              setSelectedFundName={setSelectedFundName}
              selectedFundId={selectedFundId}
              setSelectedFundId={setSelectedFundId}
            />
          </div>
          {/* order book */}
          <div
            class="col-xl-3  "
            style={{
              height: `${screenHeight}px`,
              position: "fixed",
              top: "60px",
              right: "0px",
              overflow: "hidden",
              width: `${parentWidth}px`,
            }}
          >
            <div class="card">
              <div class="card-body">
                <OrderBook
                  product_id={`${tokenname}USDT`}
                  allCoin={allCoin}
                  allCoinPrice={allCoinPrice}
                  usersToken={usersToken}
                  isCoinLoading={isCoinLoading}
                  setOpenTransferSuccessModal={setOpenTransferSuccessModal}
                  setTransactionData={setTransactionData}
                  selectedFundWalletAddress={selectedFundWalletAddress}
                  selectedFundName={selectedFundName}
                  selectedFundId={selectedFundId}
                  setSelectedFundId={setSelectedFundId}
                  tokenname={tokenname}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {openTransferSuccessModal && (
        <ExpertsBuySellSuccessModal
          openModal={openTransferSuccessModal}
          setOpenModal={setOpenTransferSuccessModal}
          transactionData={transactionData}
        />
      )}
    </MainWrapper>
  );
};

export default SpotDetailsPage;
