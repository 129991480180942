import React, { useEffect, useState } from "react";
import { appActions } from "../context/app-slice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import EastIcon from "@mui/icons-material/East";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ReferralModal from "../component/referralModal/ReferralModal";
var CryptoJS = require("crypto-js");

// wallet connect
// 1. Get projectId
const projectId = "7f0440ffb2f50b70443374f22374d01e";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create a metadata object
const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
// const ethersConfig = defaultConfig({
//   /*Required*/
//   metadata,

//   /*Optional*/
//   enableEIP6963: true, // true by default
//   enableInjected: true, // true by default
//   enableCoinbase: true, // true by default
//   rpcUrl: "...", // used for the Coinbase SDK
//   defaultChainId: 1, // used for the Coinbase SDK
// });

// // 5. Create a Web3Modal instance
// createWeb3Modal({
//   ethersConfig,
//   chains: [mainnet],
//   projectId,
//   enableAnalytics: true, // Optional - defaults to your Cloud configuration
// });

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const location = useLocation();
  const dark = false;
  const [userData, setUserData] = React.useState({
    email: appCtx.userDetails?.email,
    password: "",
  });
  const [otpLoginUserEmail, setOtpLoginUserEmail] = React.useState(
    appCtx.userDetails?.email
  );
  const [handleEnterOtp, setHandleEnterOtp] = React.useState(false);
  const [clickledOnLoginOtp, setClickledOnLoginOtp] = React.useState(false);
  const [otp1, setOtp1] = React.useState("");
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [reSendOtp, setReSendOtp] = React.useState(false);
  const [isVerifiedOtp, setIsVerifiedOtp] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [nonCustodialLoader, setNonCustodialLoader] = useState(false);
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [continueWith, setContinueWith] = useState(false);
  let distance1 = 300,
    resendDistance = 300;
  if (reSendOtp === 0) {
    resendDistance = 300;
    distance1 = 0;
  }
  const [data, setData] = useState({});
  const [transactionId, setTransactionId] = useState(null);
  const [walletConnectClicked, setWalletConnectClicked] = useState(false);
  const [metamaskClicked, setMetamaskClicked] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  let loginType = "type=login";

  const isLoginPage = location.pathname === "/login";

  const searchParams = new URLSearchParams(window.location.search);

  const [referralCode, setReferralCode] = useState(
    searchParams.get("referral")
  );

useEffect(() => {
  if (!isLoginPage) {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/referral/code/${referralCode}/verify`,
      },
      (data) => {
        // Valid referral code
      },
      (error) => {
        // Handle the error here
        setReferralCode(null);
        setOpenReferralModal(true);
      }
    ).catch((error) => {
      // Handle any uncaught errors here
      console.error("Unhandled error:", error);
    });
  }
}, [location]);

const handleChange = (e) => {
  const { name, value } = e.target;
  setUserData((prevValue) => ({ ...prevValue, [name]: value }));
  setOtpLoginUserEmail(e.target.value);
};


const handleSendOtp = () => {
  if (userData.email?.trim().length !== 0) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)) {
      setLoader(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}`,
          data: { email: otpLoginUserEmail },
          method: "post",
        },
        (data) => {
          toast("OTP Sent SuccesFully!");
          setClickledOnLoginOtp(true);
          if (data.message === "Success" && data.status === true) {
            setHandleEnterOtp(true);
            setLoader(false);
            var x = setInterval(function () {
              setMinutes(Math.floor(distance1 / 60));
              setSeconds(distance1 % 60);
              distance1--;
              if (isVerifiedOtp === true) {
                distance1 = 0;
                resendDistance = 0;
                clearInterval(x);
              }
              // if (distance1 < 0) {
              //     clearInterval(x);
              //     navigate("/signup");
              // }
              if (distance1 === -1) {
                clearInterval(x);
                // navigate("/");
              }
            }, 1000);
          }
        },
        (error) => {
          toast.error("Please try after sometime");
          setLoader(false);
        }
      );
    } else toast.error("Email ID is incorrect !");
  } else {
    toast.error("Email Id cannot be empty !");
  }
};
const handleCheckOtp = async () => {
  setLoader(true);
  if (!appCtx.isLoggedIn) {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/verify?${loginType}`,
        data: {
          email: otpLoginUserEmail,
          otp: otp1,
          product: "TALE_INVEST",

          ...(!isLoginPage && {
            //for signup page
            referredBy: referralCode,
          }),
        },
        method: "post",
      },
      (data) => {
        setIsVerifiedOtp(true);
        setLoader(false);

        dispatch(appActions.updateUserDetails(data));
        dispatch(appActions.updateAuthToken(data?.authToken));
        if (data?.role) {
          dispatch(appActions.setRole(data?.role));
        } else {
          dispatch(appActions.setRole("user"));
        }

        window.localStorage.setItem("userId", data.userId);
        window.localStorage.setItem("authToken", data.authToken);
        dispatch(appActions.setBlockChain("ALL"));

        dispatch(appActions.login(true));

        if (data?.newUser) {
          navigate("/welcome");
        } else {
          navigate("/");
        }
      },
      (error) => {
        if (
          error?.response?.data?.message?.includes(
            "Please enter a valid Invite code"
          )
        ) {
          toast.error("Sorry we couldn't find your account. Please signup");
        } else {
          toast.error("OTP entered is incorrect !");
        }

        setLoader(false);
      }
    );
  }
};
const handleResendOtp = () => {
  let header = {};

  makeRequest(
    {
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}&resend=true`,
      data: { email: otpLoginUserEmail },
      method: "post",
    },
    (data) => {
      toast("OTP Sent SuccesFully!");
      setReSendOtp(true);
      distance1 = 0;
      setOtp1("");
      if (data.message === "Success" && data.status === true) {
        setHandleEnterOtp(true);
        setLoader(false);
      }
    },
    () => {}
  );
};

// metamask signup

// wallet connect modal

// const { open } = useWeb3Modal();
// const { address, chainId, isConnected } = useWeb3ModalAccount();
// const { walletProvider } = useWeb3ModalProvider();

// const { disconnect } = useDisconnect();
// initially disconnecting modal so that modal will not stay connected if user logs in and then logout agiain

// useEffect(() => {
//   if (isConnected) {
//     disconnect();
//   }
// }, []);

const handleMetamaskSignin = async () => {
  setMetamaskClicked(true);
  setWalletConnectClicked(false);
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      handleNonCustodialSignin(accounts[0]);
    } catch (e) {
      console.log(e);
    }
  } else {
    alert("Meta Mask not detected");
  }
};
// const handleWalletConnectSignin = async () => {
//   setWalletConnectClicked(true);
//   setMetamaskClicked(false);
//   try {
//     await open();
//     // get address and then perform a demo api call
//   } catch (e) {
//     console.log(e);
//   }
// };
// useEffect(() => {
//   if (isConnected && walletConnectClicked) {
//     handleNonCustodialSignin(address);
//   }
// }, [isConnected]);

const handleNonCustodialSignin = async (walletAddress) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
    };

    if (appCtx.authToken != null) {
      config.headers["X-Auth-Token"] = appCtx.authToken;
    }

    setNonCustodialLoader(true); // Show loading spinner here

    const response = await axios.post(
      `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/account/login`,
      {
        blockchain: "BSC",
        blockchainAddress: walletAddress,
        marketplaceAddress: "0",
        wallet: "TALEWALLET",
        product: "TALE_INVEST",
        ...(!isLoginPage && {
          //for signup page
          referredBy: referralCode,
        }),
      },
      config
    );

    const message = response.data.secretKey;

    var keyEncryt = CryptoJS.SHA256(
      JSON.stringify(response?.secretKey)
    ).toString();
    dispatch(appActions.setEvmEncrypt(keyEncryt));

    // Sign the message
    const signature = await window.ethereum.request({
      method: "personal_sign",
      params: [message, walletAddress],
    });

    // Calling verify API with the signed message as the key
    const verifyResponse = await axios.post(
      `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/wallet/verify`,
      {
        blockchain: "BSC",
        blockchainAddress: walletAddress,
        marketplaceAddress: "0",
        signedSecretKey: signature,
        userId: response.data.userId,
      },
      config
    );
    if (verifyResponse?.data?.role) {
      dispatch(appActions.setRole(verifyResponse?.data?.role));
    } else {
      dispatch(appActions.setRole("user"));
    }
    dispatch(appActions.updateAuthToken(verifyResponse.data?.authToken));
    dispatch(
      appActions.updateUserDetails({
        ...appCtx?.userDetails,
        userId: verifyResponse.data?.userId,
      })
    );

    //  setOpenSetupPasswordModal(true);

    dispatch(appActions.login(true));
    dispatch(appActions.setBlockChain("ALL"));
    dispatch(appActions.setCustodial(false));
    toast.success("Wallet connected successfully");
    if (verifyResponse?.data?.newUser) {
      navigate("/welcome");
    } else {
      navigate("/");
    }
  } catch (error) {
    console.log("Error connecting...");
  } finally {
    setNonCustodialLoader(false);
  }
};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const backgroundStyle = {
  backgroundColor: "#E2E5FC",
  padding: "10px",
  borderRadius: "50px",
};

const buttonStyle = {
  borderRadius: "50px",
  backgroundColor: "white",
  color: "#6666FF",
  padding: "5px 10px",
  marginLeft: "20px",
};
console.log("appCtx", appCtx);
return (
  <div>
    <div class="body">
      <div class="authincation d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="login-aside text-center  d-flex flex-column flex-row-auto">
          <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <div class="text-center gap-3  mb-lg-4 mb-2 pt-5 d-flex flex-direction-row justify-content-center align-items-center">
              <img
                src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
                alt=""
                className=""
                style={{ width: "65px", height: "65px" }}
              />
              <h2 class="text-white">Tale Invest</h2>
            </div>
            <p class="mb-4 px-3">
              Invest in Crypto with Experts Through Trustless and Permissionless
              Onchain Crypto Funds, Indexes and Baskets
            </p>
          </div>
          <div
            class="aside-image position-relative"
            style={{ backgroundImage: "url(/images/loginicon4.png)" }}
          >
            <img class="img1 move-1" src="images/loginicon2.png" alt="" />
            <img class="img2 move-2" src="/images/loginicon3.png" alt="" />
            <img class="img3 move-3" src="/images/loginicon5.png" alt="" />
          </div>
        </div>
        <div class="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          <div class="d-flex justify-content-center h-100 align-items-center">
            <div class="authincation-content style-2">
              <div class="row no-gutters">
                <div class="col-xl-12 tab-content">
                  <div
                    id="sign-up"
                    class="auth-form tab-pane fade show active  form-validation"
                  >
                    <form onSubmit={(e) => e.preventDefault()}>
                      {!isLoginPage && referralCode && (
                        <div style={containerStyle}>
                          <div
                            style={backgroundStyle}
                            className="d-flex align-items-center px-3 mb-3"
                          >
                            <p
                              className="mb-0"
                              style={{
                                color: "#6666FF",
                              }}
                            >
                              You are invited by
                            </p>
                            <div style={buttonStyle}>{referralCode}</div>
                          </div>
                        </div>
                      )}
                      <div class="text-center mb-4">
                        <h3 class="text-center mb-2 text-dark">
                          {isLoginPage ? "Sign In" : "Sign Up"}
                        </h3>
                      </div>

                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <img
                          src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
                          alt=""
                          className=""
                          style={{ width: "65px", height: "65px" }}
                        />
                        <h5 class="my-3">
                          {isLoginPage ? "Sign In" : "Sign Up"} with OTP
                        </h5>
                      </div>

                      {!clickledOnLoginOtp ? (
                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label required"
                          >
                            Email address
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={otpLoginUserEmail}
                            onChange={handleChange}
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter your email address"
                          />
                        </div>
                      ) : (
                        <div className="mb-3 position-relative">
                          <label className="form-label required">OTP</label>
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            id="dlab-password"
                            className="form-control"
                            value={otp1}
                            onChange={(e) => {
                              setOtp1(e.target.value);
                            }}
                          />
                          <span
                            className="eye"
                            onClick={togglePasswordVisibility}
                          >
                            {isPasswordVisible ? (
                              <FontAwesomeIcon icon={faEye} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                          </span>
                        </div>
                      )}

                      <div className="d-flex justify-content-between align-items-center my-3">
                        <div
                          class="fw-bold"
                          style={{
                            visibility: !reSendOtp ? "none" : "hidden",
                            display: !clickledOnLoginOtp ? "none" : "block",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleResendOtp();
                          }}
                        >
                          Resend OTP
                        </div>
                        <div className="font-bold">
                          {!(minutes === 0 && seconds === 0) ? (
                            <>
                              {minutes >= 0 && minutes <= 9
                                ? `0${minutes}`
                                : minutes}
                              :
                              {seconds >= 0 && seconds <= 9
                                ? `0${seconds}`
                                : seconds}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center pb-[20px]">
                        <button
                          class="btn btn-block btn-primary shadow-none d-flex justify-content-center align-items-center gap-3"
                          onClick={() =>
                            !clickledOnLoginOtp
                              ? handleSendOtp()
                              : handleCheckOtp()
                          }
                        >
                          <div>
                            {!clickledOnLoginOtp ? `Send OTP` : "Verify OTP"}
                          </div>
                          {loader ? (
                            <CircularProgress
                              sryle={{ color: "#BBFF00", fontSize: "10px" }}
                              className="ml-[5px]"
                              fontSize="small"
                              style={{ width: "25px", height: "25px" }}
                            />
                          ) : (
                            <EastIcon className="ml-[5px]" />
                          )}
                        </button>
                      </div>
                      <div class="sepertor">
                        <span class="d-block my-4 fs-13">Or with</span>
                      </div>
                      <div class="row mb-4">
                        <div className="col-xl-6 col-12">
                          <button
                            onClick={handleMetamaskSignin}
                            className="btn btn-outline-dark btn-sm btn-block mt-xl-0 mt-3 d-flex align-items-center justify-content-center"
                          >
                            <img
                              src="/images/metamask.png"
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "5px",
                              }}
                            />
                            Metamask{" "}
                            {nonCustodialLoader && metamaskClicked && (
                              <CircularProgress
                                style={{
                                  color: "#BBFF00",
                                  marginLeft: "5px",
                                }}
                                size={20}
                              />
                            )}
                          </button>
                        </div>
                        <div className="col-xl-6 col-12">
                          <button
                            // onClick={handleWalletConnectSignin}
                            disabled={true}
                            className="btn btn-outline-dark btn-sm btn-block mt-xl-0 mt-3 d-flex align-items-center justify-content-center"
                          >
                            <img
                              src="/images/WalletConnect.png"
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "5px",
                              }}
                            />
                            Wallet Connect (coming soon )
                            {nonCustodialLoader && walletConnectClicked && (
                              <CircularProgress
                                style={{
                                  color: "#BBFF00",
                                  marginLeft: "5px",
                                }}
                                size={20}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="new-account mt-3 text-center">
                      {isLoginPage ? (
                        <div class="d-flex gap-3 justify-content-center align-items-center">
                          <p class="font-w500">Dont have an account? </p>
                          <Link
                            to="/signup"
                            class="text-primary"
                            data-toggle="tab"
                          >
                            Sign up
                          </Link>
                        </div>
                      ) : (
                        <div class="d-flex gap-3 justify-content-center align-items-center">
                          <p class="font-w500">Already have an account </p>
                          <Link
                            to="/login"
                            class="text-primary"
                            data-toggle="tab"
                          >
                            Sign in
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <a href="javascript:void(0);" class="text-primary">
                      Terms
                    </a>
                    <a href="javascript:void(0);" class="text-primary mx-5">
                      Plans
                    </a>
                    <a href="javascript:void(0);" class="text-primary">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ReferralModal
      openModal={openReferralModal}
      setOpenModal={setOpenReferralModal}
      referralCode={referralCode}
      setReferralCode={setReferralCode}
    />
  </div>
);
};

export default Login;
