import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import {
  faArrowRightFromBracket, faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { Card } from "react-bootstrap";
import SignupPageModal from "../CustodialWalletSetup/SignupPageModal";
import AddBankModal from "../Bank/AddBankModal";

const CryptoFundOffRampModal = ({
  openModal,
  setOpenModal,
  amount,
  basketId,
  tokenPercentArray,
  tokenArray,
  basketBlockchain,
  isTokenDetailsPage,
  symbol,
  totalInvestment,
  singleBasket,
  address,
  blockchain,
  name,
  fundId,
  tokenPriceCurrency,
  tokenPrice,
  contractAddress,
  fundsBlockchain,
  truncatedAddress,
  investedAmount,
  fundWalletAddress,
}) => {
  const appCtx = useSelector((state) => state.app);
  const [sendToken, setSendToken] = useState(null);

  const [receiveToken, setReceiveToken] = useState("INR");
  const [receiveTokenId, setReceiveTokenId] = useState("");
  const [receiveAmount, setReceiveAmount] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [isBankDataCopied, setIsBankDataCopied] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [currentComponent, setCurrentComponent] = useState(1);

  const [networkFee, setNetworkFee] = useState(20);
  const [GetTokenLoading, setGetTokenLoading] = useState(false);
  const [openToken, setOpenToken] = useState(false);
  const [buyOnramp, setBuyOnramp] = useState(false);
  const [allCoin, setAllcoin] = useState([]);
  const [totalpage, setTotalpage] = useState(0);
  const [allCoinPrice, setAllcoinPrice] = useState([]);
  const [buyWebViewLoad, setBuyWebViewLoad] = useState(false);
  const [bankDatas, setBankDatas] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [openAddBankModal, setOpenAddBankModal] = useState(false);
  const [openSendOtpModal, setOpenSendOtpModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [usersToken, setUsersToken] = useState([]);
  const [selectedtokenBalance, setSelectedtokenBalance] = useState(null);

  const [sendAmount, setSendAmount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedToken, setSelectedToken] = useState("");
  const [searchToken, setSearchToken] = useState("");
  const [searchText, setSearchText] = useState("");
  const [sendTokenIcon, setSendTokenIcon] = useState("");
  const [sendTokenSymbol, setSendTokenSymbol] = useState("");
  const [receiveTokenIcon, setReceiveTokenIcon] = useState("");
  const [receiveTokenSymbol, setReceiveTokenSymbol] = useState("");
  const [isSelectingSendToken, setIsSelectingSendToken] = useState(true);
  const [sendTokenId, setSendTokenId] = useState("");
  const [tokenInChain, setTokenInChain] = useState([]);
  const [receiveAmountLoading, setReceiveAmountLoading] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const makeRequest = useAuthorizedHttp();
  var { handleBuyAllToken, handleBuyNativeTokenFromWeb } =
    usehandleBuyTaleCoin(appCtx);

  let platformFee;
  let totalFee;

  if (receiveAmount !== null && receiveAmount !== undefined) {
    platformFee = ((receiveAmount * 0.4) / 100)?.toFixed(2);
  } else {
    // Handle the case when receiveAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = (parseFloat(platformFee) + parseFloat(networkFee))?.toFixed(2);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }

  const userAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === appCtx?.blockchain
  )?.address;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9991,
    },
    content: {
      top: "53%",
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      zIndex: 9992,
    },
  };

  let OffRampTransactionData = {
    fiatAmount: receiveAmount,

    fiatCurrency: "INR",

    cryptoToken: tokenPriceCurrency,

    cryptoAmount: receiveAmount / (tokenPrice * appCtx?.usdPrice),

    blockchain: fundsBlockchain,

    platformFee: platformFee,
    networkFee: networkFee,
    tokenPrice: tokenPrice,
    address: userAddress,
  };

  // get clients bank account
  useEffect(() => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/${appCtx.userDetails?.userId}/bank/account/list`,
      },
      (res) => {
        setBankDatas(res);
      }
    );
  }, [openAddBankModal]);

  const handleCardClick = (accountNumber) => {
    if (selectedBankAccount === accountNumber) {
      setSelectedBankAccount(null);
    } else {
      setSelectedBankAccount(accountNumber);
    }
  };

  useEffect(() => {
    if (selectedBankAccount && currentComponent > 3) {
      setOpenSendOtpModal(true);
    }
  }, [currentComponent]);

  const sortedTokens = tokenInChain.sort((a, b) =>
    a?.tokenName?.localeCompare(b?.tokenName)
  );

  console.log("sendtoken", sendToken);
  console.log("tokenInChain", tokenInChain);
  const handleReceiveAmountChange = (event) => {
    setReceiveAmount(event.target.value);
  };

  function closeModal() {
    setOpenModal(false);
  }

  const themedColor = {
    color: `${appCtx?.isDarkMode ? "white" : "black"}`,
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div class=" d-flex justify-content-between align-items-center  py-2 position-relative px-4 rounded-top-lg">
          <h5 class="mb-0"> Redeem from {name}</h5>
          <div class="">
            <div
              className="btn btn-link"
              onClick={() => {
                closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div class="overflow-hidden bg-EEEEEE rounded-xl">
          {currentComponent === 1 && (
            <div style={{ width: "400px" }}>
              <div class="px-5 py-2">
                <p class="text-md my-2"> Amount you had invested</p>

                <div
                  class="d-flex justify-content-between align-items-center  border"
                  style={{ border: "#ced4da", borderRadius: "5px" }}
                >
                  <div className="">
                    {!receiveAmountLoading ? (
                      <input
                        id="receiveAmount"
                        placeholder="you will receive"
                        name="receiveAmount"
                        type="number"
                        keyboardType="numeric"
                        step="any"
                        defaultValue={
                          investedAmount != null && investedAmount !== undefined
                            ? `${investedAmount}`
                            : "0"
                        }
                        readOnly
                        className="ms-3"
                        style={{
                          backgroundColor: `${
                            appCtx?.isDarkMode ? "#09153F" : "white"
                          }`,
                          color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                          width: "100px",
                          border: "none",
                        }}
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress
                          style={{
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                        />
                      </div>
                    )}
                  </div>

                  <div
                    id="sendToken"
                    name="sendToken"
                    class="d-flex align-items-center gap-2"
                    style={{ width: "150px" }}
                  >
                    <img
                      src="/images/indianflag.png"
                      alt=""
                      class=""
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div class="">
                      <p class="m-0 p-0">INR</p>
                      <p class="m-0 p-0">Indian Rupee</p>
                    </div>
                  </div>
                </div>
                <p class="text-md my-2">Enter Amount to Redeem</p>

                <div
                  class="d-flex justify-content-between align-items-center  border"
                  style={{ border: "#ced4da", borderRadius: "5px" }}
                >
                  <div className="">
                    <input
                      id="sendAmount"
                      placeholder="Enter amount"
                      placeholderTextColor="#000000"
                      name="receiveAmount"
                      type="number"
                      keyboardType="numeric"
                      min={0}
                      step="any"
                      value={receiveAmount}
                      onChange={handleReceiveAmountChange}
                      className="ms-3"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#09153F" : "white"
                        }`,
                        color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                        width: "100px",
                        border: "none",
                      }}
                    />
                  </div>

                  <div
                    id="sendToken"
                    name="sendToken"
                    class="d-flex align-items-center gap-2"
                    style={{ width: "150px" }}
                  >
                    <img
                      src="/images/indianflag.png"
                      alt=""
                      class=""
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div class="">
                      <p class="m-0 p-0">INR</p>
                      <p class="m-0 p-0">Indian Rupee</p>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <Accordion className="border">
                    <AccordionSummary
                      as={Card.Header}
                      eventKey="0"
                      className="d-flex justify-content-between align-items-center "
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#09153F" : "white"
                        }`,
                        color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                      }}
                      onClick={() => setExpanded(!expanded)}
                    >
                      <p className="text-[#3B0076] font-medium text-md mb-0">
                        You will receive ₹{receiveAmount}
                      </p>
                      <p className="ms-4 mb-0 ">View Breakup</p>
                      <div className="d-flex align-items-center ms-2">
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          rotation={expanded ? 180 : 0}
                          className="ms-1"
                          style={themedColor}
                        />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      eventKey="0"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#09153F" : "white"
                        }`,
                        color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                      }}
                    >
                      <>
                        <div className=" text-sm font-medium">
                          <div className="d-flex justify-content-between align-items-center ">
                            <p className="mb-1">Platform Fee (0.4%)</p>
                            <p className="mb-1">₹{platformFee || 0}</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">Network Fee</p>
                            <p className="mb-1"> ₹{networkFee || 0}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center text-sm font-medium">
                          <p className="mb-1">Total Fee</p>
                          <p className="mb-1">₹{totalFee || 0}</p>
                        </div>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          {currentComponent === 2 && (
            <div className="p-3">
              <p className="text-xl font-weight-bold text-center mb-1">
                Select the Bank
              </p>
              <p className="text-lg font-weight-medium text-center mb-3">
                We'll send funds to the selected account
              </p>
              <div className="d-flex flex-column gap-3">
                {bankDatas && bankDatas.length === 0 ? (
                  <p className="text-center my-5">
                    You haven't added any bank accounts. Please add an account
                    to proceed further.
                  </p>
                ) : (
                  bankDatas.length !== 0 &&
                  bankDatas.map((bankData, index) => (
                    <div
                      key={index}
                      className={`w-100 h-14 px-4 py-2 rounded-lg  d-flex justify-content-between align-items-center inline-flex cursor-pointer`}
                      style={{
                        border:
                          selectedBankAccount === bankData?.accountNumber
                            ? "1px solid #8B5CF6"
                            : "1px solid #718096",
                        color:
                          selectedBankAccount === bankData?.accountNumber
                            ? "#8B5CF6"
                            : "#718096",
                      }}
                      onClick={() => handleCardClick(bankData?.accountNumber)}
                    >
                      <div className="w-100 d-flex d-flex justify-content-between align-items-center">
                        <div className="h-5 d-flex justify-content-start align-items-center gap-3">
                          <div className="d-flex justify-content-start align-items-center gap-2">
                            <div className="text-base font-normal">
                              {bankData?.accountNumber}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {/* Replace with your FontAwesomeIcon */}
                          <FontAwesomeIcon
                            icon={faArrowRightFromBracket}
                            style={{ themedColor }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="d-flex justify-content-center mt-3 mb-2 w-100">
                <button
                  onClick={() => {
                    setOpenAddBankModal(true);
                  }}
                  className="btn btn-outline-secondary "
                >
                  <p className="mb-0"> + Add New Bank</p>
                </button>
              </div>
            </div>
          )}
          {currentComponent === 3 && (
            <div className="px-5 py-2">
              <>
                <p>
                  <span className="font-medium">Account No:</span>
                  <span className="">{selectedBankAccount}</span>
                </p>
                <p>
                  <span className="font-medium">You will receive:</span>
                  <span className="">{receiveAmount - totalFee} INR</span>
                </p>
              </>
            </div>
          )}

          <div className="w-100 d-flex align-items-center justify-content-center my-2 ">
            <button
              onClick={() => {
                if (receiveAmount > investedAmount) {
                  setMessage("Can't redeem more than you invested !!");
                  setAlert(true);
                } else {
                  setCurrentComponent(currentComponent + 1);
                }
              }}
              disabled={
                !receiveAmount ||
                (!selectedBankAccount && currentComponent === 2)
              }
              className="btn btn-primary px-5"
            >
              Proceed
            </button>
          </div>
          <div
            className="d-flex flex-column align-items-center mx-auto px-4 pb-2"
            style={{ width: "400px" }}
          >
            <p className="text-sm">
              This payment method works for Indian bank accounts. If you don't
              have an Indian bank account, try our partner&nbsp;
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleBuyAllToken();
                }}
              >
                Onramp.money
              </span>
            </p>
          </div>
        </div>
      </Modal>
      {openSendOtpModal && (
        <SignupPageModal
          openModal={openSendOtpModal}
          setOpenModal={setOpenSendOtpModal}
          setOpenSendOtpModal={setOpenSendOtpModal}
          isFundsOfframpTransaction={true}
          fundsOffRampTransactionData={OffRampTransactionData}
          setOpenCryptoFundOfframpModal={setOpenModal}
        />
      )}
      <AddBankModal
        openModal={openAddBankModal}
        setOpenModal={setOpenAddBankModal}
      />
    </>
  );
};

export default CryptoFundOffRampModal;
