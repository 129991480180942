import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { appActions } from "../../context/app-slice";
import { useWalletView } from "../../hooks/useWalletView";
import { useCryptoFund } from "../cryptoFund/useCryptoFund";
import { usePortfolio } from "./usePortfolio";
import axios from "axios";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { CircularProgress } from "@mui/material";
import UsersCryptoFundCard from "../cryptoFund/UsersCryptoFundCard";
import PortfolioTopCard from "./PortfolioTopCard";
import PortfolioLineChart from "./PortfolioLineChart";
import UsersCryptoBasketCard from "../cryptobasket/UsersCryptoBasketCard";
import { useCryptobasket } from "../cryptobasket/useCryptobasket";
import TokenCard from "../Tokens/TokenCard";

const PortFolio = () => {
  const appCtx = useSelector((state) => state.app);
  //   const {
  //     GetTokenBalance,
  //     GetPortfolio,
  //     GetSubscribedBasketList,
  //     GetSubscribedCryptoFundList,
  //   } = HomeScreenAPI();
  const { fetchUsersCryptoFund, usersCryptoFund, usersCryptoFundLoading } =
    useCryptoFund();

  const {
    GetPortfolio,
    GetSubscribedBasketList,
    GetSubscribedCryptoFundList,
    isUserSubscribed,
  } = usePortfolio();
  //   const { GetPosts } = CryptoLensApi();
  const {
    allCoin,
    allCoinPrice,
    usersToken,
    isCoinLoading,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
  } = useWalletView();
  const [TokenList, setTokenList] = useState([]);
  const [TokenLoad, setTokenLoad] = useState(false);
  const [graphDataLoading, setGraphDataLoading] = useState(false);
  const [TokenPrice, setTokenPrice] = useState([]);
  const [TokenPriceLoad, setTokenPriceLoad] = useState(false);
  const [tokenBalance, setTokenBalance] = useState([]);
  const [portfolioLoading, setPortfolioLoading] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [basketList, setBasketList] = useState([]);
  const [subscribedCryptoFundList, setSubscribedCryptoFundList] = useState([]);
  const [basketLoading, setBasketLoading] = useState(false);
  const [cryptoFundLoading, setCryptoFundLoading] = useState(false);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [userNames, setUserName] = useState([]);
  const [lensModalVisible, setLensModalVisible] = useState(false);
  const [refreshControl, setRefreshControl] = useState(false);
  const [ShowSellModal, setShowSellModal] = useState(false);
  const [sellWebView, setSellWebView] = useState(false);
  const [webViewLoad, setWebViewLoad] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(false);

  const [chosenTab, setChosenTab] = useState(1);
  const [UsersTokenCount, setUsersTokenCount] = useState(false);
  const [TokenCount, setTokenCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalpage, setTotalpage] = useState(0);
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);
  const [openTalewalletofframpModal, setOpenTalewalletOfframpModal] =
    useState(false);

  const [selectedBasketMarketCap, setSelectedBasketMarketCap] = useState("");
  const [selectedTokenMarketCap, setSelectedTokenMarketCap] = useState("");
  const [filteredTokenList, setFilteredTokenList] = useState([]);
  const [filteredBaskets, setFilteredBaskets] = useState([]);
  const [selectedPerformanceTab, setSelectedPerformanceTab] =
    useState("balance");

  const [graphData, setGraphData] = useState([]);
  const [minimumPrice, setMinimumPrice] = useState(null);
  const [maximumPrice, setMaximumPrice] = useState(null);

  const [marketCapRank, setMarketCapRank] = useState();
  const [marketCap, setMarketCap] = useState();
  const [minTime, setMinTime] = useState(null);
  const [maxTime, setMaxTime] = useState(null);
  const [timestamp, setTimestamp] = useState([]);
  const [price, setPrice] = useState([]);
  const [lineChartFormat, setLineChartFormat] = useState([]);
  const [initialPrice, setInitialPrice] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [totalReturns, setTotalReturns] = useState(null);

  const Data = [
    {
      id: 0,
      value: "1W",
      range: "week",
      name: "1 week",
    },
    {
      id: 1,
      value: "1M",
      range: "month",
      name: "1 month",
    },
    {
      id: 2,
      value: "6M",
      range: "6month",
      name: "6 month",
    },
    {
      id: 3,
      value: "1Y",
      range: "year",
      name: "1 year",
    },
  ];
  const [selectedItem, setSelectedItem] = useState(Data[0].id);

  const selectedRange = Data[selectedItem].range;

  const makeRequest = useAuthorizedHttp();

  const dispatch = useDispatch();

  let isTotalReturnsANumber = typeof totalReturns === "number";

  useEffect(() => {
    getAllCoin(0, 100);
    getAllCoinPrice();
    getUsersToken();
    GetPortfolio(setPortfolioLoading, setPortfolioData);
    fetchPrivateBasket();

    fetchUsersCryptoFund();
  }, [appCtx.blockchain]);
  const {
    fetchPublicBasket,
    publicBasket,
    privateBasket,
    fetchPrivateBasket,
    privateBasketLoading,
    publicBasketLoading,
    openBasketModal,
    setopenBasketModal,
  } = useCryptobasket();

  const handleSubscribe = () => {
    setLensModalVisible(true);
  };
  const OnSubClose = () => {
    setLensModalVisible(false);
  };

  const onCloseSellModal = () => {
    setShowSellModal(false);
  };

  const onCloseInfoModal = () => {
    setShowInfoBox(false);
  };

  const handlePerformanceButtonClick = (tabName) => {
    setSelectedPerformanceTab(tabName);
  };

  // useEffect(() => {
  //   makeRequest(
  //     {
  //       url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/account?wallet=TALEWALLET`,
  //     },
  //     (data) => {
  //       // && account?.blockchain.toUpperCase() === appCtx.selectedChain.toUpperCase())
  //       const walletData = data?.filter((account) => {
  //         return account?.wallet === "TALEWALLET";
  //       });
  //       let allChain = [];
  //       data?.map((items) => {
  //         allChain = [...allChain, items?.blockchain];
  //       });
  //       dispatch(appActions.setChain(allChain));
  //       dispatch(appActions.setWalletAddress(walletData));

  //       // console.log(data);
  //     },
  //     () => {
  //       // setLoading(false);
  //     },
  //     () => {
  //       // navigate('/logout')
  //     }
  //   );
  //   //     }
  //   // }
  // }, [dispatch, makeRequest]);

  // doughnut pie chart data
  const widthAndHeight = 150;
  const series = [123, 321, 123, 789];

  // const data = [
  //   { percentage: 30, cap: "Large Cap" },
  //   { percentage: 40, cap: "Mid Cap" },
  //   { percentage: 20, cap: "Mid Cap" },
  //   { percentage: 10, cap: "Small Cap" },
  // ];
  const sliceColor = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF9F50",
    "#FF9F70",
    "#36A3EB",
    "#36A1EB",
  ];

  const sliceColor2 = sliceColor.slice(0, 4 || 1);

  const handleTokenMarketCapFilter = (marketCapType) => {
    setSelectedTokenMarketCap(marketCapType);

    const filteredList = TokenList.filter(
      (token) => token.marketcapType === marketCapType
    );

    setFilteredTokenList(filteredList);
  };

  //LineChart Data fetching.

  useEffect(() => {
    setGraphDataLoading(true);
    let config = {
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/portfolio/history?range=${selectedRange}`,
      method: "get",
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        console.log("response from user portfolio history api", response.data);
        setGraphData(response?.data);

        setGraphDataLoading(false);
      })
      .catch(function (error) {
        console.log("Get Graph Data Error: ", error);
        setGraphDataLoading(false);
      });
  }, [selectedRange]);

  //Minimum and maximum price of tokens.

  useEffect(() => {
    if (graphData?.length > 0) {
      const balances = graphData?.map((item) => item.balance);
      const minBalance = Math.min(...balances);
      const maxBalance = Math.max(...balances);

      setInitialPrice(balances[balances?.length - 1]);
      setCurrentPrice(balances[0]);

      setMinimumPrice(minBalance);
      setMaximumPrice(maxBalance);

      const dates = graphData?.map((item) => item?.createdDate);
      const minId = Math.min(...dates);
      const maxId = Math.max(...dates);

      setMinTime(minId);
      setMaxTime(maxId);

      const chartformat = graphData?.map((item) => ({
        x: item?.createdDate,
        y: item?.balance,
      }));

      setLineChartFormat(chartformat);
    }
  }, [graphData]);

  let investment = 0;
  if (portfolioData?.length > 0) {
    portfolioData?.map((item) => (investment += item?.investment));
  }

  useEffect(() => {
    if (initialPrice && currentPrice) {
      const Total_Return = ((currentPrice - initialPrice) / initialPrice) * 100;
      setTotalReturns(Total_Return);
    }
  }, [initialPrice, currentPrice, selectedRange]);
  return (
    <MainWrapper>
      <div class="">
        <div class="container-fluid">
          <div className="row">
            {/* portfolio card  */}
            <div className="col-12">
              <div className="card">
                <div className="card-header border-0 pb-3">
                  <h4 className="card-title">My Portfolio</h4>
                </div>
                <div className="card-body px-0 pt-0">
                  {portfolioLoading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <CircularProgress
                        style={{
                          fontSize: "10px",
                          width: "25px",
                          height: "25px",
                        }}
                        className="py-10"
                      />
                    </div>
                  ) : (
                    <>
                      <PortfolioTopCard data={portfolioData} />
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* performance  */}
            <div className="col-xl-12">
              <div class="card coin-content">
                <div class="card-body">
                  {/* top part  */}
                  <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <div class="card-body pt-2 ">
                      <ul class="nav nav-pills d-flex justify-content-end">
                        {Data?.map((item) => (
                          <li
                            class="nav-link"
                            role="presentation"
                            key={item?.id}
                          >
                            <button
                              onClick={() => {
                                setSelectedItem(item?.id);
                              }}
                              class={`nav-link   ${
                                selectedItem === item?.id ? "active" : ""
                              }`}
                              role="tab"
                              aria-selected="true"
                            >
                              {item?.value}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* graph part  */}

                  <div id="marketChart">
                    <PortfolioLineChart
                      lineChartFormat={lineChartFormat}
                      selecteddate={selectedItem}
                      graphDataLoading={graphDataLoading}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Crypto fund subscribed  */}
            <div className="col-12 my-order-ile">
              <div className="card">
                <div className="card-header border-0 pb-3">
                  <h4 className="card-title">Crypto fund subscribed</h4>
                </div>
                <div className="card-body px-0 pt-0">
                  {usersCryptoFundLoading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <CircularProgress
                        style={{
                          fontSize: "10px",
                          width: "25px",
                          height: "25px",
                        }}
                        className="py-10"
                      />
                    </div>
                  ) : !usersCryptoFundLoading &&
                    usersCryptoFund?.length === 0 ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      style={{
                        width: "60%",
                        margin: "50px auto",
                        textAlign: "center",
                      }}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="me-2 alert-icon"
                      >
                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                      </svg>
                      You don't have any Crypto Fund. Please create one.
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : (
                    <>
                      {usersCryptoFund?.map((item) => (
                        <UsersCryptoFundCard item={item} />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* my Crypto Baskets  */}
            <div className="col-12 my-order-ile">
              <div className="card">
                <div className="card-header border-0 pb-3">
                  <h4 className="card-title">My Crypto Baskets</h4>
                  {/* <button
                    onClick={() => {
                      setOpenCreateBasketModal(true);
                    }}
                    class="btn btn-primary btn-sm"
                  >
                    Create
                  </button> */}
                </div>
                <div className="card-body px-0 pt-0">
                  {privateBasketLoading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <CircularProgress
                        style={{
                          fontSize: "10px",
                          width: "25px",
                          height: "25px",
                        }}
                        className="py-10"
                      />
                    </div>
                  ) : privateBasket?.length === 0 ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      style={{
                        width: "60%",
                        margin: "50px auto",
                        textAlign: "center",
                      }}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="me-2 alert-icon"
                      >
                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                      </svg>
                      You don't have any Crypto Fund. Please create one.
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : (
                    <>
                      {privateBasket.map((item) => (
                        <UsersCryptoBasketCard item={item} />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* my token  */}

            <div class="col-12 my-order-ile">
              <div class="card">
                <div class="card-header border-0 pb-3">
                  <h4 class="card-title">My Tokens</h4>
                </div>
                <div class="card-body px-0 pt-0 ">
                  {isCoinLoading ? (
                    <div
                      class="d-flex justify-content-center align-items-center "
                      style={{ minHeight: "100px" }}
                    >
                      <CircularProgress
                        sryle={{ color: "#BBFF00", fontSize: "10px" }}
                        className="py-10"
                        fontSize="small"
                        style={{ width: "25px", height: "25px" }}
                      />
                    </div>
                  ) : !isCoinLoading &&
                    (usersToken.length === 0 ||
                      usersToken.every((item) => item?.balance === 0)) ? (
                    <div
                      class="alert alert-danger alert-dismissible fade show"
                      style={{
                        width: "60%",
                        margin: "50px auto",
                        textAlign: "center",
                      }}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="me-2 alert-icon"
                      >
                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                      </svg>
                      You don't have any token
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : (
                    allCoin.map((coin, index) => {
                      const TokenBalance = usersToken.filter(
                        (element) =>
                          coin.symbol === element.token &&
                          coin.blockchain === element.blockchain
                      );

                      if (TokenBalance[0]?.balance > 0) {
                        return (
                          <div>
                            <TokenCard
                              key={index}
                              coin={coin}
                              allCoinPrice={allCoinPrice}
                              usersToken={usersToken}
                            />
                          </div>
                        );
                      }
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default PortFolio;
