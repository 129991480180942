import React, { useEffect, useState } from "react";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TpSlModal from "./TpSlModal";
import { CircularProgress } from "@mui/material";
import OpenOrderCard from "./OpenOrderCard";

const OpenOrderComponent = ({ selectedFundId, setSelectedFundId }) => {
  const [orderList, setOrderList] = useState([]);
  const [orderListLoading, setOrderListLoading] = useState(false);
  const [openTpSLModal, setOpenTpSLModal] = useState(false);
  const makeRequest = useAuthorizedHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    if (selectedFundId) {
      setOrderListLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fund/${selectedFundId}/trade/order/list?status=open`,
          method: "get",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          setOrderList(data);

          setOrderListLoading(false);
        },
        () => {
          setOrderListLoading(false);
        },
        (error) => {
          setOrderListLoading(false);
        }
      );
    }
  }, [selectedFundId]);
  return (
    <div class={`card-body pt-0 `}>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-order"
          role="tabpanel"
          aria-labelledby="nav-order-tab"
        >
          <div class="table-responsive dataTabletrade ">
            <table
              id="example-2"
              class="table display orderbookTable"
              style={{ minWidth: "845px" }}
            >
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Token</th>
                  <th>Marked-Price</th>
                  <th>Buy Amount</th>
                  <th>Sell Amount</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {orderListLoading ? (
                  <tr>
                    <td colSpan="5" className="w-100">
                      <div className="d-flex justify-content-center align-items-center py-5">
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="ml-2"
                          fontSize="small"
                        />
                      </div>
                    </td>
                  </tr>
                ) : !orderListLoading && orderList?.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="w-100">
                      <div className="d-flex justify-content-center align-items-center text-center">
                        OrderList is not available for this fund
                      </div>
                    </td>
                  </tr>
                ) : (
                  orderList?.map((item, index) => (
                    <OpenOrderCard
                      key={index}
                      item={item}
                      setOpenTpSLModal={setOpenTpSLModal}
                    />
                  ))
                )}
              </tbody>

              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <TpSlModal openModal={openTpSLModal} setOpenModal={setOpenTpSLModal} />
    </div>
  );
};

export default OpenOrderComponent;
