import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getChainId, nativeToken } from "../config";
import { appActions } from "../context/app-slice";
import { ethers } from "ethers";

export const useMetamask = () => {
  const dispatch = useDispatch();

  const appCtx = useSelector((state) => state.app);
  const isWalletsRoute = window.location.pathname === "/wallet";
  let blockchain = isWalletsRoute
    ? appCtx?.walletBlockchain
    : appCtx?.blockchain;

  const address = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === blockchain
  )?.address;

  const defaultHeaders = {
    "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
    "Content-Type": "application/json",
  };

  if (appCtx.authToken !== "") {
    defaultHeaders["X-Auth-Token"] = appCtx.authToken;
  }

  const switchChainandOpenModal = async (
    setOpenModal,
    targetBlockchain = blockchain === "ALL" ? "BSC" : blockchain
  ) => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    if (!provider) {
      toast.error("Metamask is not installed, please install!"); // Check if MetaMask is installed
      return;
    }

    const chainId = await provider.send("eth_chainId", []);
    let chainIdNumber = parseInt(chainId, 16);

    if (chainIdNumber !== getChainId(targetBlockchain)) {
      try {
        await provider.send("wallet_switchEthereumChain", [
          { chainId: "0x" + getChainId(targetBlockchain).toString(16) },
        ]);
        setOpenModal(true);
      } catch (switchError) {
        if (switchError.code === 4902) {
          // Error code for network not added to MetaMask
          toast.error(
            "This network is not available in your metamask, please add it"
          );
        }
        toast.error("Failed to switch metamask network to current blockchain");
      }
    } else {
      setOpenModal(true);
    }
  };

  return {
    switchChainandOpenModal,
  };
};
