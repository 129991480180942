import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactApexChart from "react-apexcharts";
import MainWrapper from "../../component/wrapper/MainWrapper";
import CandleChart from "../../component/barChart/CandleChart";
import { useWalletView } from "../../hooks/useWalletView";
import { CircularProgress, Skeleton, Stack } from "@mui/material";
import HomeCarouselTokenCard from "../../component/TokenLIstCard/HomeCarouselTokenCard";
import { useDispatch } from "react-redux";
import { appActions } from "../../context/app-slice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import Dropdown from "react-bootstrap/Dropdown";
import HomeCryptoFundAreaChart from "../../component/areachart/HomeCryptoFundAreaChart";
import { useCryptobasket } from "../cryptobasket/useCryptobasket";
import CryptoBasketAreaChart from "../../component/areachart/CryptoBasketAreaChart";
import { useCryptoFund } from "../cryptoFund/useCryptoFund";
import HomeCryptoFundCard from "../cryptoFund/HomeCryptoFundCard";
import HomeCryptoBasketCard from "../cryptobasket/HomeCryptoBasketCard";
import HomeTokenCard from "./HomeTokenCard";

const ExpertsDashboard = () => {
  const [wallet, setWallet] = useState([{ address: "" }]);
  const [selectedFund, setSelectedFund] = useState();
  const [selectedBasket, setSelectedBasket] = useState();

  const [selectedHistoryTab, setSelectedHistoryTab] = useState("week");
  const [selectedFundTab, setSelectedFundTab] = useState("w");
  const [composition, setComposition] = useState([]);
  // const [composition, setComposition] = useState([
  //   {
  //     id: 1,
  //     fundId: 1,
  //     tokenId: 5,
  //     token: "USDT",
  //     tokenAmount: 60.0,
  //     tokenPercentage: 60.0,
  //   },
  //   {
  //     id: 2,
  //     fundId: 1,
  //     tokenId: 6,
  //     token: "USDC",
  //     tokenAmount: 40.0,
  //     tokenPercentage: 40.0,
  //   },
  // ]);
  const [compositionLoading, setCompositionLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [selectedFundTokenTab, setSelectedFundTokenTab] = useState(null);
  const [selectedFundTokenName, setSelectedFundTokenName] = useState(null);

  const {
    expertsCryptoFund,
    expertsCryptoFundLoading,
    fetchExpertsCryptoFund,
    fetchAllCryptoFundsStats,
    allFundsStats,
    allCryptoFundsStatsLoading,
  } = useCryptoFund();

  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    appCtx,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    isCoinPriceLOading,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    totalpage,
    handleRefresh,
    setAllcoinPrice,
    listedToken,
    listedTokenLoading,
    getListedTokens,
  } = useWalletView();

  const {
    fetchPublicBasket,
    publicBasket,
    privateBasket,
    fetchPrivateBasket,
    privateBasketLoading,
    publicBasketLoading,
    openBasketModal,
    setopenBasketModal,
  } = useCryptobasket();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();

  const FundsTimeframeData = [
    {
      id: 0,
      value: "1H",
      range: "h",
    },
    {
      id: 1,
      value: "1D",
      range: "d",
    },
    {
      id: 2,
      value: "7D",
      range: "w",
    },
    {
      id: 3,
      value: "1M",
      range: "m",
    },
    {
      id: 4,
      value: "3M",
      range: "3m",
    },
    {
      id: 5,
      value: "6M",
      range: "6m",
    },
    {
      id: 6,
      value: "1Y",
      range: "y",
    },
  ];
  const Data = [
    {
      id: 0,
      value: "w",
      range: "week",
    },
    {
      id: 1,
      value: "m",
      range: "month",
    },
    {
      id: 2,
      value: "6m",
      range: "6month",
    },
    {
      id: 3,
      value: "y",
      range: "year",
    },
  ];

  // setting role for test
  useEffect(() => {
    // dispatch(appActions.setRole("user"));
    // dispatch(appActions.setRole("expert"));
  }, []);

  useEffect(() => {
    getListedTokens();
  }, [appCtx.blockchain]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    let fundIds = expertsCryptoFund?.map((item) => item.fundId);
    console.log("fundIds", fundIds);
    if (fundIds?.length !== 0) {
      fetchAllCryptoFundsStats(fundIds);
    }
  }, [expertsCryptoFund?.length]);

  // fetching usd price
  useEffect(() => {
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/quote`,
        data: {
          fiatAmount: 5000,
          token: "USDC",
          network: "BSC",
        },
        method: "post",
        headers: {
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        dispatch(appActions.setUsdPrice(data?.data?.rate));
      },
      () => {
        //  setReceiveAmountLoading(false);
      },
      (error) => {
        //  setReceiveAmountLoading(false);
      }
    );
  }, []);
  // wallet setup
  useEffect(() => {
    // if (appCtx.walletAddress[0]?.address!==='loading') {
    if (appCtx.authToken) {
      let config = {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/account`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
          // "X-App-Token": process.env.REACT_APP_X_APP_TOKEN
        },
      };
      axios(config)
        .then((response) => {
          let data = response.data;
          const walletData = data?.filter(
            (account) => account.wallet === "TALEWALLET"
          );
          dispatch(
            appActions.setCustodial(
              walletData.find((obj) => obj.blockchain === appCtx.blockchain)
                ?.custodial
            )
          );
          setWallet(walletData);
          dispatch(appActions.setWalletAddress(walletData));
          // if (walletData?.length === 0) {
          //   setOpenFirstUSerWalletScreen(true);
          // }

          // setLoading(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate("/login");
          }
          // setLoading(false);
        });
    }
  }, [appCtx.authToken, dispatch, makeAuthorizedRequest]);

  useEffect(() => {
    if (wallet && wallet.length === 0) {
      makeAuthorizedRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/wallet/setup`,
          method: "post",
          data: {
            blockchain: "BSC",
            wallet: "TALEWALLET",
            marketplaceAddress: "0",
          },
        },
        (data) => {
          setTimeout(() => {
            setWallet([data]);
            dispatch(appActions.setCustodial(data.custodial));
            dispatch(appActions.setWalletAddress([data]));
            // dispatch(appActions.setBlockChain(data.blockchain));
             dispatch(appActions.setBlockChain("ALL"));
          
          }, 1000);
        },
        (data) => {},
        () => {
          // setWalletCreationProgress(2);
        }
      );
    }
  }, [makeAuthorizedRequest, wallet]);

  useEffect(() => {
    fetchExpertsCryptoFund();
  }, []);
  useEffect(() => {
    fetchPrivateBasket();
  }, []);
  useEffect(() => {
    setSelectedFund(expertsCryptoFund[0]);
  }, [expertsCryptoFund]);

  useEffect(() => {
    setSelectedBasket(privateBasket[0]);
  }, [privateBasket]);

  const handleFundChange = (selectedFundId) => {
    const fund = expertsCryptoFund?.find(
      (fund) => fund.fundId === selectedFundId
    );
    setSelectedFund(fund);
  };
  const handleBasketChange = (selectedBasketId) => {
    const basket = privateBasket?.find(
      (item) => item?.basketId === selectedBasketId
    );
    setSelectedBasket(basket);
  };

  useEffect(() => {
    setCompositionLoading(true);
    if (selectedFund?.fundId) {
      makeAuthorizedRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${selectedFund?.fundId}/composition`,
          method: "get",
        },
        (data) => {
          setComposition(data);
          setCompositionLoading(false);
        },

        (err) => {
          setCompositionLoading(false);
        }
      );
    }
  }, [selectedFund]);

  return (
    <MainWrapper>
      <div className={`${appCtx?.isDarkMode ? "text-white" : "text-black"}`}>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              <div class="col-xl-12">
                <div class="">
                  {isCoinLoading || isCoinPriceLOading ? (
                    <div className="d-flex flex-row justify-between gap-2 mb-4">
                      {[...Array(4)].map((_, index) => (
                        <div className="flex-grow-1" key={index}>
                          <Stack spacing={1}>
                            <Skeleton height={130} variant="rounded" />
                          </Stack>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      infinite={true}
                      autoPlay={true}
                      transitionDuration={500}
                      arrows={false}
                    >
                      {allCoin.map((coin, index) => (
                        <HomeCarouselTokenCard
                          key={index}
                          coin={coin}
                          allCoinPrice={allCoinPrice}
                          usersToken={usersToken}
                        />
                      ))}
                    </Carousel>
                  )}
                </div>
                <div class="row g-3">
                  {/* Crypto fund card  */}
                  <div class="col-xl-6">
                    {expertsCryptoFundLoading ? (
                      <div class="d-flex justify-content-center align-items-center card crypto-chart">
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                          fontSize="small"
                        />
                      </div>
                    ) : (
                      <>
                        {expertsCryptoFund?.length === 0 ? (
                          <div className="card crypto-chart">
                            <div className="mt-5">
                              <p className="text-center  ">
                                You don't have any Crypto Fund. Please create
                                one.
                              </p>
                              <div className="d-flex justify-center">
                                <button
                                  className="btn btn-success mx-auto "
                                  onClick={() => navigate("/crypto-fund")}
                                >
                                  Create fund
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="card crypto-chart">
                            <div class="card-header pb-0 border-0 flex-wrap">
                              <h4 class="card-title">Crypto Fund</h4>
                              <button
                                onClick={() => {
                                  navigate("/crypto-fund");
                                }}
                                className="btn btn-primary"
                              >
                                View more
                              </button>
                            </div>
                            <div class="card-body pt-2">
                              {expertsCryptoFund?.slice(0, 5)?.map((item) => (
                                <HomeCryptoFundCard
                                  item={item}
                                  allFundsStats={allFundsStats}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* Crypto basket card  */}
                  <div class="col-xl-6" style={{ minHeight: "300px" }}>
                    {privateBasketLoading ? (
                      <div class="d-flex justify-content-center align-items-center card crypto-chart">
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                          fontSize="small"
                        />
                      </div>
                    ) : (
                      <>
                        {privateBasket?.length === 0 ? (
                          <div className="card crypto-chart">
                            <div className="mt-5">
                              <p className="text-center  ">No basket found</p>
                            </div>
                          </div>
                        ) : (
                          <div class="card crypto-chart">
                            <div class="card-header pb-0 border-0 flex-wrap">
                              <h4 class="card-title">Crypto Basket</h4>
                              <button
                                onClick={() => {
                                  navigate("/crypto-basket");
                                }}
                                className="btn btn-primary"
                              >
                                View more
                              </button>
                            </div>
                            <div class="card-body pt-2">
                              {privateBasket?.slice(0, 5)?.map((basket) => (
                                <HomeCryptoBasketCard basket={basket} />
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/*Token card  */}

                  <div class="col-xl-12" style={{ minHeight: "300px" }}>
                    {listedTokenLoading ? (
                      <div class="d-flex justify-content-center align-items-center card crypto-chart">
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                          fontSize="small"
                        />
                      </div>
                    ) : (
                      <>
                        {listedToken?.length === 0 ? (
                          <div className="card crypto-chart">
                            <div className="mt-5">
                              <p className="text-center  ">No Token found</p>
                            </div>
                          </div>
                        ) : (
                          <div class="card">
                            <div class="card-header pb-0 border-0 flex-wrap">
                              <div class="mb-0">
                                <h4 class="card-title">Tokens</h4>
                              </div>
                            </div>
                            <div class="card-body pt-2">
                              {listedToken?.slice(0, 10)?.map((token) => (
                                <HomeTokenCard token={token} />
                              ))}
                              <div className="d-flex justify-content-center my-3">
                                <button
                                  onClick={() => {
                                    navigate("/tokens");
                                  }}
                                  className="btn btn-primary"
                                >
                                  View more
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default ExpertsDashboard;
