import { useEffect, useRef, useState } from "react";
import { useAccount, useChainId } from "wagmi";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { toast } from "react-toastify";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { nativeToken } from "../../config";
import { Card } from "react-bootstrap";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import qs from "qs";
import { formatUnits, parseUnits } from "ethers";
import {
  useReadContract,
  useBalance,
  useSimulateContract,
  useWriteContract,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from "wagmi";
import { erc20Abi } from "viem";
import { useTailCoin } from "../../hooks/useTailCoin";

import {
  POLYGON_EXCHANGE_PROXY,
  MAX_ALLOWANCE,
  AFFILIATE_FEE,
  FEE_RECIPIENT,
} from "../../component/swap/NonCustodialSwap/constants";
import axios from "axios";
import MultiSwapSuccessModal from "./MultiSwapSuccessModal";

const BasketPaymentusingTokenModal = ({
  openModal,
  setOpenModal,
  amount,
  basketId,
  tokenArray,
  basketBlockchain,
  symbol,
  totalInvestment,
  singleBasket,
  setOpenTalewalletOnrampModal,
  minInvest,
  setopenPaymentMethodModal,
}) => {
  const appCtx = useSelector((state) => state.app);
  const chainId = useChainId() || 137;
  console.log("chainId: ", chainId);
  const [sendToken, setSendToken] = useState("");
  const [receiveTokenPrice, setReceiveTokenPrice] = useState(null);

  const [isCopied, setIsCopied] = useState(false);
  const [finalize, setFinalize] = useState(false);

  const [expanded, setExpanded] = useState(null);

  const [receiveToken, setReceiveToken] = useState(
    symbol || nativeToken[basketBlockchain]
  );

  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openMultiSwapSuccessModal, setOpenMultiSwapSuccessModal] =
    useState(false);

  const networks = {
    POLYGON: "matic20",
    BSC: "bep20",
    ETHEREUM: "erc20",
    ALGORAND: "algo",
    SOLANA: "sol",
  };

  const hardCodeGasFee = {
    POLYGON: 10,
    BSC: 30,
    ETHEREUM: 300,
    ALGORAND: 0,
    SOLANA: 10,
  };

  var network;

  network = networks[basketBlockchain];

  const [receiveAmount, setReceiveAmount] = useState(null);

  const [upiClicked, setUpiClicked] = useState(false);
  const [bankClicked, setBankClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fourDigit, setFourDigit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [networkFee, setNetworkFee] = useState(
    hardCodeGasFee[basketBlockchain] / appCtx?.usdPrice
  );
  const [receiveAmountLoading, setReceiveAmountLoading] = useState(false);
  const [receiveTokenName, setReceiveTokenName] = useState(null);
  const [sendAmount, setSendAmount] = useState(amount || minInvest);
  const [sendTokenPriceLoading, setsendTokenPriceLoading] = useState(false);
  const [basketPurchaseLoading, setBasketPurchaseLoading] = useState(false);
  const [custodailBasketPurchaseLoading, setCustodialBasketPurchaseLoading] =
    useState(false);
  const [custodailBasketPurchaseSuccess, setCustodailBasketPurchaseSuccess] =
    useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [quote, setQuote] = useState({});
  const [sendTokenData, setSendTokenData] = useState({});
  const [nativeTokenPriceLoading, setNativeTokenPriceLoading] = useState(false);
  const [nativeTokenData, setNativeTokenData] = useState({});
  const [sendTokenMoreData, setSendTokenMoreData] = useState({});
  const [receiveTokenMoreData, setReceiveTokenMoreData] = useState({});

  const componentRef = useRef(null);

  const {
    getAllCoin,
    getAllCoinPrice,
    allCoin,
    allCoinPrice,
    isCoinPriceLOading,
    getSingleTokenBalance,
  } = useWalletView();

  const {
    tokenDetails,
    getTokenDetailsbyId,
    getAllcoinprice,
    // allCoinPrice,
    getDescriptionData,
    descriptionData,
  } = useTailCoin();

  const makeRequest = useAuthorizedHttp();

  const { address } = useAccount();

  const {
    data: hash,
    isPending,
    error,
    sendTransaction,
  } = useSendTransaction();

  function closeModal() {
    setOpenModal(false);
  }
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };

  const usersWalletAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === basketBlockchain
  )?.address;

  const sendTokenObject = allCoin.find((token) => token?.symbol === "USDT");

  useEffect(() => {
    getAllCoin();
    getAllCoinPrice();
  }, [appCtx?.blockchain]);
  useEffect(() => {
    if (sendTokenObject?.tokenId) {
      getSingleTokenBalance(
        usersWalletAddress,
        basketBlockchain,
        sendTokenObject?.tokenId,
        setSendTokenData,
        setsendTokenPriceLoading
      ); //usdt

      getTokenDetailsbyId(sendTokenObject?.tokenId, setSendTokenMoreData);
    }
  }, [allCoin, appCtx?.blockchain]);
  // get native token balance
  useEffect(() => {
    let nativeTokenId = allCoin.find(
      (token) => token?.symbol === nativeToken[basketBlockchain]
    )?.tokenId;

    if (nativeTokenId) {
      getSingleTokenBalance(
        usersWalletAddress,
        basketBlockchain,
        nativeTokenId,
        setNativeTokenData,
        setNativeTokenPriceLoading
      );
    }
  }, [basketBlockchain, allCoin]);

  const exchangeProxy = (chainId) => {
    if (chainId === 137) {
      return POLYGON_EXCHANGE_PROXY;
    }
    return POLYGON_EXCHANGE_PROXY;
  };

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  // useEffect(() => {
  //   setIsMetamaskSwapPending(isPending);
  // }, [isPending]);
  // useEffect(() => {
  //   setIsMetamaskSwapConfirming(isConfirming);
  // }, [isConfirming]);
  // useEffect(() => {
  //   setIsMetamaskSwapConfirmed(isConfirmed);
  // }, [isConfirmed]);
  // useEffect(() => {
  //   setMetmaskTransactionHash(hash);
  // }, [hash, isConfirmed]);

  let platformFee;
  let totalFee;

  if (sendAmount !== null && sendAmount !== undefined) {
    platformFee = ((sendAmount * 0.4) / 100).toFixed(2);
  } else {
    // Handle the case when sendAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = (parseFloat(platformFee) + parseFloat(networkFee)).toFixed(2);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }

  const themedColor = {
    color: `${appCtx?.isDarkMode ? "white" : "black"}`,
  };

  const width = window.screen.width;
  console.log("sendTokenMoreData", sendTokenMoreData);

  const handleClick = (event) => {
    event.preventDefault();
    // Check if the clicked element has the 'button-id' ID
    if (event.target.id === "sendButton") {
      //  setSendOpen(true);
    } else if (
      event.target.id === "searchbar" ||
      event.target.id === "searchIcon"
    ) {
    } else if (event.target.id === "receiveButton") {
      setOpen(true);
    } else {
      //  setSendOpen(false);
      setOpen(false);
    }
  };

  const getBasketsSingleTokensPrice = (token) => {
    let price =
      Object?.keys(allCoinPrice)?.length > 0 &&
      (sendAmount * (token?.tokenPercent / 100)) /
        allCoinPrice[token?.symbol]?.price;
    if (price) {
      price = price.toFixed(2);
    } else {
      price = 0;
    }
    return price;
  };

  let inSufficientBalance = sendAmount > sendTokenData?.balance;

  let zeroXEndpoint =
    basketBlockchain === "FLOW"
      ? ``
      : basketBlockchain === "ALGORAND"
      ? `${process.env.REACT_APP_ALGORAND_OxAPIEndpoint}`
      : basketBlockchain === "ETHEREUM"
      ? `${process.env.REACT_APP_ETHEREUM_OxAPIEndpoint}`
      : basketBlockchain === "POLYGON"
      ? `${process.env.REACT_APP_POLYGON_OxAPIEndpoint}`
      : basketBlockchain === "BSC"
      ? `${process.env.REACT_APP_BSC_OxAPIEndpoint}`
      : basketBlockchain === "SOLANA"
      ? `${process.env.REACT_APP_SOLANA_OxAPIEndpoint}`
      : basketBlockchain === "AVALANCHE"
      ? `${process.env.REACT_APP_AVALANCHE_OxAPIEndpoint}`
      : basketBlockchain === "NORDEK"
      ? `${process.env.REACT_APP_NORDEK_OxAPIEndpoint}`
      : "";

  const handleCustodialTransfer = async () => {
    if (nativeTokenData?.balance < 0.0003) {
      toast.error(
        `You don't have enough ${nativeToken[basketBlockchain]} to pay the gas fee`
      );
      return;
    }
    setOpenMultiSwapSuccessModal(true);
    setCustodialBasketPurchaseLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/${basketId}/subscribe?custodial=true`,
        method: "post",
        data: {
          token: symbol,
          tokenId: sendTokenObject?.tokenId,
          amount: sendAmount,
        },
      },
      (data) => {
        setCustodialBasketPurchaseLoading(false);
        setCustodailBasketPurchaseSuccess(true);
        // todo::
        // need to add sucess modal
      },
      () => {
        console.log("Error: ");
        setCustodialBasketPurchaseLoading(false);
      },
      () => {
        // navigate('/login')
        setCustodialBasketPurchaseLoading(false);
      }
    );
  };

  // self custody transfer

  const getPercentageToSendTokenAmount = async (tokenPercent) => {
    return new Promise((resolve) => {
      const sendAmt = (sendAmount * tokenPercent) / 100;
      resolve(sendAmt);
    });
  };

  const getMoreTokenDetailsbyId = (id) => {
    return new Promise((resolve, reject) => {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/${id}`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const fetchQuote = (
    sendTokenContractAddress,
    receiveTokenContractAddress,
    parsedSellAmount
  ) => {
    setQuoteLoading(true);
    const params = {
      sellToken: sendTokenContractAddress,
      buyToken: receiveTokenContractAddress,
      sellAmount: parsedSellAmount,
      takerAddress: address,
      feeRecipient: FEE_RECIPIENT,
      buyTokenPercentageFee: AFFILIATE_FEE,
      feeRecipientTradeSurplus: FEE_RECIPIENT,
    };

    return fetch(`${zeroXEndpoint}/swap/v1/quote?${qs.stringify(params)}`, {
      headers: {
        "0x-api-key": process.env.REACT_APP_ZEROEX_API_KEY,
        "0x-chain-id": chainId,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setQuote(data);
        setQuoteLoading(false);
        return data;
      })
      .catch((error) => {
        console.error("Error fetching quote:", error);
        setQuoteLoading(false);
        throw error;
      });
  };

  const performTransactionWithQuote = async (quote, index) => {
    if (sendTransaction && !isPending) {
      try {
        // Send the transaction
        const transactionResponse = sendTransaction({
          gas: quote?.gas,
          to: quote?.to,
          value: quote?.value,
          data: quote?.data,
          gasPrice: quote?.gasPrice,
        });

        // Wait for transaction receipt
        const receipt = await transactionResponse.wait();
      } catch (error) {
        console.error("Error:", error);
        throw error;
      } finally {
        if (index + 1 < singleBasket?.cryptoBasketTokens?.length) {
          handleSelfCustodyTransfer(index + 1);
        } else {
          setOpenMultiSwapSuccessModal(true);
        }
      }
    }
  };
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleSelfCustodyTransfer = async (index = 0) => {
    if (nativeTokenData?.balance < 0.0003) {
      toast.error(
        `You don't have enough ${nativeToken[basketBlockchain]} to pay the gas fee`
      );
      return;
    }
    setBasketPurchaseLoading(true);

    const token = singleBasket.cryptoBasketTokens[index];
    try {
      let sendAmt = await getPercentageToSendTokenAmount(token.tokenPercent);
      console.log("sendAmt", sendAmt);
      const parsedSellAmount = sendAmt
        ? parseUnits(`${sendAmt}`, sendTokenMoreData?.decimals)?.toString()
        : undefined;

      const receiveTokensMoreData = await getMoreTokenDetailsbyId(
        token?.tokenId
      );
      let sendTokenContractAddress = sendTokenMoreData?.contractAddress
        ? sendTokenMoreData?.contractAddress
        : sendTokenMoreData?.symbol === nativeToken[basketBlockchain]
        ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        : null;

      let receiveTokenContractAddress = receiveTokensMoreData?.contractAddress
        ? receiveTokensMoreData?.contractAddress
        : receiveTokensMoreData?.symbol === nativeToken[basketBlockchain]
        ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        : null;

      await delay(1000); // Adding a delay of 1 second before fetchQuote

      const quote = await fetchQuote(
        sendTokenContractAddress,
        receiveTokenContractAddress,
        parsedSellAmount
      );

      await delay(1000); // Adding a delay of 1 second before performTransactionWithQuote

      // Wait for the transaction
      if (sendTransaction && !isPending) {
        await performTransactionWithQuote(quote, index);
      }
    } catch (error) {
      console.error("Error in handleSelfCustodyTransfer:", error);
      setBasketPurchaseLoading(false);
      return;
    }

    setBasketPurchaseLoading(false);
  };
  function ApproveOrReviewButton({
    takerAddress,
    onClick,
    sellTokenAddress,
    inSufficientBalance,
  }) {
    // 1. Read from erc20, does spender (0x Exchange Proxy) have allowance?
    const { data: allowance, refetch } = useReadContract({
      address: sellTokenAddress,
      abi: erc20Abi,
      functionName: "allowance",
      args: [takerAddress, exchangeProxy(chainId)],
    });

    // 2. (only if no allowance): write to erc20, approve a token allowance for 0x Exchange Proxy
    const { data } = useSimulateContract({
      address: sellTokenAddress,
      abi: erc20Abi,
      functionName: "approve",
      args: [exchangeProxy(chainId), MAX_ALLOWANCE],
    });

    // Define useWriteContract for the 'approve' operation
    const {
      data: writeContractResult,
      writeContractAsync: writeContract,
      error,
    } = useWriteContract();

    // useWaitForTransactionReceipt to wait for the approval transaction to complete
    const { data: approvalReceiptData, isLoading: isApproving } =
      useWaitForTransactionReceipt({
        hash: writeContractResult,
      });

    // Call `refetch` when the transaction succeeds
    useEffect(() => {
      if (data) {
        refetch();
      }
    }, [data, refetch]);

    if (error) {
      return <div>Something went wrong: {error.message}</div>;
    }

    if (allowance === 0n) {
      return (
        <>
          <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              await writeContract({
                abi: erc20Abi,
                address: sellTokenAddress,
                functionName: "approve",
                args: [exchangeProxy(chainId), MAX_ALLOWANCE],
              });
              refetch();
            }}
          >
            {isApproving ? "Approving…" : "Approve"}
          </button>
        </>
      );
    }

    return (
      <button
        type="button"
        disabled={inSufficientBalance || basketPurchaseLoading || quoteLoading}
        onClick={() => {
          if (inSufficientBalance) {
            toast.error("Insufficient balance");
          } else if (sendAmount < minInvest) {
            toast.error(`Minimum amount is ${minInvest}`);
          } else {
            handleSelfCustodyTransfer();
          }
        }}
        className="btn btn-primary d-flex align-items-centner justify-content-center gap-2 w-100"
      >
        {isPending ? "Pending..." : "Invest"}
        {basketPurchaseLoading && (
          <span>
            <CircularProgress size={20} />
          </span>
        )}
      </button>
    );
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        // className={`${styles.popup} `}
      >
        <div
          class=" d-flex justify-content-between align-items-center  py-2 position-relative px-4 rounded-top-lg"
          style={{
            backgroundColor: "#9376B1",
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          <div class="w-15%">
            <img
              src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
              alt=""
              style={{ width: "35px", height: "35px" }}
            />
          </div>

          <h5 class="mb-0">Talewallet</h5>
          <div class="">
            <div
              className="btn btn-link"
              onClick={() => {
                closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div
          class="overflow-auto bg-EEEEEE rounded-xl"
          style={{ maxHeight: "70vh" }}
          ref={componentRef}
          onClick={handleClick}
        >
          <div>
            <div class="px-4 py-2">
              <p class="text-md my-2">
                Minimum amount is {minInvest} {symbol}
              </p>
              {
                <p className="mb-1 mt-1 fs-5">
                  My balance:{" "}
                  <span className="font-w600">
                    {sendTokenData?.balance?.toFixed(2) || 0} {symbol}
                  </span>
                </p>
              }
              {inSufficientBalance && (
                <p className="text-danger">
                  You don't have enough {symbol},{" "}
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenTalewalletOnrampModal(true);
                      closeModal();
                    }}
                  >
                    Purchase {symbol} using FIAT
                  </span>
                </p>
              )}
              <p class="text-md my-2">Basket Amount</p>

              <div
                className="mb-3 d-flex justify-content-between align-items-center py-1 border"
                style={{ border: "#ced4da", borderRadius: "5px" }}
              >
                <div className="">
                  <input
                    id="sendAmount"
                    placeholder="Enter amount"
                    name="sendAmount"
                    type="number"
                    min="0"
                    value={sendAmount}
                    disabled={true}
                    className="ms-3"
                    style={{
                      backgroundColor: `${
                        appCtx?.isDarkMode ? "#09153F" : "white"
                      }`,
                      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                      width: "100px",
                      border: "none",
                    }}
                  />
                </div>
                <div class="w-2 h-12 d-flex justify-content-center align-items-center">
                  <div
                    class="border border-end"
                    style={{
                      width: "2px",
                      height: "30px",
                      border: "#ced4da",
                    }}
                  ></div>
                </div>

                <div
                  id="sendToken"
                  name="sendToken"
                  class="d-flex align-items-center gap-2 ms-2 "
                  style={{ width: "150px" }}
                >
                  <img
                    src="https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/USDT.png"
                    alt=""
                    class=""
                    style={{ width: "25px", height: "25px" }}
                  />
                  <div class="">
                    <p class="mb-0">USDT</p>
                  </div>
                </div>
              </div>

              <p class="text-md mt-2 mb-2">Amount you will receive</p>

              <>
                {isCoinPriceLOading ||
                Object?.keys(allCoinPrice)?.length === 0 ? (
                  <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ minHeight: "200px" }}
                  >
                    <CircularProgress size={20} className="" />
                  </div>
                ) : (
                  <>
                    {singleBasket?.cryptoBasketTokens?.map((token, index) => (
                      <div
                        class="d-flex flex-row justify-content-between align-items-center w-100 rounded-md overflow-hidden border hover:border-violet-500 border-#EEEEEE mb-2 py-1 px-3"
                        key={index}
                      >
                        <div
                          class=" pl-2 text-sm font-medium  justify-center"
                          style={{ width: "150px" }}
                        >
                          <div class="d-flex">
                            <p className="mb-0">
                              {getBasketsSingleTokensPrice(token)}
                            </p>
                            <p className="mb-0">({token?.tokenPercent}%)</p>
                          </div>
                          <div className="m-0 p-0">
                            <span class="text-xs font-normal">
                              1 {token?.symbol} ={" "}
                              {allCoinPrice[token?.symbol]?.price?.toFixed(2)}{" "}
                              {allCoinPrice[token?.symbol]?.currency}
                            </span>
                          </div>
                        </div>

                        <div class="">
                          <div class=" leading-tight focus:outline-none shadow-xs w-100 d-flex flex-row align-items-center pl-5 align-items-center gap-2">
                            <img
                              src={token?.icon}
                              alt=""
                              className="rounded-circle"
                              style={{
                                width: "25px",
                                height: "25px",
                              }}
                            />
                            <div class="">
                              <p className="mb-0">{token?.token}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>

              <div className="">
                <Accordion className="border">
                  <AccordionSummary
                    as={Card.Header}
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: `${
                        appCtx?.isDarkMode ? "#09153F" : "white"
                      }`,
                      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                    }}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <p className="text-[#3B0076] font-medium text-md mb-0">
                      You Pay ₹{sendAmount}
                    </p>
                    <p className="ms-4 mb-0">View Breakup</p>
                    <div className="d-flex align-items-center ms-2">
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        rotation={expanded ? 180 : 0}
                        className="ms-1"
                        style={themedColor}
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    eventKey="0"
                    style={{
                      backgroundColor: `${
                        appCtx?.isDarkMode ? "#09153F" : "white"
                      }`,
                      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                    }}
                  >
                    <>
                      <div className=" text-sm font-medium">
                        <div className="d-flex justify-content-between align-items-center ">
                          <p className="mb-1">Platform Fee (0.4%)</p>
                          <p className="mb-1">
                            {symbol} {platformFee || 0}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-1">Network Fee</p>
                          <p className="mb-1">
                            {" "}
                            {symbol} {networkFee?.toFixed(2) || 0}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center text-sm font-medium">
                        <p className="mb-1">Total Fee</p>
                        <p className="mb-1">
                          {symbol} {totalFee || 0}
                        </p>
                      </div>
                      <p className="text-sm font-medium mt-1">
                        Network fee includes{" "}
                        {tokenArray?.map((token, index) => {
                          const isLastToken = index === tokenArray.length - 1;
                          return (
                            <span
                              key={index}
                              className="text-sm font-medium ml-2"
                            >
                              {symbol} to {token}
                              {!isLastToken &&
                                index !== tokenArray.length - 2 &&
                                ", "}
                              {!isLastToken &&
                                index === tokenArray.length - 2 &&
                                " and "}
                            </span>
                          );
                        })}
                      </p>
                    </>
                  </AccordionDetails>
                </Accordion>
              </div>

              {appCtx?.custodial ? (
                <div class="d-flex mt-2">
                  <button
                    onClick={() => {
                      if (inSufficientBalance) {
                        toast.error("Insufficient balance");
                      } else if (sendAmount < minInvest) {
                        toast.error(`Minimum amount is ${minInvest}`);
                      } else {
                        handleCustodialTransfer();
                      }
                    }}
                    // disabled={!sendAmount || metamaskTransferLoading}
                    class="w-100 mb-3 py-2 text-md font-weight-bold text-white bg-primary border border-primary rounded active:text-secondary hover:bg-transparent hover:text-primary focus:outline-none focus:ring"
                  >
                    Invest{" "}
                    {custodailBasketPurchaseLoading && (
                      <CircularProgress
                        style={{
                          fontSize: "10px",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    )}
                  </button>
                </div>
              ) : (
                <>
                  {" "}
                  <div
                    className="mt-3"
                    style={{
                      minHeight: "5px",
                    }}
                  >
                    {quoteLoading && (
                      <div className="">Fetching best quote for you</div>
                    )}

                    {/* {quote?.price && !quoteLoading && (
                      <div className="w-100 d-flex justify-content-between">
                        <p className="mb-1">Quote rate: </p>
                        <p className="font-w600 mb-1">
                          1 {sendToken} = {parseFloat(quote?.price)?.toFixed(6)}{" "}
                          {receiveToken}{" "}
                        </p>
                      </div>
                    )} */}

                    {error && (
                      <div className="text-danger mt-2">
                        Error: {error.shortMessage || error.message}
                      </div>
                    )}
                  </div>
                  {/* swap button  */}
                  <div className="d-flex justify-content-center mt-4 w-100">
                    {address && (
                      <ApproveOrReviewButton
                        sellTokenAddress={sendTokenMoreData?.contractAddress}
                        takerAddress={address}
                        onClick={() => {
                          setFinalize(true);
                        }}
                        inSufficientBalance={inSufficientBalance}
                      />
                    )}
                  </div>
                  {isConfirming && (
                    <div className="text-center">
                      Waiting for confirmation ⏳ ...
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div
            className="d-flex flex-column align-items-center mx-auto px-4 pb-2"
            style={{ width: "400px" }}
          >
            <p
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenTalewalletOnrampModal(true);
                closeModal();
              }}
            >
              Purchase {symbol} using FIAT
            </p>
          </div>
        </div>

        {openMultiSwapSuccessModal && (
          <MultiSwapSuccessModal
            openModal={openMultiSwapSuccessModal}
            setOpenModal={setOpenMultiSwapSuccessModal}
            symbol={symbol}
            sendTokenObject={sendTokenObject}
            sendAmount={sendAmount}
            basketId={basketId}
            isPending={isPending}
            error={error}
            isConfirmed={isConfirmed}
            custodailBasketPurchaseLoading={custodailBasketPurchaseLoading}
            custodailBasketPurchaseSuccess={custodailBasketPurchaseSuccess}
            setOpenPreviousModal={setOpenModal}
            setopenPaymentMethodModal={setopenPaymentMethodModal}
          />
        )}
      </Modal>
    </>
  );
};

export default BasketPaymentusingTokenModal;
