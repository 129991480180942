import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { toast } from "react-toastify";
import axios from "axios";
import { appActions } from "../../context/app-slice";

const UserNameSetupModal = ({ openModal, setOpenModal }) => {
  const appCtx = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const [isValidUserName, setIsValidUserName] = useState(false);
  const [userName, setUserName] = useState(null);

  const makeRequest = useAuthorizedHttp();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "20px",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const handleUserNameSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/name/setup`,
        data: { userName: userName },
        method: "post",
      },
      (data) => {
        dispatch(
          appActions.updateUserDetails({
            ...appCtx?.userDetails,
            userName: data?.userName,
          })
        );
        toast.success("User Name updated successfully");
        setLoading(false);
        closeModal();
      },
      (error) => {
        toast.error("Username is not available");
        setLoading(false);
      }
    );
  };

  const handleValidateUserName = async () => {
    setValidating(true);
    setIsValidUserName(false);

    let data = JSON.stringify({
      userName: userName,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/name/validate`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setValidating(false);
          setIsValidUserName(true);
        }
      })
      .catch((error) => {
        // toast.error("Failed to update user name, please try again");
        // console.error("Unhandled error:", error);
        setValidating(false);
      });
  };

  useEffect(() => {
    if (userName?.length > 3) {
      handleValidateUserName();
    }
  }, [userName]);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="">
        <form className="">
          <h3
            className="text-center mt-2 "
            style={{
              color: "#570987",
            }}
          >
            Update Username
          </h3>
          <p className="text-center"></p>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Enter your username"
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
            />
          </div>
        </form>
        {!userName || userName?.length <= 3 ? (
          <p>Minimum 4 character required</p>
        ) : (
          <>
            {validating ? (
              <CircularProgress
                style={{
                  color: "#BBFF00",
                  fontSize: "10px",
                  width: "25px",
                  height: "25px",
                }}
              />
            ) : (
              <>
                {isValidUserName ? (
                  <p className="text-success">Username Available</p>
                ) : (
                  <p className="text-danger">Username Not Available</p>
                )}
              </>
            )}
          </>
        )}
        <div className="d-flex justify-content-center gap-4 mt-4 ">
          <button
            className="btn  font-w600"
            style={{
              backgroundColor: "#570987",
              color: "white",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
            type="submit"
            onClick={handleUserNameSubmit}
            disabled={validating || !isValidUserName || userName?.length <= 3}
          >
            SUBMIT{" "}
            <span>
              {loading && <CircularProgress size={20} className="ml-[5px]" />}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UserNameSetupModal;
