import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import { toast } from "react-toastify";
import "./Networkmodal.css";
import axios from "axios";
import { appActions } from "../../context/app-slice";
import { getImageUrl } from "../../config";

function Networkmodal({ openModal, setOpenModal, filteredOptions }) {
  const [copyId, setCopyId] = useState(false);
  const [openAlgoAccountModal, setOpenAlgoAccountModal] = useState(false);
  const [selectedChain, setSelectedChain] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }
  const copyToClipboard = () => {
    setCopyId(true);
    navigator.clipboard.writeText(appCtx.walletAddress[0]?.address);
  };

  const dark = false;

  const callBlockchainSetupApi = (blockchain) => {
    let data = JSON.stringify({
      blockchain: blockchain,
      wallet: "TALEWALLET",
      marketplaceAddress: "0",
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/wallet/setup`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(appActions.setBlockChain(response.data.blockchain));
        // dispatch(appActions.setWalletAddress(walletData));

        toast.success("new network added successfully  ");
        closeModal();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddAlgo = () => {
    if (appCtx?.userDetails?.email || appCtx.custodial) {
      callBlockchainSetupApi("ALGORAND");
    } else {
      setSelectedChain("ALGORAND");
      setOpenAlgoAccountModal(true);
    }
  };
  const handleAddSolana = () => {
    if (appCtx?.userDetails?.email || appCtx.custodial) {
      callBlockchainSetupApi("SOLANA");
    } else {
      setSelectedChain("SOLANA");
      setOpenAlgoAccountModal(true);
    }
  };

  console.log("filteredOptions ", filteredOptions);

  const coinButtons = Object.entries(filteredOptions)?.map(([key, value]) => (
    <button
      type="button"
      className="btn light btn-secondary my-1"
      onClick={() => {
        switch (value) {
          case "POLYGON":
            callBlockchainSetupApi("POLYGON");
            break;
          case "BSC":
            callBlockchainSetupApi("BSC");
            break;
          case "ETHEREUM":
            callBlockchainSetupApi("ETHEREUM");
            break;
          case "SOLANA":
            handleAddSolana();
            break;
          case "BASE":
            callBlockchainSetupApi("BASE");
            break;
          case "AVALANCHE":
            callBlockchainSetupApi("AVALANCHE");
            break;
          case "NORDEK":
            callBlockchainSetupApi("NORDEK");
            break;
          case "ALGORAND":
            handleAddAlgo();
            break;
          default:
            // Default case code here
            break;
        }
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center gap-2">
          <img
            src={getImageUrl(value)}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
          <div className="">
            <span className="font-weight-semibold">{value}</span>
          </div>
        </div>
        <div className="cursor-pointer">
          <h6 className="text-primary mr-3 font-weight-semibold">Add</h6>
        </div>
      </div>
    </button>
  ));

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="">
        <div className="d-flex justify-content-end m-2 position-relative">
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-1 right-2"
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="flex flex-col justify-center items-center gap-5 p-4">
          <h3 className="text-center">Add a network</h3>
          <h5>
            Add from a list of popular networks or add a network manually.{" "}
            <br />
            Only interact with the entities you trust.
          </h5>

          {filteredOptions?.length === 0 ? (
            <div className="">
              <p className="text-center my-5">No BlockChain Available</p>
            </div>
          ) : (
            <div className="d-flex flex-column">{coinButtons}</div>
          )}
        </div>
      </div>
    </Modal>
  );
}
export default Networkmodal;
