import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleChevronDown,
  faCopy,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./BuySellModal.module.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import {
  getBlockchainAddressUrl,
  getPriceInDecimal,
  nativeToken,
} from "../../config";
import { useWalletView } from "../../hooks/useWalletView";
import { Tab, Tabs } from "react-bootstrap";
import useBinanceFeed from "./useBinanceFeed";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwapSuccessModal from "./ExpertsBuySellSuccessModal";
import { useLocation } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
function BuySellModal({
  openModal,
  setOpenModal,
  selectedButton,
  allCoin,
  allCoinPrice,
  usersToken,
  isCoinLoading,
  setOpenTransferSuccessModal,
  setTransactionData,
  product_id,
  selectedFundWalletAddress,
  selectedFundName,
  selectedFundId,
  setSelectedFundId,
}) {
  const [loading, setLoading] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [usersUsdtBalanceLoading, setUsersUsdtBalanceLoading] = useState(false);
  const [usersUsdtBalance, setUsersUsdtBalance] = useState(0);
  const [
    usersSelectedTokenBalanceLoading,
    setUsersSelectedTokenBalanceLoading,
  ] = useState(false);
  const [usersSelectedTokenBalance, setUsersSelectedTokenBalance] = useState(0);

  const [activeTab, setActiveTab] = useState(selectedButton);
  const appCtx = useSelector((state) => state.app);

  const navigate = useNavigate();
  const location = useLocation();

  const { bids, asks, middlePrice } = useBinanceFeed(product_id);

  const { tokenid, tokenname } = useParams();
  const priceInputRef = useRef(null);
  const makeRequest = useAuthorizedHttp();
  const isSpotDetailsPage = window.location.href.includes("spot");

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      // width: width > 1200 ? "600px" : "370px",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const [selectedOption, setSelectedOption] = useState("market");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [formData, setFormData] = useState({
    price: "",
    total: 0,
    quantity: 0,
    tp: null,
    sl: null,
    stopPrice: "",
  });

  useEffect(() => {
    if (!formData?.price) {
      setFormData({
        ...formData,
        price: middlePrice?.price,
      });
    }
  }, [middlePrice?.price]);

  console.log("middle price", middlePrice);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (activeTab === "Buy" && formData?.total > usersUsdtBalance) {
      toast.error("You don't have enough USDT");
      return;
    }
    if (activeTab === "Sell" && formData?.total > usersUsdtBalance) {
      toast.error(`You don't have enough ${tokenname}`);
      return;
    }

    const tokenPrice =
      selectedOption === "market" ? middlePrice?.price : formData?.price;
    if (formData?.tp) {
      if (activeTab === "Buy") {
        // for buy
        if (formData?.tp < tokenPrice) {
          toast.error("TP should be greater than tokenPrice");
          return;
        }
      } else {
        // for sell
        if (formData?.tp > tokenPrice) {
          toast.error("TP should be less than tokenPrice");
          return;
        }
      }
    }
    if (formData?.sl) {
      if (activeTab === "Buy") {
        // for buy

        if (formData?.sl > tokenPrice) {
          toast.error("SL should be less than tokenPrice");
          return;
        }
      } else {
        // for sell

        if (formData?.sl < tokenPrice) {
          toast.error("SL should be greater than tokenPrice");
          return;
        }
      }
    }

    setLoading(true);
    let data;

    if (activeTab === "Buy" && selectedOption === "market") {
      data = JSON.stringify({
        fromTokenId: parseInt(usdtId),
        toTokenId: parseInt(tokenid),
        amount: parseFloat(formData?.total),
        buyTokenAmount: parseFloat(formData?.quantity),
        blockchain: appCtx.blockchain,
        fundId: parseInt(selectedFundId),
        tokenPrice: parseFloat(usersSelectedTokenPrice),
        action: "BUY",
      });
    }
    if (activeTab === "Buy" && selectedOption === "limit") {
      data = JSON.stringify({
        sellTokenId: parseInt(usdtId),
        sellToken: "USDT",
        buyTokenId: parseInt(tokenid),
        buyToken: tokenname,

        sellAmount: parseFloat(formData?.total),
        buyAmount: parseFloat(formData?.quantity),
        blockchain: appCtx.blockchain,
        fundId: selectedFundId,
        markedPrice: parseFloat(formData?.price),
        action: "BUY",
        fundAddress: selectedFundWalletAddress,
        ...(formData?.tp !== null &&
          formData?.tp !== undefined && {
            takeProfit: parseFloat(formData.tp),
          }),
        ...(formData?.sl !== null &&
          formData?.sl !== undefined && { stopLoss: parseFloat(formData.sl) }),
      });
    }
    if (activeTab === "Sell" && selectedOption === "market") {
      data = JSON.stringify({
        fromTokenId: parseInt(tokenid),
        toTokenId: parseInt(usdtId),
        amount: parseFloat(formData?.quantity),
        buyTokenAmount: parseFloat(formData?.total),
        blockchain: appCtx.blockchain,
        fundId: selectedFundId,
        tokenPrice: usersSelectedTokenPrice,
        action: "SELL",
      });
    }
    if (activeTab === "Sell" && selectedOption === "limit") {
      data = JSON.stringify({
        sellTokenId: parseInt(tokenid),
        sellToken: tokenname,
        buyTokenId: parseInt(usdtId),
        buyToken: "USDT",
        buyAmount: parseFloat(formData?.total),
        markedPrice: parseFloat(formData?.price),
        blockchain: appCtx.blockchain,
        fundId: selectedFundId,
        tokenPrice: parseFloat(usersSelectedTokenPrice),
        action: "SELL",
        fundAddress: selectedFundWalletAddress,
        ...(formData?.tp !== null &&
          formData?.tp !== undefined && {
            takeProfit: parseFloat(formData.tp),
          }),
        ...(formData?.sl !== null &&
          formData?.sl !== undefined && { stopLoss: parseFloat(formData.sl) }),
      });
    }

    let url;
    if (selectedOption === "market") {
      url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/trade/execute`;
    } else {
      url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/trade/order`;
    }

    let config = {
      method: "post",
      url: url,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);

          setOpenTransferSuccessModal(true);
          setTransactionData(response.data);
          closeModal();
          setFormData({
            price: "",
            quantity: 0,
            total: 0,
            tp: null,
            sl: null,
            stopPrice: "",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (activeTab === "Buy") {
          toast.error("Token purchase failed!");
        } else {
          toast.error("Token sell failed!");
        }
      });
  };

  let usersSelectedTokenPrice = allCoinPrice[tokenname]?.price;

  let usdtId = allCoin?.find((token) => token?.symbol === "USDT")?.tokenId;
  let selectedTokenId = allCoin?.find(
    (token) => token?.symbol === tokenname
  )?.tokenId;
  useEffect(() => {
    setUsersUsdtBalanceLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${selectedFundId}/wallet/${selectedFundWalletAddress}/token/${usdtId}/balance?blockchain=${appCtx?.blockchain}`,

        method: "get",
        headers: {
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        setUsersUsdtBalance(data?.balance); //need to check
        setUsersUsdtBalanceLoading(false);
      },
      () => {
        setUsersUsdtBalanceLoading(false);
      },
      (error) => {
        setUsersUsdtBalanceLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    setUsersSelectedTokenBalanceLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${selectedFundId}/wallet/${selectedFundWalletAddress}/token/${selectedTokenId}/balance?blockchain=${appCtx?.blockchain}`,

        method: "get",
        headers: {
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        setUsersSelectedTokenBalance(data?.balance); //need to check
        setUsersSelectedTokenBalanceLoading(false);
      },
      () => {
        setUsersSelectedTokenBalanceLoading(false);
      },
      (error) => {
        setUsersSelectedTokenBalanceLoading(false);
      }
    );
  }, []);

  const handleChangePrice = (e) => {
    const newPrice =
      selectedOption === "market" ? middlePrice?.price : e.target.value;
    const { quantity, total } = formData;
    const parsedQuantity = parseFloat(quantity);
    const parsedSize = parseFloat(total);

    const newQuantity =
      isNaN(newPrice) || isNaN(parsedSize) ? 0 : parsedSize / newPrice;
    const newSize =
      isNaN(newPrice) || isNaN(parsedQuantity) ? 0 : newPrice * parsedQuantity;

    setFormData({
      ...formData,
      price: newPrice,
      quantity: newQuantity,
      total: newSize,
    });
  };
  // Triggering the onchange event for the price input field when it's changed programmatically.
  if (selectedOption === "market" && priceInputRef.current) {
    console.log("i am inside priceinput ref.current");
    priceInputRef.current.dispatchEvent(new Event("change"));
  }

  const handleChangeQuantity = (e) => {
    const newQuantity = e.target.value;
    const { price, total } = formData;
    const parsedPrice = parseFloat(price);

    const newSize =
      isNaN(parsedPrice) || isNaN(newQuantity) ? 0 : parsedPrice * newQuantity;

    setFormData({
      ...formData,
      quantity: newQuantity,
      total: newSize,
    });
  };

  const handleChangeSize = (e) => {
    const newSize = e.target.value;
    const { price, quantity } = formData;
    const parsedPrice = parseFloat(price);
    const parsedQuantity = parseFloat(quantity);

    const newQuantity =
      isNaN(parsedPrice) || isNaN(parsedQuantity) ? 0 : newSize / parsedPrice;

    setFormData({
      ...formData,
      quantity: newQuantity,
      total: newSize,
    });
  };

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };

  const truncatedAddress = `${selectedFundWalletAddress?.substring(
    0,
    6
  )}.....${selectedFundWalletAddress?.substring(
    selectedFundWalletAddress?.length - 6
  )}`;

  function getOBPriceInDecimal(price) {
    let tokenprice;
    if (price >= 1000) {
      tokenprice = price?.toFixed(0);
    } else if (price >= 1) {
      tokenprice = price?.toFixed(2);
    } else if (price < 0.001) {
      tokenprice = price?.toFixed(6);
    } else {
      tokenprice = price?.toFixed(4);
    }

    return tokenprice;
  }

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  `}
      >
        <div
          class=" d-flex justify-content-between align-items-center  py-1 position-relative px-2 rounded-top-lg bg-primary"
          style={{
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          <h5 class="mb-0 text-white">{selectedFundName}</h5>
          <div class="d-flex gap-1 align-items-center">
            <div
              onClick={() => {
                const blockchain = appCtx?.blockchain;
                const blockchainLink = getBlockchainAddressUrl(
                  blockchain,
                  selectedFundWalletAddress
                );

                if (blockchainLink !== "") {
                  window.open(blockchainLink);
                }
              }}
              className={`text-white`}
              style={{ cursor: "pointer", marginLeft: "5px" }}
            >
              {truncatedAddress}
            </div>
            <button
              className="btn btn-link"
              onClick={() => handleCopyAddress(selectedFundWalletAddress)}
            >
              {isAddressCopied ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className=""
                  style={{
                    fontSize: "20px",
                    color: "white",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCopy}
                  className=""
                  style={{
                    fontSize: "20px",
                    color: "white",
                  }}
                />
              )}
            </button>
          </div>
        </div>
        <div
          className={`p-4 ${appCtx?.isDarkMode ? "text-white" : ""}`}
          style={{
            backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
          }}
        >
          <div class="custom-tab-1 d-flex justify-content-between align-items-center">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  class={`nav-link  ${activeTab === "Buy" && "active"}`}
                  onClick={() => handleTabSelect("Buy")}
                >
                  Buy
                </a>
              </li>
              <li class="nav-item">
                <a
                  class={`nav-link  ${activeTab === "Sell" && "active"}`}
                  data-bs-toggle="tab"
                  onClick={() => handleTabSelect("Sell")}
                >
                  Sell
                </a>
              </li>
            </ul>
          </div>

          <form className="">
            <div class="">
              <div class=" pt-3">
                <div className="d-flex gap-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="option"
                      id="market"
                      value="market"
                      checked={selectedOption === "market"}
                      onChange={handleOptionChange}
                    />
                    <label
                      className="form-check-label small text-muted"
                      htmlFor="market"
                    >
                      Market
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="option"
                      id="limit"
                      value="limit"
                      checked={selectedOption === "limit"}
                      onChange={handleOptionChange}
                    />
                    <label
                      className="form-check-label small text-muted"
                      htmlFor="limit"
                    >
                      Limit
                    </label>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3 mb-2">
                  <span class="small text-muted">Available Balance</span>
                  <span class="text-dark">
                    {activeTab === "Sell" ? (
                      <span class="text-dark">
                        {usersSelectedTokenBalance} {tokenname}{" "}
                      </span>
                    ) : (
                      <span class="text-dark">{usersUsdtBalance} USDT </span>
                    )}
                  </span>
                </div>
                <form>
                  <div class="input-group mb-3">
                    <span class="input-group-text">Price</span>
                    <input
                      type="number"
                      class="form-control"
                      value={
                        selectedOption === "market"
                          ? getOBPriceInDecimal(parseFloat(middlePrice?.price))
                          : formData.price
                      }
                      contentEditable={
                        selectedOption === "market" ? false : true
                      }
                      onChange={handleChangePrice}
                      ref={priceInputRef}
                    />
                    <span class="input-group-text">USDT</span>
                  </div>
                  <div class="input-group mb-3">
                    <span class="input-group-text">Quantity</span>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.quantity}
                      onChange={handleChangeQuantity}
                    />
                    <button
                      class="btn btn-primary btn-outline-primary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {tokenname}
                    </button>
                  </div>
                  <div class="input-group mb-3">
                    <span class="input-group-text">Total</span>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.total}
                      onChange={handleChangeSize}
                    />
                    <button
                      class="btn btn-primary btn-outline-primary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      USDT
                    </button>
                  </div>
                  {!isSpotDetailsPage && (
                    <Accordion
                      style={{
                        border: "none",
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"
                        }`,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <label class="form-label">TP/SL</label>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div class="mb-3">
                          <div class="input-group mb-3">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Take Profit"
                              value={formData.tp}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  tp: e.target.value,
                                })
                              }
                            />
                            <button
                              class="btn btn-primary btn-primary btn-outline-primary dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Mark
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end">
                              <li>
                                <a class="dropdown-item" href="#">
                                  Last
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Mark
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="input-group mb-3">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Stop Loss"
                              value={formData.sl}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  sl: e.target.value,
                                })
                              }
                            />
                            <button
                              class="btn btn-primary btn-outline-primary dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Mark
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end">
                              <li>
                                <a class="dropdown-item" href="#">
                                  Last
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Mark
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <div class="input-group mb-3">
                        <span class="input-group-text">Stop Price</span>
                        <input
                          type="number"
                          class="form-control"
                          value={formData.stopPrice}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              stopPrice: e.target.value,
                            })
                          }
                        />
                        <button
                          class="btn btn-primary btn-outline-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Mark
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <a class="dropdown-item" href="#">
                              Limit
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Mark
                            </a>
                          </li>
                        </ul>
                      </div> */}
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <div class="mt-3 d-flex justify-content-between">
                    {activeTab === "Buy" ? (
                      <button
                        onClick={handleSubmit}
                        disabled={
                          formData?.quantity === 0 || formData?.total === 0
                        }
                        class="btn btn-success btn-sm light text-uppercase btn-block"
                      >
                        BUY
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        disabled={
                          formData?.quantity === 0 || formData?.total === 0
                        }
                        class="btn btn-danger btn-sm light text-uppercase btn-block"
                      >
                        Sell
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default BuySellModal;
