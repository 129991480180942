import React, { useEffect, useState } from "react";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Skeleton } from "@mui/material";
import PortfolioTokenCard from "./PortfolioTokenCard";
import { appActions } from "../../context/app-slice";

const PortfolioComponent = ({
  allCoin,
  allCoinPrice,
  usersToken,
  isCoinLoading,
  selectedFundId,
  setSelectedFundId,
}) => {
  const [fundsPortfolio, setFundsPortfolio] = useState([]);
  const [fundsPortfolioLoading, setFundsPortfolioLoading] = useState(false);
  const makeRequest = useAuthorizedHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    if (selectedFundId) {
      setFundsPortfolioLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${selectedFundId}/composition`,

          method: "get",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          setFundsPortfolio(data);

          setFundsPortfolioLoading(false);
        },
        () => {
          setFundsPortfolioLoading(false);
        },
        (error) => {
          setFundsPortfolioLoading(false);
        }
      );
    }
  }, [selectedFundId]);

  

  return (
    <div class={`card-body pt-0 `}>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-order"
          role="tabpanel"
          aria-labelledby="nav-order-tab"
        >
          <div class="table-responsive dataTabletrade">
            <table
              id="example-2"
              class="table display orderbookTable"
              style={{ minWidth: "845px" }}
            >
              <thead>
                <tr>
                  <th>Pair</th>
                  <th>Total Qty</th>
                  <th>In Order Qty</th>
                  <th>Price</th>
                  <th>Total Asset Value</th>
                </tr>
              </thead>
              <tbody>
                {isCoinLoading || fundsPortfolioLoading ? (
                  <tr>
                    <td colSpan="5" className="w-100">
                      <div className="d-flex justify-content-center align-items-center py-5">
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="ml-2"
                          fontSize="small"
                        />
                      </div>
                    </td>
                  </tr>
                ) : !fundsPortfolioLoading && fundsPortfolio?.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="w-100">
                      <div className="d-flex justify-content-center align-items-center text-center">
                        <p className="mb-0">
                          Portfolio is not available for this fund
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  fundsPortfolio?.map((item, index) => (
                    <PortfolioTokenCard
                      key={index}
                      item={item}
                      allCoin={allCoin}
                      allCoinPrice={allCoinPrice}
                      usersToken={usersToken}
                      fundsPortfolio={fundsPortfolio}
                    />
                  ))
                )}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioComponent;
