import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useEffect, useState } from "react";

const CommunityRewards = () => {

    const [points, setPoints] = useState(0);
    const [pointsLoading, setPointsLoading] = useState(false);
  const makeRequest = useAuthorizedHttp();
  const navigate = useNavigate();
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const backgroundStyle = {
    backgroundColor: "#E2E5FC",
    padding: "5px 10px",
    borderRadius: "50px",
  };

  const buttonStyle = {
    borderRadius: "50px",
    backgroundColor: "white",
    color: "#6666FF",
    padding: "5px 10px",
    marginLeft: "10px",
  };

  useEffect(() => {
    setPointsLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/tale/points`,
      },
      (data) => {
        setPoints(data?.points);
        setPointsLoading(false);
      },
      (error) => {
        setPointsLoading(false);
      },
      () => {
        setPointsLoading(false);
      }
    );
  }, []);

  return (
    <>
      <MainWrapper>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            <div className="col-12">
              <div className="card">
                <div className="card-header border-0 pb-3">
                  <h4 className="card-title">Tale Community Rewards</h4>
                </div>
                <div className="card-body px-0 pt-0">
                  <div className="w-100">
                    <h3 className="text-center">WELCOME</h3>
                    <h3 className="font-w400 text-center">To Tale Community</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header border-0"></div>
                <div className="card-body pt-0">
                  <div className="w-100">
                    <h5>
                      Tale Community welcomes all members, We plan on rewarding
                      the early contributors who help us in building the
                      ecosystem that we are striving for.
                    </h5>
                    <h6 className="font-w400 mt-1">
                      Complete the actions below and get Tale Community Reward
                      Points. These Reward Points will be redeemable for
                      something exiciting in future.
                    </h6>
                    <h6 className="font-w400">
                      So make sure you completes as many of the following task
                      to reap the most of this opportunity{" "}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="card w-100">
                <div class="card-header pb-0 mb-0 border-0 flex-wrap">
                  <div class="mb-0">
                    <h4 class="card-title mb-2">Rewards</h4>

                    {!pointsLoading && (
                      <div style={containerStyle}>
                        <div
                          style={backgroundStyle}
                          className="d-flex align-items-center px-3 mb-3"
                        >
                          <p
                            className="mb-0"
                            style={{
                              color: "#6666FF",
                            }}
                          >
                            My Tc Points
                          </p>
                          <div style={buttonStyle}>{points || 0}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div class="card-body pt-0  overflow-auto">
                  <div class="d-flex align-items-center  market-preview">
                    <div class="w-100">
                      <div class="row">
                        <div className="d-flex align-items-center font-w600 ">
                          <div class="d-flex align-items-center col-4 col-md-2">
                            S no
                          </div>

                          <div className="d-flex flex-column align-items-start justify-content-start col-4 col-md-3">
                            <div className="">Activity</div>
                          </div>
                          <div className="d-flex flex-column align-items-start justify-content-start col-4 col-md-5">
                            <div className="">Description</div>
                          </div>

                          <div className="d-flex align-items-center justify-content-start  col-4 col-md-2">
                            Status
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex align-items-center  market-preview"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/refer-and-earn")}
                  >
                    <div class="w-100">
                      <div class="row">
                        <div className="d-flex align-items-center ">
                          <div class="d-flex align-items-center col-4 col-md-2 font-w-600">
                            1.
                          </div>

                          <div className="col-4 col-md-3  font-w-600">
                            Refer & Earn
                          </div>
                          <div className="col-4 col-md-5">
                            Refer and Earn with referred completing their first
                            deposit
                          </div>
                          <div className="col-4 col-md-2">--</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center  market-preview"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/crypto-basket")}
                  >
                    <div class="w-100">
                      <div class="row">
                        <div className="d-flex align-items-center ">
                          <div class="d-flex align-items-center col-4 col-md-2 font-w-600">
                            2.
                          </div>

                          <div className="col-4 col-md-3  font-w-600">
                            Basket Subscription
                          </div>
                          <div className="col-4 col-md-5">
                            Earn TC Points on your Crypto Basket investments
                          </div>
                          <div className="col-4 col-md-2">--</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center  market-preview"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/crypto-fund")}
                  >
                    <div class="w-100">
                      <div class="row">
                        <div className="d-flex align-items-center ">
                          <div class="d-flex align-items-center col-4 col-md-2 font-w-600">
                            3.
                          </div>

                          <div className="col-4 col-md-3  font-w-600">
                            Fund Token
                          </div>
                          <div className="col-4 col-md-5">
                            Earn TC Points on your Crypto Fund Token purchase
                          </div>
                          <div className="col-4 col-md-2">--</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center  market-preview"
                    style={{ cursor: "pointer" }}
                    // onClick={() => navigate("/refer")}
                  >
                    <div class="w-100">
                      <div class="row">
                        <div className="d-flex align-items-center ">
                          <div class="d-flex align-items-center col-4 col-md-2 font-w-600">
                            4.
                          </div>

                          <div className="col-4 col-md-3  font-w-600">AUM</div>
                          <div className="col-4 col-md-5">
                            Complete AUM Milestones to claim your TC Points
                          </div>
                          <div className="col-4 col-md-2">--</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center  market-preview"
                    style={{ cursor: "pointer" }}
                    // onClick={() => navigate("/refer")}
                  >
                    <div class="w-100">
                      <div class="row">
                        <div className="d-flex align-items-center ">
                          <div class="d-flex align-items-center col-4 col-md-2 font-w-600">
                            5.
                          </div>

                          <div className="col-4 col-md-3  font-w-600">
                            Socials
                          </div>
                          <div className="col-4 col-md-5">
                            Strengthen our Twitter Community and claim TC Points
                          </div>
                          <div className="col-4 col-md-2">--</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default CommunityRewards;
