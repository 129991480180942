import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { toast } from "react-toastify";
import { appActions } from "../../context/app-slice";
import { CircularProgress } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { EmailOutlined } from "@mui/icons-material";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import axios from "axios";
import SuccessModal from "../BuyAndSellAlgos/sendAlgos/SuccessModal";
import AlgorandClient from "../services/algorandsdk";
import { GetDecryptedData } from "../GetDecryptedData/GetDecryptedData";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var CryptoJS = require("crypto-js");

function SignupPageModal({
  openModal,
  setOpenModal,
  setOpenPreviousModal,
  login,
  rewards,
  otpLogin,
  transfer,
  recieverAddress,
  option,
  amount,
  recoverPopup,
  payAlgos,
  nftId,
  blockchainNftId,
  setOpenModalPaymentSuccess,
  setOpenModalPaymentFailure,
  setPreviousOpen,
  setConfirmOpen,
  offRampSell,
  OffRampTransactionData,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const dark = false;
  const [userData, setUserData] = React.useState({
    email: transfer || payAlgos ? appCtx.userDetails?.email : "",
    password: "",
  });
  const [otpLoginUserEmail, setOtpLoginUserEmail] = React.useState(
    transfer || payAlgos ? appCtx.userDetails?.email : ""
  );
  const [handleEnterOtp, setHandleEnterOtp] = React.useState(false);
  const [clickledOnLoginOtp, setClickledOnLoginOtp] = React.useState(false);
  const [otp1, setOtp1] = React.useState("");
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [reSendOtp, setReSendOtp] = React.useState(false);
  const [isVerifiedOtp, setIsVerifiedOtp] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [openRefferalTailCoinModal, setOpenRefferalTailCoinModal] =
    useState(false);
  const [continueWith, setContinueWith] = useState(false);
  let distance1 = 300,
    resendDistance = 300;
  if (reSendOtp === 0) {
    resendDistance = 300;
    distance1 = 0;
  }
  const [data, setData] = useState({});
  const [transactionHash, setTransactionHash] = useState(null);
  const [openTransferSuccessModal, setOpenTransferSuccessModal] =
    useState(false);

  let loginType = "";
  // var keyEncryt = CryptoJS.SHA256(JSON.stringify(appCtx?.encrypt)).toString();

  const themedColor = `${appCtx?.isDarkMode ? "white" : "black"}`;

  const { decryptedData } = GetDecryptedData();
  if (otpLogin) {
    loginType = "type=login";
  } else if (offRampSell) {
    loginType = "type=offramp_sell";
  } else if (transfer) {
    loginType = "type=token_transfer";
  }
  // else if (decryptedData !== "") {
  //   loginType = "type=email_update";
  // }
  else {
    loginType = "type=wallet_login";
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevValue) => ({ ...prevValue, [name]: value }));
    setOtpLoginUserEmail(e.target.value);
  };

  const handleSendOtp = () => {
    if (userData.email?.trim().length !== 0) {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)
      ) {
        setLoader(true);
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}`,
            data: { email: otpLoginUserEmail },
            method: "post",
          },
          (data) => {
            toast("OTP Sent SuccesFully!");
            setClickledOnLoginOtp(true);
            if (data.message === "Success" && data.status === true) {
              setHandleEnterOtp(true);
              setLoader(false);
              var x = setInterval(function () {
                setMinutes(Math.floor(distance1 / 60));
                setSeconds(distance1 % 60);
                distance1--;
                if (isVerifiedOtp === true) {
                  distance1 = 0;
                  resendDistance = 0;
                  clearInterval(x);
                }
                // if (distance1 < 0) {
                //     clearInterval(x);
                //     navigate("/signup");
                // }
                if (distance1 === -1) {
                  clearInterval(x);
                  // navigate("/");
                }
              }, 1000);
            }
          },
          (error) => {
            toast.error("Please try after sometime");
            setLoader(false);
          }
        );
      } else toast.error("Email ID is incorrect !");
    } else {
      toast.error("Email Id cannot be empty !");
    }
  };
  const handleCheckOtp = async () => {
    setLoader(true);
    if (appCtx.blockchain === "ALGORAND" && transfer) {
      let accountInfo = await AlgorandClient.accountInformation(
        recieverAddress
      ).do();
      let array = [];

      if (accountInfo["assets"] === undefined) {
      } else {
        var assetobj = accountInfo["assets"];
        let assetUrl = [];
        assetobj?.map((asset) => {
          array = [...array, { key: asset["asset-id"], amount: asset.amount }];
        });
      }
      const isassetIdPresent = array?.filter((assets) => {
        return assets.key == process.env.REACT_APP_TAIL_COIN_TOKEN;
      });
      if (
        (isassetIdPresent?.length > 0 && option === "tale") ||
        option === "algo"
      ) {
        makeRequest(
          {
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/transfer`,
            data: {
              email: otpLoginUserEmail,
              otp: otp1,
              blockchainFtId:
                option === "tale" ? process.env.REACT_APP_TAIL_COIN_TOKEN : 0,
              buyerUserAddress: recieverAddress,
              amount:
                option === "tale"
                  ? parseFloat(amount) * 100
                  : parseFloat(amount) * 1000000,
            },
            method: "post",
          },
          (data) => {
            setLoader(false);
            toast.success("Transfer Successfull");
            navigate("/");
          },
          (error) => {
            toast.error("OTP entered is incorrect !");
            setLoader(false);
          },
          () => {}
        );
      } else {
        setLoader(false);
        if (option === "tale") {
          toast.error(
            "You cannot send Tale Coin to an account which has not opted in Tale Coin asset"
          );
        } else {
          toast.error("Algo transfer unsuccessfull");
        }
      }
    }
    if (appCtx.blockchain === "ALGORAND" && payAlgos) {
      let config = {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/wallet/pay`,
        data: {
          details: {
            email: otpLoginUserEmail,
            otp: otp1,
            blockchainFtId: 0,
            buyerUserAddress: recieverAddress,
            amount: parseFloat(amount) * 1000000,
          },
          nftIds: [nftId],
        },
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
          // "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
      };
      axios(config)
        .then((res) => {
          setLoader(false);
          // navigate('/');
          // console.log(data.status);
          if (res?.data?.status) {
            setOpenModalPaymentSuccess(true);
            toast.success("NFT Transfer Successfull");
          } else {
            setOpenModalPaymentFailure(true);
          }
          closeModal();
        })
        .catch((error) => {
          // toast.error("Something went wrong!");
          setOpenModalPaymentFailure(true);

          setLoader(false);
          closeModal();
        });
    } else if (offRampSell) {
      // handling offramp transaction here
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/inhouse/offramp/transaction`,
          data: OffRampTransactionData,
          method: "post",
        },
        (data) => {
          toast.success("Sell request submitted successfully !!");
          navigate("/");
          setLoader(false);
        },
        (error) => {
          toast.error("Failed to sell !!");
          setLoader(false);
        },
        () => {}
      );
    } else {
      if (
        appCtx.blockchain !== "ALGORAND" &&
        appCtx.isLoggedIn &&
        !offRampSell
      ) {
        setLoader(true);
        makeOtherTokenTransfer();
      }
      if (!appCtx.isLoggedIn) {
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/verify?${loginType}`,
            data: {
              email: otpLoginUserEmail,
              otp: otp1,
              referredBy: localStorage.getItem("referral"),
            },
            method: "post",
          },
          (data) => {
            setIsVerifiedOtp(true);

            if (rewards) {
              if (
                appCtx.authToken &&
                data?.authToken &&
                appCtx.authToken !== data?.authToken &&
                appCtx.authToken !== null
              ) {
                setData(data);
                setContinueWith(true);
              } else {
                setLoader(false);

                makeRequest(
                  {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/wallet/connect`,
                    data: {
                      address: appCtx.walletAddress[0]?.address,
                      wallet: "TALEWALLET",
                      blockchain: "ALGORAND",
                      marketplaceAddress: "0",
                      custodial: false,
                    },
                    method: "post",
                  },
                  (data1) => {
                    dispatch(
                      appActions.updateUserDetails({
                        ...appCtx.userDetails,
                        email: otpLoginUserEmail,
                      })
                    );
                    setOpenModal(false);
                  },
                  () => {
                    toast.error(
                      "wallet connect unsuccessfull!!! if this issue persists.. logout and try again"
                    );
                  }
                );
              }
            } else {
              setLoader(false);
              dispatch(
                appActions.updateUserDetails(data)
              );
              dispatch(appActions.updateAuthToken(data?.authToken));
              window.localStorage.setItem("userId", data.userId);
              window.localStorage.setItem("authToken", data.authToken);
              setOpenModal(false);
            }

            dispatch(appActions.login(true));
          },
          (error) => {
            toast.error("OTP entered is incorrect !");
            setLoader(false);
          },
          () => {}
        );
      }
    }
  };
  const handleResendOtp = () => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}&resend=true`,
        data: { email: otpLoginUserEmail },
        method: "post",
      },
      (data) => {
        toast("OTP Sent SuccesFully!");
        setReSendOtp(true);
        distance1 = 0;
        setOtp1("");
        if (data.message === "Success" && data.status === true) {
          setHandleEnterOtp(true);
          setLoader(false);
          // var x = setInterval(function () {

          //     setMinutes(Math.floor(resendDistance / 60));
          //     setSeconds(((resendDistance % 60)));
          //     resendDistance--;
          //     if(isVerifiedOtp === true){
          //         distance1=0;
          //         resendDistance=0;
          //     }
          //     if (resendDistance < 0) {
          //         clearInterval(x);
          //         navigate("/signup");
          //     }
          //     else if (resendDistance===0) {
          //         clearInterval(x);
          //     }

          // }, 1000)
        }
      },
      () => {}
    );
  };
  const handleContinueAccount = () => {
    dispatch(
      appActions.updateUserDetails(data)
    );
    dispatch(appActions.setWalletAddress([{ address: data.userName }]));
    dispatch(appActions.updateAuthToken(data.authToken));
    window.localStorage.setItem("userId", data.userId);
    window.localStorage.setItem("authToken", data.authToken);
    dispatch(appActions.setMnemonicCode('""'));
    dispatch(appActions.setAlgoMnemonicCode(""));
    dispatch(appActions.setEvmMnemonicCode(""));
    dispatch(appActions.setCustodial(true));
    setOpenModal(false);
  };


    let isMobileDevice = width < 600;

    const customStyles = {
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 9997,
      },
      content: {
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: 9998,
        backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
        border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
        color: `${appCtx?.isDarkMode ? "white" : "black"}`,

        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        padding: "20px",
        borderRadius: "10px",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        width: `${isMobileDevice ? "95%" : "500px"}`,
      },
    };
  function closeModal() {
    setOpenModal(false);
    // setOpenPreviousModal(true);
  }

  function makeOtherTokenTransfer() {
    let data = JSON.stringify({
      amount: amount,
      receiver: recieverAddress,
      senderUserId: appCtx.userDetails.userId,
      blockchain: appCtx.blockchain,
      token: option,
      email: appCtx.userDetails.email,
      otp: otp1,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/erc20/token/transfer`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          // toast.success("Token Transfer Successfull");
          setTransactionHash(response?.data?.blockchainTransactionId);

          setOpenTransferSuccessModal(true);
          setLoader(false);
          closeModal();
        } else {
          toast.error("Token Transfer failed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log("transactionHash", transactionHash);
  // console.log("isVerifiedOtp", isVerifiedOtp);
  // console.log("otp1", otp1);
  // console.log("rewards", rewards);

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={
          rewards ? false : recoverPopup ? true : false
        }
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="py-3">
          {!continueWith ? (
            <>
              {!rewards && (
                <div className="">
                  <div
                    className="d-flex align-items-center gap-2"
                    onClick={closeModal}
                    style={{ cursor: "pointer" }}
                  >
                    <ArrowBackIosOutlinedIcon
                      className=""
                      style={{ width: "20px" }}
                    />
                    <h5 className="mb-0"> Back</h5>
                  </div>
                </div>
              )}
              {recoverPopup && (
                <div className="w-100 d-flex justify-end pt-2">
                  <button
                    className="font-weight-medium d-flex align-items-center leading-2 mr-2 mt-2"
                    onClick={closeModal}
                  >
                    x
                  </button>
                </div>
              )}
              <div className="d-flex flex-column justify-content-center align-items-center px-lg-3">
                <img
                  src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
                  style={{ width: "50px", height: "50px" }}
                  alt="tale_logo"
                />
                {recoverPopup ? (
                  <>
                    <div className="text-center mb-2">
                      To complete your E-KYC, you must verify your email
                      address. If you wish to use your wallet without E-KYC, you
                      can skip the process.
                    </div>
                  </>
                ) : rewards ? (
                  <div className="text-center mb-2">
                    Please provide the same email Id which you submitted while
                    joining the waitlist
                  </div>
                ) : (
                  <>
                    {transfer ? (
                      <>
                        <div className="text-20px font-semibold mt-4 mb-2">
                          Verify your Email to complete the transaction.
                        </div>
                      </>
                    ) : payAlgos ? (
                      <>
                        <div className="text-20px font-semibold mt-4 mb-2">
                          Verify your Email to complete the Transfer.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-24px lg:text-30px text-purple-600 font-semibold">
                          Welcome
                        </div>
                        <div>
                          {login ? "Login with OTP" : "Signup with OTP"}
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="w-100">
                  <div className={!clickledOnLoginOtp ? "h-100" : ""}>
                    <div className="mb-2">
                      {!clickledOnLoginOtp ? (
                        <>
                          <div className="font-medium mb-2">Your Email Id</div>
                          <div>
                            <div className="input-group">
                              <input
                                className="form-control border rounded ps-3"
                                disabled={transfer || payAlgos}
                                value={otpLoginUserEmail}
                                placeholder="Enter your Email ID"
                                onChange={handleChange}
                                name="email"
                                style={{
                                  color: themedColor,
                                }}
                              />
                              <span className="input-group-text">
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  style={{
                                    color: themedColor,
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center mt-2">
                            <input
                              value={otp1}
                              onChange={(e) => {
                                setOtp1(e.target.value);
                              }}
                              className=" form-control py-2"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between pb-2">
                    <div
                      className="font-weight-bold cursor-pointer"
                      style={{
                        visibility: !reSendOtp ? "hidden" : "visible",
                        display: !clickledOnLoginOtp ? "none" : "block",
                      }}
                      onClick={() => {
                        handleResendOtp();
                      }}
                    >
                      Resend OTP
                    </div>
                    <div className="font-weight-bold">
                      {!(minutes === 0 && seconds === 0) ? (
                        <>
                          {minutes >= 0 && minutes <= 9
                            ? `0${minutes}`
                            : minutes}
                          :
                          {seconds >= 0 && seconds <= 9
                            ? `0${seconds}`
                            : seconds}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center pb-2 pt-2">
                    <button
                      className="btn btn-primary d-flex justify-content-center align-items-center gap-2"
                      onClick={() =>
                        !clickledOnLoginOtp ? handleSendOtp() : handleCheckOtp()
                      }
                    >
                      <div>
                        {!clickledOnLoginOtp ? `Send OTP` : "Verify OTP"}
                      </div>
                      {loader ? (
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="ml-2"
                          fontSize="small"
                        />
                      ) : (
                        <span className="ml-2">
                          <EastIcon />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column px-lg-3 justify-content-center align-items-center pb-3 mx-9 gap-3">
              <img
                src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
                className=""
                alt="tale_logo"
                style={{ width: "50px", height: "50px" }}
              />
              <div className="text-center">
                It seems like you already have an account associated with this
                email
              </div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    handleContinueAccount();
                  }}
                >
                  Use already connected account
                </button>
                <span>OR</span>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    dispatch(
                      appActions.updateUserDetails({
                        ...appCtx.userDetails,
                        email: data?.email || otpLoginUserEmail,
                      })
                    );
                    setOpenModal(false);
                  }}
                >
                  continue with this account
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      {openTransferSuccessModal && (
        <SuccessModal
          openModal={openTransferSuccessModal}
          setOpenModal={setOpenTransferSuccessModal}
          amount={amount}
          option={option}
          address={recieverAddress}
          setPreviousOpen={setPreviousOpen}
          setConfirmOpen={setConfirmOpen}
          transactionHash={transactionHash}
        />
      )}
    </>
  );
}

export default SignupPageModal;
