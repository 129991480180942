import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { red } from "@mui/material/colors";
import { useLocation } from "react-router-dom";

const UsersCryptoBasketCard = ({ item, showCreatedBy }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div
      class="d-flex justify-content-between align-items-center gap-4  market-preview"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/crypto-basket/${item?.basketId}`)}
    >
      <div class="w-100">
        <div class="row d-flex align-items-center">
          {/* left side  */}
          <div class="col-12 col-md-6">
            <div className="d-flex align-items-center justify-content-between py-4">
              <div class="d-flex align-items-center">
                <span>
                  {item?.icon ? (
                    <img
                      src={item?.icon}
                      alt=""
                      style={{
                        width: "36px",
                        height: "36px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <img
                      src="/images/basketimage.svg"
                      alt=""
                      style={{
                        width: "36px",
                        height: "36px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      className="rounded-circle"
                    />
                  )}
                </span>
                <div class="ms-3">
                  <h5 class="fs-14 font-w600 mb-0">
                    {item?.name && item?.name?.length > 25
                      ? `${item?.name.substring(0, 25)}...`
                      : item?.name}
                  </h5>
                  {showCreatedBy !== false && item?.wealthManagerName && (
                    <h6 class="fs-12 font-w400">
                      by{" "}
                      {item?.wealthManagerName ? item?.wealthManagerName : "--"}
                    </h6>
                  )}
                </div>
              </div>
              <div class="d-flex  ">
                <div class="">
                  <p
                    className={`${
                      item?.annualCAGR > 0 ? "text-success" : "text-danger"
                    } mb-0 text-center fs-14 font-w600`}
                  >
                    {item?.annualCAGR || "--"}
                  </p>

                  <h5 class="fs-12 font-w400 mb-0  text-end">CAGR</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            {/* right side  */}
            <div className="d-flex align-items-center justify-content-between">
              <div className="col-xl-9 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center gap-4">
                  <div
                    className="vertical-line-bg  d-none d-md-block"
                    style={{
                      height: "40px",
                      width: "1px",
                    }}
                  ></div>
                  <div class="">
                    <h5
                      // className={`fs-12 font-w400 mb-0 ${
                      //   item?.need_to_update > 0
                      //     ? "text-success"
                      //     : "text-danger"
                      // }  text-center fs-14 font-w600 `}
                      className="mb-0"
                    >
                      {item?.need_to_update || "--"}
                    </h5>
                    <h6 class="fs-12 font-w400">One Day</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 ">
                  {/* <CryptoFundLineChart /> */}

                  <div
                    className="vertical-line-bg  d-none d-md-block"
                    style={{
                      height: "40px",
                      width: "1px",
                    }}
                  ></div>
                </div>
              </div>

              <div class="d-flex col-xl-3  ">
                <div class="w-100">
                  <p
                    className={`text-end mb-0 fs-14 font-w600 ${
                      appCtx?.isDarkMode ? "text-white" : "text-black"
                    }`}
                  >
                    {item?.minAmountCurrency} {item?.minAmount}
                  </p>
                  <h5 class="fs-12 font-w400 mb-0  text-end">Minimum Invest</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersCryptoBasketCard;
