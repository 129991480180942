import React, { useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { CircularProgress } from "@mui/material";

const MultiSwapSuccessModal = ({
  openModal,
  setOpenModal,
  symbol,
  sendTokenObject,
  sendAmount,
  basketId,
  isPending,
  error,
  isConfirmed,
  custodailBasketPurchaseLoading,
  custodailBasketPurchaseSuccess,
  setOpenPreviousModal,
  setopenPaymentMethodModal,
}) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "20px",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };
  function closeModal() {
    setOpenModal(false);
    setOpenPreviousModal(false);
    setopenPaymentMethodModal(false);
  }

  useEffect(() => {
    if (!appCtx?.custodial) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/${basketId}/subscribe?custodial=false`,
          method: "post",
          data: {
            token: symbol,
            tokenId: sendTokenObject?.tokenId,
            amount: sendAmount,
          },
        },
        (data) => {
          //   setBasketPurchaseLoading(false);
          //   setBasketPurchaseSuccess(true);
          // todo::
          // need to add success modal
        },
        () => {
          console.log("Error: ");
          //   setBasketPurchaseLoading(false);
        },
        () => {
          // navigate('/login')
          //   setBasketPurchaseLoading(false);
        }
      );
    }
  }, []);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="mt-4 w-100">
        {!appCtx?.custodial ? (
          <>
            {isPending ? (
              <div className="w-100 my-5 d-flex flex-column gap-2 justify-content-center align-items-center">
                <h4 className="font-weight-bold text-2xl text-center">
                  Transaction Pending...
                </h4>
                <CircularProgress size={20} className="ml-[5px]" />
              </div>
            ) : error ? (
              <h4 className="font-weight-bold text-2xl text-danger text-center">
                Something went wrong. please try again.
              </h4>
            ) : isConfirmed ? (
              <h4 className="font-weight-bold text-2xl text-center">
                Basket Subscription Successful
              </h4>
            ) : (
              <div className="w-100 my-5 d-flex flex-column gap-2 justify-content-center align-items-center">
                <h4 className="font-weight-bold text-2xl text-center">
                  Transaction Pending...
                </h4>
                <CircularProgress size={20} className="ml-[5px]" />
              </div>
            )}
          </>
        ) : (
          <>
            {custodailBasketPurchaseLoading ? (
              <div className="w-100 my-5 d-flex flex-column gap-2 justify-content-center align-items-center">
                <h4 className="font-weight-bold text-2xl text-center">
                  Transaction Pending...
                </h4>
                <CircularProgress size={20} className="ml-[5px]" />
              </div>
            ) : custodailBasketPurchaseSuccess ? (
              <h4 className="font-weight-bold text-2xl text-center">
                Basket Subscription Successful
              </h4>
            ) : (
              <h4 className="font-weight-bold text-2xl text-danger text-center">
                Something went wrong. please try again.
              </h4>
            )}
          </>
        )}

        <div class="w-100 d-flex justify-content-center">
          <div class="w-50">
            <button
              onClick={() => {
                closeModal();
              }}
              class="mb-3 py-2 text-md font-weight-bold text-white bg-success border border-success rounded active:text-secondary focus:outline-none focus:ring w-100"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MultiSwapSuccessModal;
