import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PaidIcon from "@mui/icons-material/Paid";
import TokenIcon from "@mui/icons-material/Token";
import WalletIcon from "@mui/icons-material/Wallet";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { appActions } from "../../context/app-slice";
import WcIcon from "@mui/icons-material/Wc";
import PeopleIcon from "@mui/icons-material/People";

const width = window.screen.width;
console.log("hello width--", width);

const SidebarMobile = ({ children }) => {
  const appCtx = useSelector((state) => state.app);
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const dispatch = useDispatch();
  const sidebarVariants = {
    hidden: {
      x: "-100%",
    },
    visible: {
      x: "-5%",
    },
  };

  let isOpen = appCtx?.isSideBarOpen;

  let routes = [];
  if (appCtx?.role === "user" || appCtx?.role === "expertNormal") {
    routes = [
      {
        path: "/",
        name: "Dashboard",
        icon: <DashboardIcon className={`w-[50px]`} />,
      },
      {
        path: "/crypto-basket",
        name: "Crypto Basket",
        icon: <ShoppingBasketIcon className="w-[50px]" />,
        subRoutes: ["/crypto-basket"],
      },
      {
        path: "/crypto-fund",
        name: "Crypto Fund",
        icon: <PaidIcon className="w-[50px]" />,
        subRoutes: ["/crypto-fund"],
      },
      {
        path: "/tokens",
        name: "Tokens",
        icon: <TokenIcon className="w-[50px]" />,
        subRoutes: ["/token"],
      },
      {
        path: "/wallet",
        name: "Wallet",
        icon: <WalletIcon className="w-[50px]" />,
      },
      {
        path: "/portfolio",
        name: "Portfolio",
        icon: <AssignmentIndIcon className={`w-[50px]`} />,
      },
      {
        path: "/community-rewards",
        name: "Rewards",
        icon: <PeopleIcon className={`w-[50px]`} />,
      },
      {
        path: "/refer-and-earn",
        name: "Refer & Earn",
        icon: <WcIcon className={`w-[50px]`} />,
      },
    ];
  } else {
    routes = [
      {
        path: "/",
        name: "Dashboard",
        icon: <DashboardIcon className={`w-[50px]`} />,
      },
      {
        path: "/wallet",
        name: "Wallet",
        icon: <WalletIcon className="w-[50px]" />,
      },
      {
        path: "/crypto-basket",
        name: "Crypto Basket",
        icon: <ShoppingBasketIcon className="w-[50px]" />,
        subRoutes: ["/crypto-basket"],
      },
      {
        path: "/crypto-fund",
        name: "Crypto Fund",
        icon: <PaidIcon className="w-[50px]" />,
        subRoutes: ["/crypto-fund"],
      },
      {
        path: "/spot",
        name: "Spot Trade",
        icon: <AutoGraphIcon className="w-[50px] " />,
        subRoutes: ["/spot"],
      },
      {
        path: "/future",
        name: "Futures Trade",
        icon: <EqualizerIcon className="w-[50px]" />,
        subRoutes: ["/future"],
      },
      {
        path: "/community-rewards",
        name: "Rewards",
        icon: <PeopleIcon className={`w-[50px]`} />,
      },
      {
        path: "/refer-and-earn",
        name: "Refer & Earn",
        icon: <WcIcon className={`w-[50px]`} />,
      },
    ];
  }

  return (
    <>
      <motion.nav
        className={isOpen ? "nav-mobile-menu  active" : "nav-mobile-menu"}
        variants={sidebarVariants}
        initial="hidden"
        animate="visible"
        exit="visible"
        style={{ zIndex: 10 }}
      >
        <ul className="">
          <section className="routes pt-4">
            {routes.map((route, index) => {
              return (
                <NavLink
                  onClick={() => {
                    dispatch(
                      appActions.setIsSideBarOpen(!appCtx.isSideBarOpen)
                    );
                  }}
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active d-flex gap-2 mt-4"
                >
                  {/* <div className="icon">{route.icon}</div> */}
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        initial="hidden"
                        exit="hidden"
                        className="d-flex align-items-center ms-2 "
                      >
                        <div className="icon">{route.icon}</div>
                        <p className="mb-0">{route.name}</p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </ul>
      </motion.nav>
    </>
  );
};

export default SidebarMobile;
