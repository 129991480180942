import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { CircularProgress, Skeleton } from "@mui/material";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";
import { appActions } from "../../context/app-slice";
import MainWrapper from "../../component/wrapper/MainWrapper";
import TokenTablesCard from "./TokenTablesCard";
import CryptoFundLineChart from "../cryptoFund/CryptoFundLineChart";

const Tokens = () => {
  const [pausePriceApiCall, setPausePriceApiCall] = useState(false);
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false);

  const makeRequest = useAuthorizedHttp();
  const navigate = useNavigate();
  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    appCtx,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    isCoinPriceLOading,
    icon,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    totalpage,
    setAllcoinPrice,
    listedToken,
    listedTokenLoading,
    getListedTokens,
  } = useWalletView();

  useEffect(() => {
    // getAllCoin(0, 100);
    // getAllCoinPrice();
    // getUsersToken();
    getListedTokens();
  }, [appCtx.blockchain, openAddTokenModal === false]);

  // calling additional price api

  useEffect(() => {
    if (
      allCoin.length !== 0 &&
      Object.keys(allCoinPrice).length !== 0 &&
      allCoin.length !== Object.keys(allCoinPrice).length &&
      !isCoinLoading &&
      !isCoinPriceLOading &&
      !pausePriceApiCall
    ) {
      const tokenSymbolsWithNoPrice = allCoin
        ?.filter((coin) => !allCoinPrice[coin.symbol?.toUpperCase()]?.price)
        ?.map((coin) => coin?.symbol);

      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price`,
          data: tokenSymbolsWithNoPrice,
          method: "post",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          setAllcoinPrice((prev) => ({ ...prev, ...data }));
          setPausePriceApiCall(true);
        },
        (error) => {}
      );
    }
  }, [allCoin, allCoinPrice, appCtx.blockchain]);

  return (
    <>
      <MainWrapper>
        {/* <FutureHeader /> */}
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            <div class="col-xl-12">
              {listedTokenLoading ? (
                <div
                  class="d-flex justify-content-center align-items-center card crypto-chart"
                  style={{ minHeight: "400px" }}
                >
                  <CircularProgress
                    style={{
                      color: "#BBFF00",
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    className="py-10"
                    fontSize="small"
                  />
                </div>
              ) : (
                <>
                  {listedToken?.length === 0 ? (
                    <div className="card crypto-chart">
                      <div className="mt-5">
                        <p className="text-center  ">No Token found</p>
                      </div>
                    </div>
                  ) : (
                    <div class="card w-100">
                      <div class="card-header pb-0 border-0 flex-wrap">
                        <div class="mb-0">
                          <h4 class="card-title">Tokens</h4>
                        </div>
                      </div>
                      <div class="card-body pt-2 overflow-auto">
                        <div class="d-flex align-items-center  market-preview">
                          <div class="w-100">
                            <div class="row">
                              <div className="d-flex align-items-center font-w600">
                                <div class="d-flex align-items-center col-md-3">
                                  Token
                                </div>

                                <div className="d-flex flex-column align-items-start justify-content-start col-4 col-md-2">
                                  <div className="">Marketcap($)</div>
                                </div>
                                <div className="d-flex flex-column align-items-start justify-content-start col-4 col-md-2">
                                  <div className="">1 day</div>
                                </div>

                                <div className="d-flex align-items-center justify-content-start  col-4 col-md-3">
                                  Last 7 days
                                </div>
                                <div className="d-flex flex-column align-items-start justify-content-end col-4 col-md-2">
                                  <div className="w-100 text-end">Price($)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {listedToken?.map((token, index) => (
                          <TokenTablesCard key={index} token={token} />
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default Tokens;
