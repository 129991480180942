import React, { useRef, useState } from "react";
import useBinanceFeed from "./useBinanceFeed";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./OrderBook.css";
import BuySellModal from "./BuySellModal";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const OrderBook = ({
  product_id,
  allCoin,
  allCoinPrice,
  usersToken,
  isCoinLoading,
  setOpenTransferSuccessModal,
  setTransactionData,
  selectedFundWalletAddress,
  selectedFundName,
  selectedFundId,
  setSelectedFundId,
  tokenname,
}) => {
  const { bids, asks, middlePrice } = useBinanceFeed(product_id);
  const [filter, setFilter] = useState("All");
  const [openBuySellModal, setOpenBuySellModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Buy");

  const location = useLocation();

  const isFutureRoute = location.pathname.includes("future");

  let fullHeight = window.innerHeight;
  let halfHeight = window.innerHeight / 2;

  let heightvalue = 0;
  if (filter === "All") {
    heightvalue = halfHeight;
  } else {
    heightvalue = fullHeight - 120;
  }
  function getOBPriceInDecimal(price) {
    let tokenprice;
    if (price >= 1000) {
      tokenprice = price?.toFixed(0);
    } else if (price >= 1) {
      tokenprice = price?.toFixed(2);
    } else if (price < 0.001) {
      tokenprice = price?.toFixed(6);
    } else {
      tokenprice = price?.toFixed(4);
    }

    return tokenprice;
  }

  const AskRow = () => (
    <div
      className="row"
      style={{ height: `${heightvalue - 160}px`, overflow: "hidden" }}
    >
      <div className="col">
        {/* <h4>Asks</h4> */}
        <div>
          {asks?.map((ask, index) => (
            <div
              key={index}
              className="d-flex flex-direction-row justify-content-between"
            >
              <span className=" text-danger">
                {ask?.price
                  ? `${getOBPriceInDecimal(parseFloat(ask?.price))}`
                  : ""}
              </span>
              <span>
                {ask?.qty ? `${parseFloat(ask?.qty)?.toFixed(4)}` : ""}
              </span>
              <span>{parseInt(ask.price * ask.qty)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const BidRow = () => (
    <div
      className="row"
      style={{ height: `${heightvalue - 160}px`, overflow: "hidden" }}
    >
      <div className="col">
        {/* <h4>Bids</h4> */}
        <div>
          {bids?.map((bid, index) => (
            <div
              key={index}
              className="d-flex flex-direction-row justify-content-between"
            >
              <span className="text-success">
                {bid?.price
                  ? `${getOBPriceInDecimal(parseFloat(bid.price))}`
                  : ""}
              </span>
              <span>
                {bid?.qty ? `${parseFloat(bid?.qty)?.toFixed(4)}` : ""}
              </span>
              <span>
                {bid?.price !== undefined && bid?.qty !== undefined
                  ? parseInt(bid.price * bid.qty)
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  const MidRow = () => (
    <div
      className={`${
        middlePrice?.color === "text-success"
          ? "middlebg-color-green"
          : middlePrice?.color === "text-danger"
          ? "middlebg-color-red"
          : "middlebg-color-gray"
      } height40 my-2 rounded d-flex flex-direction-row justify-content-center align-items-center `}
    >
      <div className={`${middlePrice?.color}  font-bold`}>
        {middlePrice?.price
          ? `${getOBPriceInDecimal(parseFloat(middlePrice.price))}`
          : ""}
      </div>
      {/* <ArrowDropDownIcon /> */}
    </div>
  );

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  return (
    <div className="order-container ">
      <div className="">
        <h5 class="">Order Book</h5>

        <div className="d-flex flex-direction-row  gap-2">
          <div
            className={` ${filter === "All" ? "text-danger" : ""}`}
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => handleFilterChange("All")}
          >
            All
          </div>
          <div
            type="button"
            className={` ${filter === "Ask" ? "text-danger" : ""}`}
            onClick={() => handleFilterChange("Ask")}
            style={{ fontSize: "12px", cursor: "pointer" }}
          >
            Ask
          </div>
          <div
            type="button"
            className={` ${filter === "Bid" ? "text-danger" : ""}`}
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => handleFilterChange("Bid")}
          >
            Bid
          </div>
        </div>
      </div>
      <div className="row " style={{ overflow: "hidden" }}>
        <div className="col">
          <div className="d-flex flex-direction-row justify-content-between">
            <p className="" style={{ fontSize: "10px" }}>
              price(USDT)
            </p>
            <p className="" style={{ fontSize: "10px" }}>
              qty({tokenname})
            </p>
            <p className="" style={{ fontSize: "10px" }}>
              total(USDT)
            </p>
          </div>
        </div>
      </div>
      {filter === "All" ? (
        <div className="">
          <AskRow />
          <MidRow />
          <BidRow />
        </div>
      ) : null}
      {filter === "Ask" ? (
        <>
          <AskRow />
        </>
      ) : null}
      {filter === "Bid" ? (
        <>
          <BidRow />
        </>
      ) : null}

      <div className="d-flex flex-column gap-1">
        <button
          disabled={isFutureRoute}
          onClick={() => {
            if (selectedFundWalletAddress) {
              setOpenBuySellModal(true);
              setSelectedButton("Buy");
            } else {
              toast.error("Please Select a Fund");
            }
          }}
          type="button"
          className="btn btn-success "
        >
          Buy {isFutureRoute && "(Coming soon)"}
        </button>
        <button
          disabled={isFutureRoute}
          onClick={() => {
            if (selectedFundWalletAddress) {
              setOpenBuySellModal(true);
              setSelectedButton("Sell");
            } else {
              toast.error("Please Select a Fund");
            }
          }}
          type="button"
          className="btn btn-danger "
        >
          Sell {isFutureRoute && "(Coming soon)"}
        </button>
      </div>

      {openBuySellModal && (
        <BuySellModal
          openModal={openBuySellModal}
          setOpenModal={setOpenBuySellModal}
          selectedButton={selectedButton}
          allCoin={allCoin}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
          isCoinLoading={isCoinLoading}
          setOpenTransferSuccessModal={setOpenTransferSuccessModal}
          setTransactionData={setTransactionData}
          product_id={product_id}
          selectedFundWalletAddress={selectedFundWalletAddress}
          selectedFundName={selectedFundName}
          selectedFundId={selectedFundId}
          setSelectedFundId={setSelectedFundId}
        />
      )}
    </div>
  );
};

export default OrderBook;
