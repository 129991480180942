import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { erc20Abi } from "../../component/Abi/abi";
import { parseUnits } from "ethers";
const {
  abi: V3SwapRouterABI,
} = require("@uniswap/v3-periphery/artifacts/contracts/interfaces/ISwapRouter.sol/ISwapRouter.json");
const {
  abi: PeripheryPaymentsABI,
} = require("@uniswap/v3-periphery/artifacts/contracts/interfaces/IPeripheryPayments.sol/IPeripheryPayments.json");
const {
  abi: MulticallABI,
} = require("@uniswap/v3-periphery/artifacts/contracts/interfaces/IMulticall.sol/IMulticall.json");

function Test() {
  const [account, setAccount] = useState("");
  const [usdtAmount, setUsdtAmount] = useState("0.1");
  const [token1, setToken1] = useState(
    "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063"
  );
  const [token2, setToken2] = useState(
    "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD"
  );
  const [token3, setToken3] = useState(
    "0x5fe2B58c013d7601147DcdD68C143A77499f5531"
  );

  // Connect to MetaMask
  const connectWallet = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      console.error("MetaMask is not installed");
    }
  };

  const approveUSDT = async (web3, routerAddress) => {
    const usdtContract = new web3.eth.Contract(
      erc20Abi,
      "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
    );
    const amountToApprove = web3.utils.toWei(usdtAmount, "mwei");
    console.log("account", account);
    console.log("amountToApprove", amountToApprove);
    await usdtContract.methods
      .approve(routerAddress, amountToApprove)
      .send({ from: account });
  };

  // Swap USDT for Tokens using Uniswap Multicall
  const swapTokens = async () => {
    const web3 = new Web3(window.ethereum);
    const multicallAddress = "0xcf6119b221abd70389a835fff14aed6b04934920"; // Correct Polygon Multicall address
    const routerAddress = "0xE592427A0AEce92De3Edee1F18E0157C05861564"; // Uniswap Router contract address

    const routerContract = new web3.eth.Contract(
      V3SwapRouterABI.concat(PeripheryPaymentsABI).concat(MulticallABI),
      routerAddress
    );
    const multicallContract = new web3.eth.Contract(
      MulticallABI,
      multicallAddress
    );

    console.log("routerContract", routerContract);
    console.log("multicallContract", multicallContract);

    const validatedUsdtAmount = web3.utils.toWei(usdtAmount, "mwei"); // Assuming USDT is in 6 decimals
    // const validatedUsdtAmount = parseUnits(usdtAmount, 6).toString(); // Assuming USDT is in 6 decimals
    const amountPerSwap = validatedUsdtAmount / 3; // Split the total USDT amount into three parts
    const amountOutMin = parseUnits("0.1", 18).toString(); // Adjust according to your needs

    // Approve the router to spend USDT
    await approveUSDT(web3, routerAddress);

    const swapCallData = [
      routerContract.methods
        .exactInputSingle({
          tokenIn: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f", // USDT Token Contract Address
          tokenOut: token1,
          fee: 3000, // Pool fee
          recipient: account,
          deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from current Unix time
          amountIn: amountPerSwap.toString(),
          amountOutMinimum: amountOutMin,
          sqrtPriceLimitX96: 0,
        })
        .encodeABI(),

      routerContract.methods
        .exactInputSingle({
          tokenIn: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f", // USDT Token Contract Address
          tokenOut: token2,
          fee: 3000, // Pool fee
          recipient: account,
          deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from current Unix time
          amountIn: amountPerSwap.toString(),
          amountOutMinimum: amountOutMin,
          sqrtPriceLimitX96: 0,
        })
        .encodeABI(),

      routerContract.methods
        .exactInputSingle({
          tokenIn: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f", // USDT Token Contract Address
          tokenOut: token3,
          fee: 3000, // Pool fee
          recipient: account,
          deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from current Unix time
          amountIn: amountPerSwap.toString(),
          amountOutMinimum: amountOutMin,
          sqrtPriceLimitX96: 0,
        })
        .encodeABI(),
    ];

    try {
      const results = await multicallContract.methods
        .multicall(swapCallData)
        .send({ from: account });
      console.log("Multicall successful", results);
    } catch (error) {
      console.error("Multicall failed", error);
    }
  };

  return (
    <div>
      <button onClick={connectWallet}>Connect Wallet</button>
      <div>Account: {account}</div>
      <input
        type="text"
        placeholder="USDT Amount"
        value={usdtAmount}
        onChange={(e) => setUsdtAmount(e.target.value)}
      />
      <input
        type="text"
        placeholder="Token 1 Address"
        value={token1}
        onChange={(e) => setToken1(e.target.value)}
      />
      <input
        type="text"
        placeholder="Token 2 Address"
        value={token2}
        onChange={(e) => setToken2(e.target.value)}
      />
      <input
        type="text"
        placeholder="Token 3 Address"
        value={token3}
        onChange={(e) => setToken3(e.target.value)}
      />
      <button onClick={swapTokens}>Swap</button>
    </div>
  );
}

export default Test;