import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { toast } from "react-toastify";
import { appActions } from "../../context/app-slice";

const ReferralModal = ({
  openModal,
  setOpenModal,
  referralCode,
  setReferralCode,
}) => {
  const appCtx = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);

  const makeRequest = useAuthorizedHttp();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "20px",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const handleReferralCodeSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/referral/code/${referralCode}/verify`,
      },
      (data) => {
        //valid referralcode
        toast.success("Invite code added successfully");
        dispatch(appActions.updateReferredBy(referralCode));
        setLoading(false);
        closeModal();
      },
      (error) => {
        toast.error("Invalid invite code");
        setLoading(false);
      }
    ).catch((error) => {
      // Handle any uncaught errors here
      console.error("Unhandled error:", error);
    });
  };

  return (
    <Modal
      isOpen={openModal}
      // onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div
        className=""
        style={{
          color: "#333333",
        }}
      >
        <form className="">
          <h3
            className="text-center mt-2 "
            style={{
              color: "#570987",
            }}
          >
            ENTER YOUR INVITE CODE
          </h3>
          <p className="text-center">
            Tale Invest is right now in closed beta and requires an INVITE CODE
          </p>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="referralCode"
              placeholder="Enter your invite code"
              value={referralCode}
              onChange={(event) => setReferralCode(event.target.value)}
            />
          </div>
        </form>
        <div className="d-flex justify-content-center gap-4 mt-4 ">
          <button
            className="btn  font-w600"
            style={{
              backgroundColor: "#570987",
              color: "white",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
            type="submit"
            onClick={handleReferralCodeSubmit} //need to integrate api
          >
            SUBMIT{" "}
            <span>
              {loading && <CircularProgress size={20} className="ml-[5px]" />}
            </span>
          </button>
        </div>
        <p className="mt-3 text-muted text-center">
          To get the invite code join our discord group{" "}
          <span
            className="text-primary"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              window.open("https://discord.com/invite/tbs47P7gDW");
            }}
          >
            @https://discord.com/invite/tbs47P7gDW
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default ReferralModal;
