import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const HomeCryptoFundAreaChart = ({
  tokenid,
  selectedRange,
  setPriceLoading,
  composition,
  selectedFundTokenName,
  priceLoading,
}) => {
  const [chartData, setChartData] = useState([]);
  const appCtx = useSelector((state) => state.app);

  const bgColor = [
    "bg-primary",
    "bg-secondary",
    "bg-success",
    "bg-danger",
    "bg-warning",
    "bg-info",
    "bg-light",
    "bg-dark",
  ];

  const fetchTokenPrice = (id, tokenName) => {
    setPriceLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price/history?tokenId=${id}&range=${selectedRange}`,
      headers: {
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
      },
    };

    axios
      .request(config)
      .then((response) => {
        const priceData = response?.data?.prices?.map((price, index) => {
          return { x: price[0], y: price[1] }; // Ensure each data point has an 'x' and 'y' value
        });

        const combinedPriceData = {
          name: tokenName,
          className: bgColor[Math.floor(Math.random() * bgColor.length)],
          data: priceData,
        };
        setChartData((prevChartData) => [...prevChartData, combinedPriceData]);
        setPriceLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPriceLoading(false);
      });
  };

  useEffect(() => {
    if (tokenid) {
      setChartData([]);
      fetchTokenPrice(tokenid, selectedFundTokenName);
    } else {
      if (composition.length > 0) {
        setChartData([]);
        composition?.map((item) => fetchTokenPrice(item?.tokenId, item?.token));
      }
    }
  }, [selectedRange, tokenid]);

  // Function to calculate min and max y-values from all series
  const findYAxisBounds = (data) => {
    let min = data[0]?.data[0]?.y || 0;
    let max = min;

    data.forEach((series) => {
      series.data.forEach((point) => {
        if (point.y < min) min = point.y;
        if (point.y > max) max = point.y;
      });
    });

    return { min, max };
  };

  const options = {
    series: chartData,
    chart: {
      height: 300,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FFA726",
      "#00E676",
      "#FF4081",
      "#8BC34A",
      "#9C27B0",
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    legend: {
      show: false,
    },
    markers: {
      strokeWidth: 5,
      strokeColors: "#fff",
      hover: {
        size: 10,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 6,
      borderColor: "#E0E0E0",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      tickAmount: 5,
      show: false,
      labels: {
        style: {
          colors: "#333",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        formatter: function (value) {
          return value + "";
        },
      },
    },
    xaxis: {
      type: "datetime",
      // categories: chartData[0]?.data?.map((item, i) => i),
      labels: {
        formatter: function (value) {
          return new Date(value).toLocaleDateString(); // Format to display dates
        },
      },
      labels: {
        style: {
          colors: "#B5B5C3",
          fontSize: "0px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },

    fill: {
      type: "solid",
      opacity: 0,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let tooltipHTML = '<div class="tooltip_box_column">';

        series.forEach((s, index) => {
          tooltipHTML += '<div class="">';
          tooltipHTML += w.config.series[index].name + " </span>";
          tooltipHTML += "<span>" + s[dataPointIndex] + " </span>";
          tooltipHTML += "</div>";
        });

        tooltipHTML += "</div>";

        return tooltipHTML;
      },
    },
  };

  const yAxisBounds = findYAxisBounds(chartData);

  const updatedOptions = {
    ...options,
    yaxis: {
      ...options.yaxis,
      min: yAxisBounds.min,
      max: yAxisBounds.max,
      show: false, // Ensure that y-axis labels are shown to reflect dynamic bounds
      labels: {
        ...options.yaxis.labels,
        formatter: (value) => `${value.toFixed(2)}`, // Optionally format y-axis labels
      },
    },
  };

  return (
    <div>
      {priceLoading ? (
        <div
          class="d-flex justify-content-center align-items-center"
          style={{
            height: "300px",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "#BBFF00",
              fontSize: "10px",
              width: "25px",
              height: "25px",
            }}
            className="py-10"
            fontSize="small"
          />
        </div>
      ) : (
        <>
          {chartData?.length > 0 && !priceLoading && (
            <ReactApexChart
              options={updatedOptions}
              series={options.series}
              type="area"
              height={options.chart.height}
            />
          )}
        </>
      )}
    </div>
  );
};
export default HomeCryptoFundAreaChart;
