import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCryptobasket } from "../cryptobasket/useCryptobasket";
import CreateBasketModal from "../cryptobasket/CreateBasketModal";
import { useCryptoFund } from "../cryptoFund/useCryptoFund";
import CryptoFundOnRampModal from "../../component/CryptoFundOnRampModal/CryptoFundOnRampModal";
import BasketModal from "../cryptobasket/BasketModal";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import WelcomeWrapper from "../../component/wrapper/WelcomeWrapper";
import TaleWalletOnRampModal from "../../component/TaleWalletOnRamp/TaleWalletOnRampModal";
import PaymentMethodModal from "../cryptobasket/PaymentMethodModal";
import BasketPaymentusingTokenModal from "../cryptobasket/BasketPaymentusingTokenModal";
import FundCurrencyModal from "../cryptoFund/FundCurrencyModal";
import PayusingTokenModal from "../cryptoFund/PayusingTokenModal";
import { useWalletView } from "../../hooks/useWalletView";
import CryptoFundOffRampModal from "../../component/CryptoFundOffRampModal/CryptoFundOffRampModal";
import { useMetamask } from "../../hooks/useMetamask";

const Welcome = () => {
  const appCtx = useSelector((state) => state.app);
  const [openCreateBasketModal, setOpenCreateBasketModal] = useState(false);
  const [openCryptoFundOnrampModal, setOpenCryptoFundOnrampModal] =
    useState(false);
  const [stats, setStats] = useState({});
  const [composition, setComposition] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [selectedChartTab, setSelectedChartTab] = useState(0);
  const [subscribed, setSubscribed] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [portfolio, setPortfolio] = useState("");
  const [subscribedData, setSubscribedData] = useState("");
  const [fees, setFees] = useState("");
  const [distributionProgress, setDistributionProgress] = useState(false);
  const [openEditBasketModal, setOpenEditBasketModal] = useState(false);
  const [openFeeInfoModal, setOpenFeeInfoModal] = useState(false);
  const [status, setStatus] = useState("");
  const [subscribeId, setSubscribeId] = useState(null);
  const [topBasketId, setTopBasketId] = useState(null);
  const [topFundId, setTopFundId] = useState(null);
  const [openPaymentMethodModal, setopenPaymentMethodModal] = useState(false);

  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [totalInvestment, setTotalInvestment] = useState(null);
  const [openTalewalletModal, setOpenTalewalletModal] = useState(false);
  const [openHaveAnAccountOptionModal, setOpenHaveAnAccountOptionModal] =
    useState(false);
  const [openContinueInWebModal, setOpenContinueInWebModal] = useState(false);
  const [selectedBasketTokenOption, setSelectedBasketTokenOption] =
    useState(null);
  const [payBasketUsingTokenModal, setPayBasketUsingTokenModal] =
    useState(false);
  const [openBasketTalewalletOnrampModal, setOpenBasketTalewalletOnrampModal] =
    useState(false);

  // for fund
  const [openFundCurrencyModal, setOpenFundCurrencyModal] = useState(false);
  const [payFundUsingTokenModal, setPayFundUsingTokenModal] = useState(false);
  const [selectedFundTokenOption, setSelectedFundTokenOption] = useState(null);

  const [openFundTalewalletOnrampModal, setOpenFundTalewalletOnrampModal] =
    useState(false);

  const makeRequest = useAuthorizedHttp();
  const navigate = useNavigate();

  const { switchChainandOpenModal } = useMetamask();
  const {
    fetchUsersCryptoFund,
    usersCryptoFund,
    usersCryptoFundLoading,
    allCryptoFund,
    setAllCryptoFund,
    fetchAllCryptoFund,
    allCryptoFundLoading,
    fetchAllCryptoFundsStats,
    allFundsStats,
    allCryptoFundsStatsLoading,
  } = useCryptoFund();

  const {
    fetchBasketById,
    singleBasket,
    singleBasketLoading,
    setopenBasketModal,
    openBasketModal,
    closeClicked,
    setCloseClicked,
    successcloseClicked,
    fetchBasketOwner,
    ownerDataLoading,
    basketOwner,
    transactionSuccess,
    setTransactionSuccess,
    setSuccessCloseClicked,
    openSuccessModal,
    setopenSuccessModal,
    fetchPublicBasket,
    publicBasket,
    publicBasketLoading,
  } = useCryptobasket();

  const { allCoin, getAllCoin } = useWalletView();

  useEffect(() => {
    getAllCoin(0, 100);
  }, [appCtx.blockchain]);

  useEffect(() => {
    fetchPublicBasket();
    fetchAllCryptoFund();
  }, []);

  useEffect(() => {
    if (publicBasket?.length > 0) {
      setTopBasketId(publicBasket[0].basketId);
    }
  }, [publicBasket?.length]);
  useEffect(() => {
    if (allCryptoFund?.length > 0) {
      setTopFundId(allCryptoFund[0].fundId);
    }
  }, [allCryptoFund?.length]);

  useEffect(() => {
    if (topBasketId) {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/${topBasketId}/fees`,
        headers: {
          ...(appCtx.authToken
            ? { "X-Auth-Token": appCtx.authToken }
            : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
          "Content-Type": "application/json",
        },
      };

      const fetchData = async () => {
        try {
          const response = await axios.request(config);
          setFees(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [topBasketId]);

  useEffect(() => {
    if (topBasketId) {
      fetchBasketById(topBasketId)
        .then((response) => {
          const userId = response.data.userId;
          return fetchBasketOwner(userId);
        })

        .catch((error) => {
          console.log(error);
        });
    }
  }, [topBasketId]);

  const tokenPercentArray = singleBasket?.cryptoBasketTokens?.map(
    (token) => token?.tokenPercent
  );
  const tokenArray = singleBasket?.cryptoBasketTokens?.map(
    (token) => token?.token
  );

  useEffect(() => {
    if (topFundId) {
      setDataLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${topFundId}/stats`,
          method: "get",
        },
        (data) => {
          setStats(data);
          setDataLoading(false);
        },

        (err) => {}
      );
    }
  }, [topFundId]);

  useEffect(() => {
    if (topFundId) {
      setDataLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${topFundId}/composition`,
          method: "get",
        },
        (data) => {
          setComposition(data);
          setDataLoading(false);
        },

        (err) => {
          setDataLoading(false);
        }
      );
    }
  }, [topFundId]);
  useEffect(() => {
    if (topFundId) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${topFundId}/contracts`,
          method: "get",
        },
        (data) => {
          setContracts(data);
        },

        (err) => {}
      );
    }
  }, [topFundId]);

  const address = contracts?.fundInWallet
    ? contracts?.fundWalletAddress
    : contracts?.contractAddress;

  const truncatedAddress = `${address?.substring(
    0,
    6
  )}.....${address?.substring(address?.length - 6)}`;

  return (
    <>
      <WelcomeWrapper>
        <div
          class=""
          style={{
            marginTop: "70px",
          }}
        >
          {/* <!-- row --> */}
          <div class="container-fluid">
            {appCtx?.referredBy && (
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0"></div>
                  <div className="card-body pt-0">
                    <div className="w-100 text-center">
                      <h2 className="mb-1 mt-2">
                        You were invited to this platform by{" "}
                        <span className="font-w600"> {appCtx?.referredBy}</span>
                      </h2>
                      <h5>
                        <span className="font-w600"> {appCtx?.referredBy}</span>{" "}
                        has recommended following portfolio for you
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="w-100 d-flex justify-content-between align-items-center">
              <h3 className="font-w800 my-4"> Recommended Portfolio</h3>
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="btn btn-success "
              >
                Skip the recommendation and explore the platform
              </button>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header border-0"></div>
                <div className="card-body pt-0">
                  <div className="">
                    {singleBasketLoading ? (
                      <div
                        class="d-flex justify-content-center align-items-center card crypto-chart"
                        style={{ minHeight: "200px" }}
                      >
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                          fontSize="small"
                        />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                          <h3>{singleBasket?.name}</h3>
                          {basketOwner?.name && <p>by {basketOwner?.name}</p>}
                          <div className="d-flex gap-4 mt-3">
                            {singleBasket?.cryptoBasketTokens?.map((token) => (
                              <div
                                key={token?.tokenId}
                                className="d-flex flex-column  align-items-center"
                              >
                                <img
                                  src={token?.icon}
                                  alt=""
                                  className="rounded-circle"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                  }}
                                />
                                <h6 className="mt-1 mb-0 ">{token?.symbol}</h6>
                                <p className="fs-8">{token?.tokenPercent}%</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          class=""
                          style={{
                            width: "25%",
                          }}
                        >
                          <p
                            className={`${
                              singleBasket?.annualCAGR >= 0
                                ? "text-success"
                                : "text-danger"
                            } mb-0 text-center fs-2 font-w600`}
                          >
                            {singleBasket?.annualCAGR || "--"}
                            {singleBasket?.annualCAGR && "%"}
                          </p>
                          <p class="fs-4 font-w400 mb-0  text-center">CAGR</p>

                          <div className="d-flex justify-content-center">
                            <button
                              onClick={() => {
                                if (!appCtx?.custodial) {
                                  switchChainandOpenModal(
                                    setopenBasketModal,
                                    singleBasket?.blockchain
                                  );
                                } else {
                                  setopenBasketModal(true);
                                }
                              }}
                              className="btn btn-primary px-4  mt-2 fs-6 font-w600"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              Subscribe now
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* line  */}
                  <div className="w-100 border my-4"></div>

                  <div className="">
                    {dataLoading ? (
                      <div
                        class="d-flex justify-content-center align-items-center card crypto-chart"
                        style={{ minHeight: "200px" }}
                      >
                        <CircularProgress
                          style={{
                            color: "#BBFF00",
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                          fontSize="small"
                        />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className=""
                          style={{
                            width: "75%",
                          }}
                        >
                          <h3>{stats?.fundName}</h3>
                          {stats?.managedBy && (
                            <p class="fs-4">by {stats?.managedBy}</p>
                          )}
                          <p className="fs-4">
                            {stats?.tokenSymbol} ={" "}
                            {stats?.cryptoFundStats?.tokenPrice}{" "}
                            {stats?.cryptoFundStats?.tokenPriceCurrency}
                          </p>
                          <div className="">
                            <p className="fs-6">{stats?.description}</p>
                          </div>
                        </div>
                        <div
                          class=""
                          style={{
                            width: "25%",
                          }}
                        >
                          <p
                            className={`${
                              stats?.cryptoFundStats?.cagr > 0
                                ? "text-success"
                                : "text-danger"
                            } mb-0 text-center fs-2 font-w600`}
                          >
                            {stats?.cryptoFundStats?.cagr || "--"}
                            {stats?.cryptoFundStats?.cagr && "%"}
                          </p>
                          <p class="fs-4 font-w400 mb-0  text-center">CAGR</p>

                          <div className="d-flex justify-content-center">
                            <button
                              onClick={() => {
                                if (!appCtx?.custodial) {
                                  switchChainandOpenModal(
                                    setOpenFundCurrencyModal,
                                    stats?.blockchain
                                  );
                                } else {
                                  setOpenFundCurrencyModal(true);
                                }
                              }}
                              className="btn btn-primary px-4  mt-2 fs-6 font-w600"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              Subscribe now
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openPaymentMethodModal && (
          <PaymentMethodModal
            openModal={openPaymentMethodModal}
            setOpenModal={setopenPaymentMethodModal}
            setSelectedTokenOption={setSelectedBasketTokenOption}
            setopenBasketModal={setopenBasketModal}
            setPayUsingTokenModal={setPayBasketUsingTokenModal}
            setOpenTalewalletOnrampModal={setOpenBasketTalewalletOnrampModal}
          />
        )}
        {payBasketUsingTokenModal && (
          <BasketPaymentusingTokenModal
            openModal={payBasketUsingTokenModal}
            setOpenModal={setPayBasketUsingTokenModal}
            symbol={selectedBasketTokenOption}
            basketId={topBasketId}
            tokenArray={tokenArray}
            basketBlockchain={singleBasket?.blockchain}
            singleBasket={singleBasket}
            amount={investmentAmount}
            totalInvestment={totalInvestment}
            minInvest={singleBasket?.minAmount}
          />
        )}

        {openBasketModal && (
          <BasketModal
            openModal={openBasketModal}
            setOpenModal={setopenBasketModal}
            closeClicked={closeClicked}
            setCloseClicked={setCloseClicked}
            singleBasket={singleBasket}
            tokenPercentArray={tokenPercentArray}
            tokenArray={tokenArray}
            successcloseClicked={successcloseClicked}
            setSuccessCloseClicked={setSuccessCloseClicked}
            openSuccessModal={openSuccessModal}
            setopenSuccessModal={setopenSuccessModal}
            transactionSuccess={transactionSuccess}
            setTransactionSuccess={setTransactionSuccess}
            fees={fees}
            setOpenContinueInWebModal={setOpenContinueInWebModal}
            subscribeId={subscribeId}
            status={status}
            basketId={topBasketId}
            basketBlockchain={singleBasket?.blockchain}
            setopenPaymentMethodModal={setopenPaymentMethodModal}
            investmentAmount={investmentAmount}
            setInvestmentAmount={setInvestmentAmount}
            totalInvestment={totalInvestment}
            setTotalInvestment={setTotalInvestment}
          />
        )}

        {/* for crypto basket */}
        {openBasketTalewalletOnrampModal && (
          <TaleWalletOnRampModal
            openModal={openBasketTalewalletOnrampModal}
            setOpenModal={setOpenBasketTalewalletOnrampModal}
            amount={investmentAmount}
            basketId={topBasketId}
            tokenPercentArray={tokenPercentArray}
            tokenArray={tokenArray}
            basketBlockchain={singleBasket?.blockchain}
            singleBasket={singleBasket}
            totalInvestment={totalInvestment}
          />
        )}

        {/*for crypto fund */}
        {openFundTalewalletOnrampModal && (
          <TaleWalletOnRampModal
            openModal={openFundTalewalletOnrampModal}
            setOpenModal={setOpenFundTalewalletOnrampModal}
            // amount={amount}
            // basketId={basketId}
            // tokenPercentArray={tokenPercentArray}
            // tokenArray={tokenArray}
            // basketBlockchain={basketBlockchain}
            symbol={selectedFundTokenOption}
            isTokenDetailsPage={true}
          />
        )}

        {openFundCurrencyModal && (
          <FundCurrencyModal
            openModal={openFundCurrencyModal}
            setOpenModal={setOpenFundCurrencyModal}
            setSelectedTokenOption={setSelectedFundTokenOption}
            setOpenCryptoFundOnrampModal={setOpenCryptoFundOnrampModal}
            setPayUsingTokenModal={setPayFundUsingTokenModal}
          />
        )}

        {payFundUsingTokenModal && (
          <PayusingTokenModal
            openModal={payFundUsingTokenModal}
            setOpenModal={setPayFundUsingTokenModal}
            symbol={selectedFundTokenOption}
            name={stats?.fundName}
            fundId={topFundId}
            truncatedAddress={truncatedAddress}
            fundWalletAddress={contracts?.fundWalletAddress}
            setOpenTalewalletOnrampModal={setOpenFundTalewalletOnrampModal}
            allCoin={allCoin}
          />
        )}
        {openCryptoFundOnrampModal && (
          <CryptoFundOnRampModal
            openModal={openCryptoFundOnrampModal}
            setOpenModal={setOpenCryptoFundOnrampModal}
            // symbol={selectedTokenOption}
            // selectedInvestmentOption={selectedInvestmentOption}
            name={stats?.fundName}
            fundId={topFundId}
            tokenPriceCurrency={stats?.tokenSymbol}
            fundWalletAddress={contracts?.fundWalletAddress}
            tokenPrice={stats?.cryptoFundStats?.tokenPrice}
            contractAddress={contracts?.contractAddress}
            fundsBlockchain={contracts?.blockchain}
            truncatedAddress={truncatedAddress}
          />
        )}
      </WelcomeWrapper>
    </>
  );
};

export default Welcome;
