import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const PortfolioTokenCard = ({ allCoin, allCoinPrice, usersToken, item }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let tokenprice;
  let price = allCoinPrice[item?.symbol]?.price;
  if (price >= 1000) {
    tokenprice = `${price?.toFixed(0)}`;
  } else if (price >= 1) {
    tokenprice = `${price?.toFixed(2)}`;
  } else if (price >= 0.1) {
    tokenprice = `${price?.toFixed(3)}`;
  } else if (price >= 0.01) {
    tokenprice = `${price?.toFixed(4)}`;
  } else if (price >= 0.001) {
    tokenprice = `${price?.toFixed(5)}`;
  } else if (price >= 0.0001) {
    tokenprice = `${price?.toFixed(6)}`;
  } else if (price >= 0.00001) {
    tokenprice = `${price?.toFixed(7)}`;
  } else if (price === 0) {
    tokenprice = 0.0;
  } else {
    tokenprice = price?.toFixed(8);
  }

  let totalAssetValue = tokenprice * item?.tokenAmount;

  const tokenIcon = allCoin?.find(
    (token) => token.symbol === item?.symbol
  )?.tokenIcon;


    let isSpotPage = window?.location?.pathname?.includes("spot");
    let isFuturePage = window?.location?.pathname?.includes("future");

    const handleNavigation = () => {
      if (isSpotPage) {
        navigate(`/spot/${item?.tokenId}/${item?.symbol}`);
      } else if (isFuturePage) {
        navigate(`/future/${item?.tokenId}/${item?.symbol}`);
      } else {
        navigate(`/token/${item?.tokenId}/${item?.symbol}`);
      }
    };

    return (
      <tr
        className={` ${appCtx?.isDarkMode ? "text-white" : ""}`}
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleNavigation();
        }}
      >
        <td className={`d-flex flex-direction-row align-items-center`}>
          <img
            src={tokenIcon}
            alt=""
            style={{ width: "30px", height: "30px", marginRight: "5px" }}
          />
          {item?.symbol}{" "}
          {item?.symbol !== "USDT" && (
            <span>
              .
              <span className="" style={{ color: "#A9A9A9" }}>
                USDT
              </span>
            </span>
          )}
        </td>
        <td>{item?.tokenAmount}</td>
        <td>--</td>
        <td>{tokenprice}</td>
        <td>{totalAssetValue?.toFixed(2)}</td>
      </tr>
    );
};

export default PortfolioTokenCard;
