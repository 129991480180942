import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import SideBar from "../sidebar/SideBar";
import { useSelector } from "react-redux";

const MainWrapper = (props) => {
  const appCtx = useSelector((state) => state.app);
  const dark = false;
  return (
    <div className={``}>
      <SideBar>
        <Navbar />
        <div
          className={` ${props.className}  `}
          style={{
            backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#E7E7E7"}`,
            color: `${appCtx?.isDarkMode ? "white" : "black"}`,

            minHeight: "100vh",
            paddingTop: "15px",
          }}
        >
          {props.children}
        </div>
        <Footer />
      </SideBar>
    </div>
  );
};

export default MainWrapper;
