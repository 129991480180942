import { useNavigate } from "react-router-dom";
import "../navbar/Navbar.css";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";

import useAuthorizedHttp from "../../hooks/use-authorized-http";

import { appActions } from "../../context/app-slice";
import { getImageUrl } from "../../config";

const WalletCurrencyDropdown = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const makeRequest = useAuthorizedHttp();

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const appCtx = useSelector((state) => state.app);
  const dark = false;
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const [openLockScreenModal, setOpenLockScreenModal] = useState(false);

  const BlockChainmenue = Object.keys(props.blockchainobject).filter(
    (key) => key !== appCtx.walletBlockchain
  );
  const themedColor = `${appCtx?.isDarkMode ? "white" : "black"}`;

  return (
    <div
      className={`d-flex flex-column gap-4 min-height-270px border `}
      style={{
        backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
        color: themedColor,
      }}
    >
      <div className="d-flex flex-column">
        <div className="px-2 py-3">
          <div className="d-flex flex-column rounded-lg overflow-hidden">
            <div className="d-flex flex-column  ">
              {appCtx.walletBlockchain &&
                BlockChainmenue?.map((singleChain) => (
                  <>
                    <div className="d-flex gap-2 align-items-center px-3">
                      <img
                        src={getImageUrl(singleChain)}
                        alt=""
                        style={{ width: "25px", height: "25px" }}
                        className="rounded-circle"
                      />
                      <p
                        className="mb-0"
                        key={singleChain}
                        onClick={() => {
                          props.setCurrencyVisible(false);
                          dispatch(appActions.setWalletBlockChain(singleChain));
                        }}
                        style={{ cursor: "pointer" }}
                        onMouseOver={(e) => {
                          e.target.style.color = "blue";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "";
                        }}
                      >
                        {singleChain}
                      </p>
                    </div>
                    <div className="border-bottom border-gray-400 my-2"></div>
                  </>
                ))}

              <div class="btn-group mt-2" role="group">
                <button
                  onClick={() => {
                    props.setCurrencyVisible(false);
                    props?.setopenAccountModal(true);
                  }}
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Add Network
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletCurrencyDropdown;
