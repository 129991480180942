import { useEffect, useState } from "react";

const useBinanceFeed = (product_id) => {
  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);
  const [middlePrice, setMiddleprice] = useState({});

  useEffect(() => {
    const wsUrl = `wss://stream.binance.com:9443/ws/${product_id.toLowerCase()}@bookTicker`;
    const tradeurl = `wss://stream.binance.com:9443/ws/${product_id.toLowerCase()}@trade`;
    const client = new WebSocket(wsUrl);
    const client2 = new WebSocket(tradeurl);

    console.log("wsUrl", wsUrl);

    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      setBids((currentBids) => [
        ...currentBids.slice(-22),
        { price: data.b, qty: data.B },
      ]); // Keep the latest 20 entries
      setAsks((currentAsks) => [
        ...currentAsks.slice(-22),
        { price: data.a, qty: data.A },
      ]);
    };

    let price = 0;
    let old_price = 0;
    let priceColor = "black";

    client2.onmessage = (message) => {
      const data = JSON.parse(message.data);

      price = parseFloat(data.p).toFixed(2);
      if (price > old_price) {
        priceColor = "text-success";
      } else if (price == old_price) {
        priceColor = "text-dark";
      } else {
        priceColor = "text-danger";
      }
      old_price = price;

      setMiddleprice({ price: data.p, color: priceColor });
    };

    return () => {
      client.close();
      client2.close();
    };
  }, [product_id]);

  const orderBook = {
    bids,
    asks,
    middlePrice,
  };

  return orderBook;
};

export default useBinanceFeed;
