import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { useLocation } from "react-router-dom";
import { getBlockchainTransactionUrl } from "../../config";

const TransactionsTablesCard = ({ tran }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <tr style={{ cursor: "pointer", padding: "5px" }}>
      <td className="py-1">
        {tran?.blockchainTransactionId ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              const blockchain = tran?.blockchain;
              const transactionHash = tran?.blockchainTransactionId;
              const blockchainLink = getBlockchainTransactionUrl(
                blockchain,
                transactionHash
              );
              if (blockchainLink !== "") {
                window.open(blockchainLink);
              }
            }}
            className="text-primary py-2"
          >
            {`${tran?.blockchainTransactionId?.substring(0, 20)}...`}
          </div>
        ) : (
          <p className="py-2 mb-0">--</p>
        )}
      </td>
      <td>{tran?.action}</td>
      <td>--</td>
      <td>{tran?.blockchain}</td>
      <td>{tran?.address?.substring(0, 20) || null}...</td>
      <td>{tran?.otherAddress?.substring(0, 20) || null}...</td>
      <td>{tran?.amount}</td>
    </tr>
  );
};

export default TransactionsTablesCard;
