import { useNavigate } from "react-router-dom";
import "../navbar/Navbar.css";
import { useDispatch, useSelector } from "react-redux";

import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { appActions } from "../../context/app-slice";
import { persistor } from "../../context/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardQuestion,
  faLink,
  faRepeat,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { getBlockchainAddressUrl } from "../../config";
import { useWalletView } from "../../hooks/useWalletView";

const ProfileDropdown = ({
  currencyvisible,
  setCurrencyVisible,
  setOpenSupportModal,
  setOpenAccountDetailsModal,
  setOpenPrivateKeyLoginModal,
  setOpenPrivateKeyPasswordModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const makeRequest = useAuthorizedHttp();

  useState(false);
  const appCtx = useSelector((state) => state.app);
  const dark = false;
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const { handleLogout } = useWalletView();
  const themedColor = `${appCtx?.isDarkMode ? "white" : "black"}`;

  return (
    <div
      className={`d-flex flex-column gap-4 border `}
      style={{
        backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
        color: themedColor,
        minWidth: "200px",
      }}
    >
      <div className="d-flex flex-column">
        <div className="px-1 pt-4 pb-2">
          <div className="d-flex flex-column rounded-lg overflow-hidden">
            <div className="d-flex flex-column ">
              {/* switch role  */}

              {(appCtx?.role === "expert" ||
                appCtx?.role === "expertNormal") && (
                <>
                  <div
                    className="d-flex gap-2 align-items-center px-3"
                    onClick={() => {
                      appCtx?.role === "user" || appCtx?.role === "expertNormal"
                        ? dispatch(appActions.setRole("expert"))
                        : dispatch(appActions.setRole("expertNormal"));
                      setCurrencyVisible(false);
                      navigate("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={faRepeat}
                      style={{
                        color: themedColor,
                      }}
                    />

                    <p
                      className="mb-0"
                      onMouseOver={(e) => {
                        e.target.style.color = "blue";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.color = "";
                      }}
                    >
                      Switch to{" "}
                      {appCtx?.role === "user" ||
                      appCtx?.role === "expertNormal"
                        ? "expert"
                        : "user"}{" "}
                      mode
                    </p>
                  </div>
                  <div className="border-bottom border-gray-400 my-2"></div>
                </>
              )}

              {/* logout  */}
              <>
                <div
                  className="d-flex gap-2 align-items-center px-3"
                  onClick={() => {
                    setCurrencyVisible(false);
                    handleLogout();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{
                      color: themedColor,
                    }}
                  />

                  <p
                    className="mb-0"
                    onMouseOver={(e) => {
                      e.target.style.color = "blue";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                  >
                    Logout
                  </p>
                </div>
                <div className="border-bottom border-gray-400 my-2"></div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDropdown;
