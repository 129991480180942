import React, { useEffect, useState } from "react";
import PortfolioComponent from "./PortfolioComponent";
import Dropdown from "react-bootstrap/Dropdown";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import OpenOrderComponent from "./OpenOrderComponent";
import TransactionHistoryComponent from "./TransactionHistoryComponent";

const TokenBottomTable = ({
  allCoin,
  allCoinPrice,
  usersToken,
  isCoinLoading,
  fundWalletAddresstLoading,
  fundWalletAddress,
  selectedFundWalletAddress,
  setSelectedFundWalletAddress,
  selectedFundName,
  setSelectedFundName,
  selectedFundId,
  setSelectedFundId,
}) => {
  const [activeTab, setActiveTab] = useState("portfolio");

  const makeRequest = useAuthorizedHttp();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  let activeComponent;

  if (activeTab === "portfolio") {
    activeComponent = (
      <PortfolioComponent
        allCoin={allCoin}
        allCoinPrice={allCoinPrice}
        usersToken={usersToken}
        isCoinLoading={isCoinLoading}
        selectedFundId={selectedFundId}
        setSelectedFundId={setSelectedFundId}
      />
    );
  } else if (activeTab === "openOrder") {
    activeComponent = (
      <OpenOrderComponent
        selectedFundId={selectedFundId}
        setSelectedFundId={setSelectedFundId}
      />
    );
  } else if (activeTab === "transactionHistory") {
    activeComponent = (
      <TransactionHistoryComponent
        selectedFundId={selectedFundId}
        setSelectedFundId={setSelectedFundId}
      />
    );
  }

  return (
    <div class="card col-xl-12" style={{ marginTop: "-12px" }}>
      <div class="card-header border-0 pb-3 flex-wrap">
        <h4 class="card-title">
          {activeTab === "portfolio"
            ? "Portfolio"
            : activeTab === "openOrder"
            ? "  Open Order"
            : activeTab === "transactionHistory"
            ? "Order History"
            : ""}
        </h4>
        <div class="d-flex mb-2">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {selectedFundName}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {fundWalletAddress?.map((fund) => (
                <Dropdown.Item
                  key={fund.fundId}
                  onClick={() => {
                    setSelectedFundWalletAddress(fund?.fundWalletAddress);
                    setSelectedFundName(fund?.fundName);
                    setSelectedFundId(fund?.fundId);
                  }}
                  value={fund?.fundWalletAddress}
                >
                  {fund?.fundName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <nav>
          <div class="nav nav-pills light" id="nav-tab" role="tablist">
            <button
              className={`nav-link ${
                activeTab === "portfolio" ? "active" : ""
              }`}
              onClick={() => handleTabClick("portfolio")}
            >
              Portfolio
            </button>
            <button
              className={`nav-link ${
                activeTab === "openOrder" ? "active" : ""
              }`}
              onClick={() => handleTabClick("openOrder")}
            >
              Open Order
            </button>
            <button
              className={`nav-link ${
                activeTab === "transactionHistory" ? "active" : ""
              }`}
              onClick={() => handleTabClick("transactionHistory")}
            >
              Transaction History
            </button>
          </div>
        </nav>
      </div>

      <div className="tab-content">{activeComponent}</div>
    </div>
  );
};

export default TokenBottomTable;
