import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import "./Sidebar.css";
import { useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PaidIcon from "@mui/icons-material/Paid";
import TokenIcon from "@mui/icons-material/Token";
import WalletIcon from "@mui/icons-material/Wallet";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WcIcon from "@mui/icons-material/Wc";
import PeopleIcon from "@mui/icons-material/People";

const SideBar = ({ children }) => {
  const appCtx = useSelector((state) => state.app);

  let isOpen = appCtx?.isSideBarOpen;

  let routes = [];
  if (appCtx?.role === "user" || appCtx?.role === "expertNormal") {
    routes = [
      {
        path: "/",
        name: "Dashboard",
        icon: <DashboardIcon className={`w-[50px]`} />,
      },
      {
        path: "/crypto-basket",
        name: "Crypto Basket",
        icon: <ShoppingBasketIcon className="w-[50px]" />,
        subRoutes: ["/crypto-basket"],
      },
      {
        path: "/crypto-fund",
        name: "Crypto Fund",
        icon: <PaidIcon className="w-[50px]" />,
        subRoutes: ["/crypto-fund"],
      },
      {
        path: "/tokens",
        name: "Tokens",
        icon: <TokenIcon className="w-[50px]" />,
        subRoutes: ["/token"],
      },
      {
        path: "/wallet",
        name: "Wallet",
        icon: <WalletIcon className="w-[50px]" />,
      },
      {
        path: "/portfolio",
        name: "Portfolio",
        icon: <AssignmentIndIcon className={`w-[50px]`} />,
      },
      {
        path: "/community-rewards",
        name: "Rewards",
        icon: <PeopleIcon className={`w-[50px]`} />,
      },
      {
        path: "/refer-and-earn",
        name: "Refer & Earn",
        icon: <WcIcon className={`w-[50px]`} />,
      },
    ];
  } else {
    routes = [
      {
        path: "/",
        name: "Dashboard",
        icon: <DashboardIcon className={`w-[50px]`} />,
      },
      {
        path: "/wallet",
        name: "Wallet",
        icon: <WalletIcon className="w-[50px]" />,
      },
      {
        path: "/crypto-basket",
        name: "Crypto Basket",
        icon: <ShoppingBasketIcon className="w-[50px]" />,
        subRoutes: ["/crypto-basket"],
      },
      {
        path: "/crypto-fund",
        name: "Crypto Fund",
        icon: <PaidIcon className="w-[50px]" />,
        subRoutes: ["/crypto-fund"],
      },
      {
        path: "/spot",
        name: "Spot Trade",
        icon: <AutoGraphIcon className="w-[50px] " />,
        subRoutes: ["/spot"],
      },
      {
        path: "/future",
        name: "Futures Trade",
        icon: <EqualizerIcon className="w-[50px]" />,
        subRoutes: ["/future"],
      },
      {
        path: "/community-rewards",
        name: "Rewards",
        icon: <PeopleIcon className={`w-[50px]`} />,
      },
      {
        path: "/refer-and-earn",
        name: "Refer & Earn",
        icon: <WcIcon className={`w-[50px]`} />,
      },
    ];
  }

  const location = useLocation();

  return (
    <>
      <div className={`main-container  `} style={{ paddingTop: "50px" }}>
        <div className="d-none d-md-block">
          <div
            className={`sidebar  overflow-hidden pt-4  ${
              !isOpen ? "sidebar-closed" : ""
            }`}
            style={{
              backgroundColor: `${appCtx?.isDarkMode ? "#18254F" : "#FFFFFF"}`,
            }}
          >
            <section className="routes">
              <div className="mt-2">
                {routes?.map((route, index) => {
                  const isPathnameIncludesSubRoute =
                    route?.subRoutes &&
                    route?.subRoutes.some((subRoute) =>
                      location.pathname.includes(subRoute)
                    );
                  const isActive =
                    route.path === location.pathname ||
                    isPathnameIncludesSubRoute;

                  return (
                    <Link
                      to={route.path}
                      key={index}
                      className={`link d-flex flex-row  ${
                        !isOpen && "justify-content-center "
                      } align-items-center ${
                        isActive ? "navitemactive" : "navitemnotactive"
                      }`}
                      style={{ margin: "5px 20px" }}
                    >
                      <div
                        className={`icon ${
                          isActive || appCtx?.isDarkMode
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {route.icon}
                      </div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            initial="hidden"
                            exit="hidden"
                            transition={{ delay: 0.5 }}
                            className="flex items-center ms-2"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Link>
                  );
                })}
              </div>
            </section>
          </div>
        </div>

        <main
          class=" overflow-y-auto overflow-x-hidden"
          style={{ width: "100vw", height: "100vh" }}
        >
          {children}
        </main>
      </div>
    </>
  );
};

export default SideBar;
