import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import CreateBasketModal from "./CreateCryptoFundModal";
import { useSelector } from "react-redux";
import CryptoFundCard from "./CryptoFundCard";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useCryptoFund } from "./useCryptoFund";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { CircularProgress } from "@mui/material";
import AllCryptoFundCard from "./AllCryptoFundCard";

const ExpertsCryptoFund = () => {
  const [openCreateBasketModal, setOpenCreateBasketModal] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);

  const [walletVerifyLoader, setWalletVerifyLoader] = useState(false);
  const {
    expertsCryptoFund,
    expertsCryptoFundLoading,
    fetchExpertsCryptoFund,
    fetchAllCryptoFundsStats,
    allFundsStats,
    allCryptoFundsStatsLoading,
  } = useCryptoFund();
  const makeRequest = useAuthorizedHttp();

  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    fetchExpertsCryptoFund();
  }, []);

     useEffect(() => {
       let fundIds = expertsCryptoFund?.map((item) => item.fundId);
       if (fundIds?.length !== 0) {
         fetchAllCryptoFundsStats(fundIds);
       }
     }, [expertsCryptoFund?.length]);

  return (
    <MainWrapper>
      <div className="row container-fluid">
        <div className="col-xl-12 col-sm-12 mx-auto">
          <div class="">
            <CreateBasketModal
              openModal={openCreateBasketModal}
              setOpenModal={setOpenCreateBasketModal}
              closeClicked={closeClicked}
              setCloseClicked={setCloseClicked}
            />
            {!expertsCryptoFundLoading && (
              <>
                <h1 class="text-center no-border font-w600 fs-60 mt-2">
                  Crypto Funds
                </h1>
                <h4 class="text-center ">
                  Explore and invest in blockchain themes and ideas with Crypto
                  Funds.
                </h4>
                {appCtx?.role === "expert" && (
                  <div className="my-2 d-flex gap-4">
                    <button
                      onClick={() => {
                        setOpenCreateBasketModal(true);
                      }}
                      class="btn btn-primary btn-sm"
                    >
                      Create
                    </button>
                  </div>
                )}
              </>
            )}
            <div class="row ">
              <div class="col-12 my-order-ile">
                <div class="card">
                  <div class="card-body px-0 pt-0 ">
                    {expertsCryptoFundLoading ? (
                      <div
                        class="d-flex justify-content-center align-items-center "
                        style={{ minHeight: "100px" }}
                      >
                        <CircularProgress
                          sryle={{ fontSize: "10px" }}
                          className="py-10"
                          fontSize="small"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </div>
                    ) : expertsCryptoFund?.length === 0 ? (
                      <div
                        class="alert alert-danger alert-dismissible fade show"
                        style={{
                          width: "60%",
                          margin: "50px auto",
                          textAlign: "center",
                        }}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="me-2 alert-icon"
                        >
                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        No Crypto Fund Found
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="btn-close"
                        ></button>
                      </div>
                    ) : (
                      <>
                        {expertsCryptoFund?.map((item) => (
                            <AllCryptoFundCard
                              item={item}
                              allFundsStats={allFundsStats}
                            />
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default ExpertsCryptoFund;
