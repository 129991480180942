import React, { useEffect, useState } from "react";
import FutureHeader from "../../component/futureComponent/FutureHeader";
import MainWrapper from "../../component/wrapper/MainWrapper";
import TradingViewWidget from "../../component/tradingViewChart/TradingViewWidget";
import { useWalletView } from "../../hooks/useWalletView";
import FutureTokenCard from "../../component/TokenLIstCard/FutureTokenCard";
import { CircularProgress, Skeleton } from "@mui/material";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import AddTokenModal from "../../component/addTokenModal/AddTokenModal";

const Futures = () => {
  const [pausePriceApiCall, setPausePriceApiCall] = useState(false);
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false);
 

     const makeRequest = useAuthorizedHttp();
     const {
       token,
       accountAsset,
       setAccountAsset,
       amount,
       setAmount,
       assetUrl,
       setAssetUrl,
       handleOptIn,
       showAssets,
       appCtx,
       optedIn,
       taleAmount,
       showTaleData,
       optInSuccessfull,
       setOptInSuccessfull,
       handleCustodialOptIn,
       minBalance,
       getAssetsById,
       assetLoader,
       allCoin,
       allCoinPrice,
       usersToken,
       isAssetLoading,
       setIsAssetLOading,
       isCoinLoading,
       isCoinPriceLOading,
       icon,
       otheraccountAsset,
       otherassetLoader,
       openSwapModal,
       setopenSwapModal,
       openSellModal,
       setopenSellModal,
       getAllCoin,
       getAllCoinPrice,
       getUsersToken,
       totalpage,
       setAllcoinPrice,
     } = useWalletView();

     useEffect(() => {
       getAllCoin(0, 100);
       getAllCoinPrice();
       getUsersToken();
     }, [appCtx.blockchain, openAddTokenModal === false]);

     // calling additional price api

     useEffect(() => {
       if (
         allCoin.length !== 0 &&
         Object.keys(allCoinPrice).length !== 0 &&
         allCoin.length !== Object.keys(allCoinPrice).length &&
         !isCoinLoading &&
         !isCoinPriceLOading &&
         !pausePriceApiCall
       ) {
         const tokenSymbolsWithNoPrice = allCoin
           ?.filter((coin) => !allCoinPrice[coin.symbol?.toUpperCase()]?.price)
           ?.map((coin) => coin?.symbol);

         makeRequest(
           {
             url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price`,
             data: tokenSymbolsWithNoPrice,
             method: "post",
             headers: {
               "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
               "Content-Type": "application/json",
             },
           },
           (data) => {
             setAllcoinPrice((prev) => ({ ...prev, ...data }));
             setPausePriceApiCall(true);
           },
           (error) => {}
         );
       }
     }, [allCoin, allCoinPrice, appCtx.blockchain]);



  
  return (
    <MainWrapper>
      {/* <FutureHeader /> */}
      <div class="">
        {/* <!-- row --> */}
        <div class="container-fluid">
          <div class="col-12 my-order-ile">
            <div class="card">
              <div class="card-header border-0 pb-3">
                <h4 class="card-title">Tokens</h4>
                <div class="d-flex justify-content-end">
                  <button
                    onClick={() => {
                      setOpenAddTokenModal(true);
                    }}
                    type="button"
                    class="btn btn-primary"
                  >
                    Add Token
                  </button>
                </div>
              </div>
              <div class="card-body px-0 pt-0 " style={{ minHeight: "800px" }}>
                {isCoinLoading ? (
                  <div class="d-flex justify-content-center align-items-center">
                    <CircularProgress
                      style={{
                        color: "#BBFF00",
                        fontSize: "10px",
                        width: "25px",
                        height: "25px",
                      }}
                      className="py-10"
                      fontSize="small"
                    />
                  </div>
                ) : (
                  <>
                    {allCoin.map((coin, index) => {
                      if (!["DAI", "USDC", "USDT"].includes(coin.symbol)) {
                        return (
                          <FutureTokenCard
                            key={index}
                            coin={coin}
                            allCoinPrice={allCoinPrice}
                            usersToken={usersToken}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTokenModal
        openModal={openAddTokenModal}
        setOpenModal={setOpenAddTokenModal}
      />
    </MainWrapper>
  );
};

export default Futures;
