import React, { useEffect, useState } from "react";
import { useCryptobasket } from "./useCryptobasket";
import { Skeleton } from "@mui/material";
import CreateBasketModal from "./CreateBasketModal";
import { useSelector } from "react-redux";
import MybasketCard from "./MybasketCard";
import { CircularProgress } from "@mui/material";
import MainWrapper from "../../component/wrapper/MainWrapper";
import UsersCryptoBasketCard from "./UsersCryptoBasketCard";
import AllCryptoBasketCard from "./AllCryptoBasketCard";
import { usePortfolio } from "../portfolio/usePortfolio";

const CryptoBasket = () => {
  const [openCreateBasketModal, setOpenCreateBasketModal] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const [howItWorksModal, setHowItWorksModal] = useState(false);
  const [showAllPrivateBasket, setShowAllPrivateBasket] = useState(false);
  const [showAllPublicBasket, setShowAllPublicBasket] = useState(false);
  const [showMyAllSubscribedBasket, setShowMyAllSubscribedBasket] = useState(false);
  const [myBasket, setMyBasket] = useState([]);
  const [myBasketLoading, setMyBasketLoading] = useState(false);
  const {
    privateBasket,
    fetchPrivateBasket,
    privateBasketLoading,
    fetchPublicBasket,
    publicBasket,
    publicBasketLoading,
    openBasketModal,
    setopenBasketModal,
  } = useCryptobasket();

  const {
    GetPortfolio,
    GetSubscribedBasketList,
    GetSubscribedCryptoFundList,
    isUserSubscribed,
  } = usePortfolio();
  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    fetchPrivateBasket();
  }, []);
  useEffect(() => {
    if (appCtx?.role === "user" || appCtx?.role === "expertNormal") {
      fetchPublicBasket();
      GetSubscribedBasketList(setMyBasket, setMyBasketLoading);
    }
  }, []);
  return (
    <MainWrapper>
      <div className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-order-ile">
              {!privateBasketLoading && privateBasket?.length === 0 ? (
                <>
                  <img
                    src="/images/cryptobasketlong.png"
                    alt=""
                    style={{ width: "100%" }}
                    className="my-2"
                  />

                  <div className="w-100 d-flex justify-content-end mt-3 mb-4">
                    <button
                      onClick={() => {
                        setOpenCreateBasketModal(true);
                      }}
                      class="btn btn-primary btn-sm"
                    >
                      Create Crypto Basket
                    </button>
                  </div>
                </>
              ) : (
                <div className="card">
                  <div className="card-header border-0 pb-3">
                    <h4 className="card-title">My Crypto Baskets</h4>
                    <button
                      onClick={() => {
                        setOpenCreateBasketModal(true);
                      }}
                      class="btn btn-primary btn-sm"
                    >
                      Create
                    </button>
                  </div>
                  <div className="card-body px-0 pt-0">
                    {privateBasketLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        <CircularProgress
                          style={{
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                        />
                      </div>
                    ) : (
                      <>
                        {showAllPrivateBasket
                          ? privateBasket.map((item) => (
                              <UsersCryptoBasketCard
                                item={item}
                                showCreatedBy={false}
                              />
                            ))
                          : privateBasket
                              .slice(0, 5)
                              .map((item) => (
                                <UsersCryptoBasketCard
                                  item={item}
                                  showCreatedBy={false}
                                />
                              ))}

                        {privateBasket.length > 5 && (
                          <div className="d-flex w-100 justify-content-center mt-2">
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                setShowAllPrivateBasket(!showAllPrivateBasket)
                              }
                            >
                              {showAllPrivateBasket ? "View less" : "View more"}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* Subscribed baskets  */}
            {(appCtx?.role === "user" || appCtx?.role === "expertNormal") && (
              <div className="col-12 my-order-ile">
                {!myBasketLoading && myBasket?.length === 0 ? (
                  <>{/* user didn't subscribed to any basket  */}</>
                ) : (
                  <div className="card">
                    <div className="card-header border-0 pb-3">
                      <h4 className="card-title">Subscribed Baskets</h4>
                    </div>
                    <div className="card-body px-0 pt-0">
                      {myBasketLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "100px" }}
                        >
                          <CircularProgress
                            style={{
                              fontSize: "10px",
                              width: "25px",
                              height: "25px",
                            }}
                            className="py-10"
                          />
                        </div>
                      ) : (
                        <>
                          {showMyAllSubscribedBasket
                            ? myBasket.map((item) => (
                                <UsersCryptoBasketCard item={item} />
                              ))
                            : myBasket
                                .slice(0, 5)
                                .map((item) => (
                                  <UsersCryptoBasketCard item={item} />
                                ))}

                          {myBasket.length > 5 && (
                            <div className="d-flex w-100 justify-content-center mt-2">
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  setShowMyAllSubscribedBasket(!showMyAllSubscribedBasket)
                                }
                              >
                                {showMyAllSubscribedBasket ? "View less" : "View more"}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* all baskets  */}
            {(appCtx?.role === "user" || appCtx?.role === "expertNormal") && (
              <div class="col-12 my-order-ile">
                <div class="card">
                  <div class="card-header border-0 pb-3">
                    <h4 class="card-title">Crypto Baskets</h4>
                  </div>
                  <div class="card-body px-0 pt-0 ">
                    {publicBasketLoading ? (
                      <div
                        class="d-flex justify-content-center align-items-center "
                        style={{ minHeight: "100px" }}
                      >
                        <CircularProgress
                          sryle={{ fontSize: "10px" }}
                          className="py-10"
                          fontSize="small"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </div>
                    ) : publicBasket?.length === 0 ? (
                      <div
                        class="alert alert-danger alert-dismissible fade show"
                        style={{
                          width: "60%",
                          margin: "50px auto",
                          textAlign: "center",
                        }}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="me-2 alert-icon"
                        >
                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        No Crypto Basket Found
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="btn-close"
                        ></button>
                      </div>
                    ) : (
                      <>
                        {showAllPublicBasket
                          ? publicBasket?.map((item) => (
                              <AllCryptoBasketCard item={item} />
                            ))
                          : publicBasket
                              ?.slice(0, 20)
                              ?.map((item) => (
                                <AllCryptoBasketCard item={item} />
                              ))}

                        <div className="d-flex w-100 justify-content-center mt-2">
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              setShowAllPublicBasket(!showAllPublicBasket)
                            }
                          >
                            {showAllPublicBasket ? "View less" : "View more"}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CreateBasketModal
        openModal={openCreateBasketModal}
        setOpenModal={setOpenCreateBasketModal}
        closeClicked={closeClicked}
        setCloseClicked={setCloseClicked}
      />
    </MainWrapper>
  );
};

export default CryptoBasket;
