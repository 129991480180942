import React, { useEffect, useRef, memo } from "react";
import { useSelector } from "react-redux";

function TradingViewWidget({ tokenname }) {
  const appCtx = useSelector((state) => state.app);
  const container = useRef();
  const widgetRef = useRef(null); // Add a ref to keep track of the TradingView widget instance

  useEffect(() => {
    // Remove the previous widget before rendering a new one
    if (widgetRef.current !== null) {
      widgetRef.current.remove();
      widgetRef.current = null;
    }

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "BINANCE:${tokenname}USDT",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "${appCtx?.isDarkMode ? "dark" : "light"}",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "withdateranges": true,
          "hide_side_toolbar": false,
          "allow_symbol_change": false,
          "show_popup_button": true,
          "popup_width": "1000",
          "popup_height": "650",
          "support_host": "https://www.tradingview.com"
        }`;

    // Add the script to the container only if it exists
    if (container.current) {
      container.current.appendChild(script);

      // Store the widget instance in the ref
      widgetRef.current = {
        remove: () => {
          container.current.innerHTML = "";
        },
      };
    }

    // Cleanup function to remove the widget when the component unmounts
    return () => {
      if (widgetRef.current !== null && container.current !== null) {
        widgetRef.current.remove();
        widgetRef.current = null;
      }
    };
  }, [appCtx?.isDarkMode, tokenname]);

  return <div className="tradingview-widget-container" ref={container}></div>;
}

export default memo(TradingViewWidget);
