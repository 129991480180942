import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import DoneIcon from "@mui/icons-material/Done";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

export const AccountAddressBar = ({ address }) => {
  const [copyId, setCopyId] = useState(false);

  const appCtx = useSelector((state) => state.app);

  const copyToClipboard = () => {
    setCopyId(true);
    navigator.clipboard.writeText(address);
  };

  return (
    <>
      <div
        className="text-lg font-weight-bold d-flex justify-content-around align-items-center px-2 py-2 gap-5  shadow rounded-lg "
        style={{
          backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : ""}`,
          width: "220px",
        }}
      >
        {address === undefined ? (
          <div className="w-[220px]">
            <CircularProgress
              style={{
                width: "14px",
                height: "14px",
              }}
            />
          </div>
        ) : (
          <div
            className="d-flex justify-content-between align-items-center mx-2"
            style={{ width: "220px" }}
          >
            <div className="">{address && `${address?.substr(0, 20)}...`}</div>
            <div
              className="mb-[6px] transition-all ease-out duration-500"
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard();
              }}
            >
              {!copyId ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Copy address</Tooltip>}
                >
                  <FontAwesomeIcon
                    icon={faCopy}
                    className=""
                    style={{
                      fontSize: "20px",
                      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                    }}
                  />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Copied</Tooltip>}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    className=""
                    style={{
                      fontSize: "20px",
                      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                    }}
                  />
                </OverlayTrigger>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
