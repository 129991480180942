import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";

const OpenOrderCard = ({ item, setOpenTpSLModal }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <tr
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        if (!e.target.classList.contains("view-button")) {
          navigate(`/token/${item?.buyTokenId}/${item?.buyToken}`);
        }
      }}
    >
      <td>{moment(item.createdDate).format("D MMM YYYY")}</td>
      <td>{item.buyToken}</td>

      <td>{item?.markedPrice || "--"}</td>
      <td>${item.buyAmount}</td>
      <td>${item.sellAmount}</td>

      <td
        className=""
        // onClick={() => {
        //   setOpenTpSLModal(true);
        // }}
        style={{ cursor: "pointer" }}
      >
        <span className="view-button">Delete</span>
      </td>
      {/* <td
        className=""
        onClick={() => {
          setOpenTpSLModal(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <span className="view-button">View</span>
      </td> */}
    </tr>
  );
};

export default OpenOrderCard;
