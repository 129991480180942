import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";

const FutureTokenCard = ({ coin, allCoinPrice, usersToken }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let tokenprice;
  let price = allCoinPrice[coin?.symbol]?.price;
  if (price >= 1000) {
    tokenprice = `${price?.toFixed(0)}`;
  } else if (price >= 1) {
    tokenprice = `${price?.toFixed(2)}`;
  } else if (price >= 0.1) {
    tokenprice = `${price?.toFixed(3)}`;
  } else if (price >= 0.01) {
    tokenprice = `${price?.toFixed(4)}`;
  } else if (price >= 0.001) {
    tokenprice = `${price?.toFixed(5)}`;
  } else if (price >= 0.0001) {
    tokenprice = `${price?.toFixed(6)}`;
  } else if (price >= 0.00001) {
    tokenprice = `${price?.toFixed(7)}`;
  } else {
    tokenprice = price?.toFixed(8);
  }

  return (
    <div
      class="d-flex justify-content-between align-items-center market-preview"
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/future/${coin?.tokenId}/${coin?.symbol}`);
        dispatch(
          appActions.setSingleTokenData({
            tokenName: coin.tokenName,
            tokenSymbol: coin?.symbol,
            priceOfToken: tokenprice,
          })
        );
      }}
    >
      <div class="d-flex align-items-center">
        <span>
          <img
            src={coin.tokenIcon}
            alt=""
            style={{ width: "36px", height: "36px" }}
          />
        </span>
        <div class="ms-3">
          <a href="javascript:void(0);">
            <h5 class="fs-14 font-w600 mb-0">
              {coin.tokenName}.
              <span className="" style={{ color: "#A9A9A9" }}>
                USDT
              </span>
            </h5>
          </a>
          {/* <span class="fs-12 font-w400">January</span> */}
        </div>
      </div>
      <div class="d-flex  ">
        <div class="">
          <h5 class="fs-14 font-w600 mb-0  text-end">$ {tokenprice}</h5>

          {allCoinPrice && (
            <span
              className={`${
                allCoinPrice[coin.symbol]?.priceChange > 0
                  ? "text-success"
                  : "text-danger"
              }  text-end`}
            >
              <FontAwesomeIcon
                icon={
                  allCoinPrice[coin.symbol]?.priceChange > 0
                    ? faArrowUp
                    : faArrowDown
                }
              />
              {allCoinPrice[coin.symbol]?.priceChange?.toFixed(5)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default FutureTokenCard;
